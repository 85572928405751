import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { InteractivePlanType } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface Point {
  x: number
  y: number
}

export interface Size {
  width: number
  height: number
}

export interface Polygon {
  label?: string
  subLabel?: string
  groupId: string
  color: string
  points: Array<Point>
  coordinates: Point
  markerCoordinates: Point
  size: Size
  angle?: number
  rectAngle?: number
  rectCoordinates?: Point
  polyCoordinates?: Point
  markerSize?: Size
  polySize?: Size
  rectSize?: Size
  oblCoordinates?: Point
  oblRadius?: Point
  oblSize?: Size
  type?: 'numbered' | 'badge' | 'arrow' | 'arrow-small'
  noPolyHover?: boolean
  isInvisible?: boolean
}

export interface MapContent {
  id?: string
  image: string
  showcaseRoom?: string
  polygons: Array<Polygon>
}

export interface MappingCollection {
  [index: string]: MapContent
}

export interface MappingBlockCollection {
  [index: string]: Array<MapContent>
}

export interface MappingPrecinctCollection {
  [index: string]: Array<MapContent>
}

export interface MappingStageCollection {
  [index: string]: MapContent
}

export interface InteractivePlanData {
  projectId?: string
  areaView: MapContent
  blocks: MappingBlockCollection
  floorplan: MappingCollection
  precinct: MappingPrecinctCollection
  stages: MappingStageCollection
}

type InteractivePlanParamsType = {
  projectName: string
  type?: InteractivePlanType
  id?: string
  slug?: string
}

export const interactivePlanApi = createApi({
  reducerPath: 'interactivePlanApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getInteractivePlan: builder.query<
      ApiResponse<InteractivePlanData>,
      InteractivePlanParamsType
    >({
      query: (params: InteractivePlanParamsType) => ({
        url: `/v1/${params.projectName}/interactive-plan`,
        method: 'get',
        params: {
          ...roomQueryParams(),
          type: params.type,
          id: params.id,
          slug: params.slug,
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  error,
  status,
}: UseQueryStateResult<any, any>): {
  maps: InteractivePlanData
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  maps: data?.data || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetInteractivePlanQuery } = interactivePlanApi
