import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase, SessionMap } from '@store/types'

import FadeContainer from '@components/fade-container'
import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
  setScrollPosition,
} from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'

import { TeamsInfo } from '@api/teams'

import getSession from '@utilities/firebase-util'
import handleMouseWheel from '@utilities/gallery-image-control-util'

export interface SlideWrapperProps {
  session: SessionMap | undefined
  slideContent: Array<TeamsInfo & { backgroundSrc: string }>
}

const CarouselContent = ({ session, slideContent }: SlideWrapperProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const [isConnected, setIsConnected] = React.useState(false)

  const [toggleControls, setToggleControls] = React.useState(true)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        team: {
          galleryControl: { activeItemID: activeSlideIndexFirebase },
        },
      } = session

      const activeItemIdFirebase = slideContent.findIndex(
        (item) => item.id === activeSlideIndexFirebase
      )
      setIsConnected(connected)
      setCurrentSlide(activeItemIdFirebase !== -1 ? activeItemIdFirebase : 0)
    }
  }, [session, slideContent])

  return (
    <div
      onWheel={(e) => {
        handleMouseWheel(
          e,
          'innernextButton',
          'innerprevButton',
          currentSlide,
          slideContent.length
        )
        setScrollPosition(dotContainerRef)
        setToggleControls(true)
      }}
      className="relative h-full"
      data-testid="teams"
    >
      <ButtonNext id="innernextButton" className="hidden">
        Next button
      </ButtonNext>
      <ButtonBack id="innerprevButton" className="hidden">
        Prev button
      </ButtonBack>
      {!isConnected && (
        <IdleTimeHandler>
          <FadeContainer className="relative z-10" show={toggleControls}>
            <div
              className="absolute left-0 flex h-screen w-1/2 cursor-default flex-col justify-end px-14 pb-14 ipad-pro-land:w-2/5 ipad-pro-land:px-28"
              onClick={() => setToggleControls((state) => !state)}
              role="none"
            >
              <div role="none" onClick={(e) => e.stopPropagation()}>
                <SlideTrigger
                  type="label"
                  items={slideContent.map((item) => ({ text: item.label }))}
                  setCurrentSlide={setCurrentSlide}
                  currentSlide={currentSlide}
                  dotContainerRef={dotContainerRef}
                  toggleControls={toggleControls}
                  nextButtonId="innernextButton"
                  previousButtonId="innerprevButton"
                  className="max-w-none"
                />
              </div>
            </div>
          </FadeContainer>
        </IdleTimeHandler>
      )}

      <GallerySlider
        transitionType="fadeIn"
        onClick={() => setToggleControls((state) => !state)}
      >
        {slideContent.map((data, index) => (
          <GallerySlide index={index} key={data.name} transitionType="fadeIn">
            <div className="flex h-screen">
              <div className="custom-scrollbar flex max-h-[90vh] w-1/2 flex-col justify-center self-center overflow-y-auto bg-transparent px-14 pb-20 text-white ipad-pro-land:w-2/5 ipad-pro-land:px-28">
                <p className="mb-5 text-[45.5px]/none font-medium">
                  {data.label || ''}
                </p>
                <p className="mb-9 font-semibold uppercase tracking-wider">
                  {data.caption || ''}
                </p>
                <p className="mb-[51px] whitespace-pre-line text-base">
                  {data.description}
                </p>
                <div>
                  <ImageHandler
                    alt={data.imageName}
                    url={data.imageSource}
                    type="legacy"
                    className="max-w-[200px]"
                    isImage
                  />
                </div>
              </div>
              <ImageHandler
                className="background-cover w-1/2 ipad-pro-land:w-3/5"
                url={data.backgroundSrc}
                type="legacy"
                bgProps={{
                  gradiant: 0,
                }}
              />
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default connect(({ firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
}))(CarouselContent)
