import { fabric } from 'fabric'

const FabricOperations = (fabricRef: fabric.Canvas | null) => {
  const getImageRatios = (oImg: fabric.Image) => {
    const widthRatio = (fabricRef?.width || 0) / (oImg?.width || 0)
    const heightRatio = (fabricRef?.height || 0) / (oImg?.height || 0)
    return {
      widthRatio,
      heightRatio,
      maxRatio: Math.max(widthRatio, heightRatio),
      minRatio: Math.min(widthRatio, heightRatio),
    }
  }

  const setImageState = (oImg: fabric.Image, ratio: string) => {
    const { widthRatio, heightRatio, maxRatio, minRatio } = getImageRatios(oImg)

    oImg.set({
      objectCaching: false,
      opacity: 0,
    })

    if (heightRatio > widthRatio) {
      oImg.scale(ratio === 'max' ? maxRatio : minRatio)
    } else {
      oImg.set({
        scaleX: widthRatio,
        scaleY: heightRatio,
        noScaleCache: false,
      })
    }

    return oImg
  }

  return {
    getImageRatios,
    setImageState,
  }
}

export default FabricOperations
