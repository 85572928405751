import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { LocationData } from '@api/location'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<LocationData>

if (isOffline()) {
  const locationData = localStorage.getItem('locationApiData')
  if (locationData) {
    initialState = JSON.parse(locationData)
  }
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (
      state: Array<LocationData>,
      action: PayloadAction<Array<LocationData>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'locationApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeLocation: () => {
      localStorage.removeItem('locationApiData')
      return []
    },
  },
})

export const { setLocation, removeLocation } = locationSlice.actions

export const selectlocation = (state: RootStateTypeExtra) => state.location

export default locationSlice.reducer
