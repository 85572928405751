import React from 'react'

import { FilterSvg } from '@svg/react'

interface ComponentProps {
  unitCount: number
  popupState: boolean
  togglePopup: (arg: boolean) => void
}

const AddFilterButton = ({
  unitCount,
  popupState,
  togglePopup,
}: ComponentProps) => (
  <button
    type="button"
    className="relative rounded bg-mainColour px-3 py-1 text-white"
    onClick={() => togglePopup(!popupState)}
  >
    <div className="inline-flex items-center">
      <span className="text-xs font-bold tracking-widest">Add Filter</span>
      <FilterSvg className="ml-1 h-4 w-4" />
    </div>
    <span className="absolute -right-2 -top-2 h-5 w-5 rounded-full border-2 border-mainColour bg-white text-xs font-bold text-mainColour">
      {unitCount}
    </span>
  </button>
)

export default AddFilterButton
