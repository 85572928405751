const generateId = (
  length: number,
  idType: 'numeric' | 'alphanumeric' = 'alphanumeric'
) => {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  if (idType === 'numeric') {
    characters = '0123456789'
  }
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const createSessionId = () => generateId(6, 'numeric')

export { generateId, createSessionId }
