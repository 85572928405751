import React from 'react'

import { ShortlistItemInterface, ShortlistPropertyType } from '@store/types'

interface UseGetQRCodeArgs {
  projectName: string
  shortlists: ShortlistItemInterface[]
  documentIds: string[]
  agentId?: string
}

type QRShortlistItem = {
  propertyId: string
  configuration?: {
    packageId?: string
    floorPlanId?: string
    facadeId?: string
    facadeColorId?: string
    internalThemeId?: string
    internalColorThemeId?: string
  }
}

const useGetQRCodeData = ({
  projectName,
  shortlists,
  documentIds,
  agentId,
}: UseGetQRCodeArgs) => {
  const qrCodeData = React.useMemo(() => {
    let dataParams: Record<string, any> = {}

    dataParams = {
      project: {
        name: projectName || '',
      },
    }

    if (shortlists.length > 0) {
      dataParams = {
        ...dataParams,
        shortlist: shortlists.map<QRShortlistItem>((property) => ({
          propertyId: property.propertyId || '',
          ...(property.type === ShortlistPropertyType.Lot && {
            configuration: {
              packageId: property.configuration?.floorplan || '',
              floorPlanId: property.configuration?.floorplanOption || '',
              facadeId: property.configuration?.facade || '',
              facadeColorId: property.configuration?.facadeColor || '',
              internalThemeId: property.configuration?.internalTheme || '',
              internalColorThemeId:
                property.configuration?.internalColorScheme || '',
            },
          }),
        })),
      }
    }

    if (documentIds.length > 0) {
      dataParams = {
        ...dataParams,
        documents: documentIds || [],
      }
    }

    if (agentId) {
      dataParams = {
        ...dataParams,
        agent: agentId || '',
      }
    }

    return dataParams
  }, [shortlists, documentIds, agentId])

  return qrCodeData
}

export default useGetQRCodeData
