import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface ExternalViewsData {
  title: string
  url: string
}

export interface ExternalViews {
  id: string
  content: ExternalViewsData
  created: {
    date: string
    timezone: string
    timezone_type: number
  }
}

type ExternalViewsType = {
  projectName: string
}

export const externalViewsApi = createApi({
  reducerPath: 'externalViewsApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getExternalViews: builder.query<
      ApiResponse<ExternalViews>,
      ExternalViewsType
    >({
      query: (params: ExternalViewsType) => ({
        url: `/v1/${params.projectName}/external-views`,
        method: 'get',
      }),
    }),
  }),
})

const processExternalViewsData = (
  data: Array<ExternalViews>
): Array<ExternalViewsData> =>
  data.map((viewData) => ({
    title: viewData?.content?.title,
    url: viewData?.content?.url,
  }))

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  externalViewsData: ReturnType<typeof processExternalViewsData>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  externalViewsData: processExternalViewsData(data?.data || []),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetExternalViewsQuery } = externalViewsApi
