import React from 'react'

import { ArrowSvg } from '@components/adgroup-svg/react'

interface LayerProps {
  isValid?: boolean
  label: string
  onClick: React.MouseEventHandler
  path?: string
}

const Layer = ({ isValid, label, onClick, path }: LayerProps) => (
  <>
    {isValid && path ? (
      <a href={path} onClick={onClick}>
        <span className="border-b-2 border-black">{label}</span>
        <span className="border-b-2 border-black">
          <ArrowSvg
            size="m"
            className="-mr-1 inline-block h-5 w-5"
            styles={{ rotate: '135deg' }}
            strokeColor="#000000"
          />
        </span>
      </a>
    ) : (
      <span>{label}</span>
    )}
  </>
)

export default Layer
