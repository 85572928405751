import React from 'react'

import { SvgProps } from './types'

const ChevronUpDownSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
  rotate,
  stroke,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          height: 'auto',
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || 40}
      height={height || 40}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'none'}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={stroke || '1'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
      />
    </svg>
  )
}

export default ChevronUpDownSvg
