import { useEffect, useState } from 'react'

type UseScriptOptions = {
  onLoadCallback?: () => void
  onErrorCallback?: (error: Event) => void
}

type UseScriptReturn = {
  loaded: boolean
  error: Event | null
}

function useScript(
  src: string,
  { onLoadCallback, onErrorCallback }: UseScriptOptions = {}
): UseScriptReturn {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState<Event | null>(null)

  useEffect(() => {
    if (!src) return undefined

    const script = document.createElement('script')
    script.src = src
    script.async = true

    const onLoad = () => {
      setLoaded(true)
      setError(null)
      if (onLoadCallback) {
        onLoadCallback()
      }
    }

    const onError = (e: Event) => {
      setLoaded(false)
      setError(e)
      if (onErrorCallback) {
        onErrorCallback(e)
      }
    }

    script.addEventListener('load', onLoad)
    script.addEventListener('error', onError)

    document.body.appendChild(script)

    return () => {
      script.removeEventListener('load', onLoad)
      script.removeEventListener('error', onError)
      document.body.removeChild(script)
    }
  }, [src, onLoadCallback, onErrorCallback])

  return { loaded, error }
}

export default useScript
