const PANORAMIC_ACTIVE_BLOCK_KEY = 'panoramicActiveBlock'
const PANORAMIC_ACTIVE_LEVEL_KEY = 'panoramicActiveLevel'
const PANORAMIC_ACTIVE_UNIT_KEY = 'panoramicActiveUnit'
const PANORAMIC_ACTIVE_PRECINCT_KEY = 'panoramicActivePrecinct'
const PANORAMIC_ACTIVE_STAGE_KEY = 'panoramicActiveStage'
const PANORAMIC_ACTIVE_LOT_KEY = 'panoramicActiveLot'

const getItem = (key: string, defaultValue?: string) => {
  const data = localStorage.getItem(key)

  if (!data) return defaultValue

  try {
    return JSON.parse(data)
  } catch (e) {
    return defaultValue
  }
}

const setItem = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value))

const removeItem = (key: string) => localStorage.removeItem(key)

export {
  PANORAMIC_ACTIVE_BLOCK_KEY,
  PANORAMIC_ACTIVE_LEVEL_KEY,
  PANORAMIC_ACTIVE_UNIT_KEY,
  PANORAMIC_ACTIVE_PRECINCT_KEY,
  PANORAMIC_ACTIVE_STAGE_KEY,
  PANORAMIC_ACTIVE_LOT_KEY,
  getItem,
  setItem,
  removeItem,
}
