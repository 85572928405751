import React from 'react'
import { connect } from 'react-redux'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'
import { hasToken } from '@utilities/helper'
import useMatrixCacheCleaner from '@utilities/matrix-cache-cleaner'

export interface ContainerProps {
  children?: React.ReactNode
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  token: TokenPayloadInterface
  style?: React.CSSProperties
  background?: {
    url: string
    type: 'legacy' | 'new'
    noSpliceUrl: boolean
    staticUrl?: boolean
  }
  gradiant?: number
  className?: string
  disableNavigation?: boolean
  fullScreenMode?: boolean
}

const Container = ({
  style,
  children,
  projectIdentity,
  session,
  token,
  background,
  gradiant = 0.5,
  className = '',
  disableNavigation = false,
  fullScreenMode,
}: ContainerProps) => {
  const remoteAppIsConnected = React.useMemo(
    () => hasToken(token) && session?.connected,
    [token, session]
  )

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const [isMatrixCacheCleaned, handleMatrixCacheCleanerTrigger] =
    useMatrixCacheCleaner({
      projectName: projectIdentity.projectName,
      email: token.email,
    })

  const resetClearCacheTriggerKeyFromFirebaseAndReload = async () => {
    await firebaseControlQuery.update({
      [`clearCacheTriggerKey`]: '',
      [`clearCacheKeyState`]: 'cleared',
    })
    window.location.reload()
  }

  React.useEffect(() => {
    if (isMatrixCacheCleaned) {
      resetClearCacheTriggerKeyFromFirebaseAndReload()
    }
  }, [isMatrixCacheCleaned])

  React.useEffect(() => {
    if (hasToken(token) && session) {
      const { clearCacheTriggerKey: firebaseClearCacheTriggerKey } = session
      handleMatrixCacheCleanerTrigger(firebaseClearCacheTriggerKey !== '')
    }
  }, [session])

  return (
    <div
      className={`background-cover relative bg-secondaryColour transition-all duration-300 ${
        !disableNavigation && !(fullScreenMode || remoteAppIsConnected)
          ? 'top-9 h-page-container rounded-t-3xl ipad-mini:top-16'
          : 'top-0 h-screen'
      } ${className}`}
      style={{
        ...setBackground(background ? AssetHandler(background) : '', {
          gradiant,
        }),
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default connect(
  ({ projectIdentity, firestore, token }: RootStateFirebase) => ({
    projectIdentity,
    session: getSession(firestore),
    token,
  })
)(Container)
