import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { EnvisionAssetConfigInterface } from '@store/actionSlices/appConfig'
import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import { setFilter } from '@store/actionSlices/unitFilter'
import {
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { DataNotFound } from '@components/data-handler/errors'
import FilterPopup from '@components/filter-popup'
import IdleTimeHandler from '@components/idle-time-handler'
import Skeleton from '@components/skeleton'

import {
  Level,
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  EnvisionVRConfigurationInterface,
  EnvisionVRLevelsByBlockInterface,
} from '@api/config'
import {
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import getInactiveLevelAndBlocks from '@utilities/envision-vr-utils'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'
import { getQueryStringParams, hasEnvisionVR } from '@utilities/helper'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import { ArrowDownTraySvg, FilterSvg } from '@svg/react'

import {
  GET_BUILDING_IDS,
  GET_LEVELS,
  ON_LEVEL_HOVERED,
  ON_LEVEL_HOVERED_OUT,
  ON_LEVEL_SELECTED,
  ON_MODEL_DOWNLOAD,
  ON_MODEL_LOADED,
  ON_UNITY_MESSAGE,
} from './event-type'

interface ComponentProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  showPrice: boolean
  building: BuildingInterface
  unitFilter: UnitFilterInterface
  envisionAssetConfig: EnvisionAssetConfigInterface
}

const MODEL_NAME = 'envision-model.uscene'
const DEFAULT_CAMERA_ZOOM_VALUE = 0.5
const DEFAULT_CAMERA_ANGLE_VALUE = 0

const EnvisionVR = ({
  session,
  projectIdentity,
  envisionVRConfiguration,
  showPrice,
  building,
  unitFilter,
  envisionAssetConfig,
}: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { showDownloadOption } = getQueryStringParams(location.search)

  const iframeRef = React.useRef() as React.MutableRefObject<HTMLIFrameElement>
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [isRemoteAppConnected, setRemoteAppConnectionState] =
    React.useState(false)
  const [isModelLoaded, setModelLoadState] = React.useState(false)
  const [envisionVRBuildings, setEnvisionVRBuildings] = React.useState<
    Array<string>
  >([])
  const [envisionVRLevels, setEnvisionVRLevels] = React.useState<
    Array<Array<string>>
  >([])
  const [envisionVRLevelsByBlock, setEnvisionVRLevelsByBlock] =
    React.useState<EnvisionVRLevelsByBlockInterface>()
  const [levelList, setLevelList] = React.useState<Array<string>>([])
  const [activeBuilding, setActiveBuilding] = React.useState('')
  const [isLevelHighlighted, setLevelHighlightState] = React.useState(false)
  const [rotateCameraByTriggerKey, setRotateCameraByTrigger] =
    React.useState('')
  const [zoomCameraByTriggerKey, setZoomCameraByTrigger] = React.useState('')
  const [currentZoomValue, setCurrentZoomValue] = React.useState(
    DEFAULT_CAMERA_ZOOM_VALUE
  )
  const [cameraState, setCameraState] = React.useState(false)
  const [modelResetTriggerKey, setModelResetTriggerKey] = React.useState('')
  const [hoveredLevel, setHoveredLevel] = React.useState('')

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )
  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromResultInteractive }
  )

  const cameraZoomValueInConfig = React.useMemo(
    () => envisionVRConfiguration?.zoom || DEFAULT_CAMERA_ZOOM_VALUE,
    [envisionVRConfiguration]
  )

  const cameraAngleValueInConfig = React.useMemo(
    () => envisionVRConfiguration?.rotateCameraBy || DEFAULT_CAMERA_ANGLE_VALUE,
    [envisionVRConfiguration]
  )

  const getModelSrc = () => {
    const { host, port } = envisionAssetConfig
    const { projectName: activeProject } = projectIdentity

    let src = envisionVRConfiguration.url

    if (host && port) {
      const localAssetLocation = `http://${host}:${port}/${activeProject}/${MODEL_NAME}`

      src += `&override=${localAssetLocation}`
    }

    return src
  }

  const callUnityFunction = (functionName: string, parameters: any) => {
    if (!iframeRef.current?.contentWindow) return
    const eventData = {
      eventType: 'call',
      payload: {
        functionName,
        parameters,
      },
    }
    iframeRef.current.contentWindow.postMessage(eventData, '*')
  }

  const handleGetBuildingIDsCallback = (values: string) => {
    try {
      const parseValues = JSON.parse(values).Value
      setEnvisionVRBuildings(parseValues)

      if (session) {
        firebaseControlQuery.update({
          [`envisionVR.isLoaded`]: true,
          [`envisionVR.buildings.data`]: parseValues || [],
          [`envisionVR.levels.data`]: [],
        })
      }
    } catch (errro) {
      console.error('Building data load error')
    }
  }
  const handleGetLevelsCallback = (values: string) => {
    setEnvisionVRLevels((prevData) => [
      ...prevData,
      JSON.parse(values).Value || [],
    ])
  }

  const handleLevelHoveredCallback = (values: string) => {
    try {
      const parseValues = JSON.parse(values)
      setHoveredLevel(parseValues.Level)
    } catch (errro) {
      setHoveredLevel('')
      console.error('Building data load error')
    }
  }

  const handleLevelHoveredOutCallback = () => setHoveredLevel('')

  const handleActiveBuildingFirebase = (block: string) => {
    try {
      if (
        session &&
        envisionVRLevelsByBlock &&
        envisionVRLevelsByBlock[block]
      ) {
        const levelsByBlock = envisionVRLevelsByBlock[block]
        setLevelList(levelsByBlock)
        firebaseControlQuery.update({
          [`envisionVR.isLoaded`]: true,
          [`envisionVR.levels.data`]: levelsByBlock,
        })
      }
    } catch (errro) {
      console.error('Level data load error')
    }
  }

  const getCorrectBlockValue = (argBlock: string, argLevel: string): string => {
    if (argBlock === '') return ''
    const level = building.levels.find((lvl: Level) => argLevel === lvl.level)
    if (!level) return ''
    const unit = level.data.find((unt: Unit) => unt.blockId === argBlock)
    if (!unit) return ''
    return unit.blockId || ''
  }

  const handleLevelClick = (data: string) => {
    const obj = JSON.parse(data)
    if (!obj.Level) return
    const thisLevel = obj.Level
    const thisBlock = obj.BuildingID || ''
    dispatch(
      setByFlag({
        flag: 'activeBlock',
        value: getCorrectBlockValue(thisBlock, thisLevel),
      })
    )
    dispatch(
      setByFlag({
        flag: 'activeLevel',
        value: thisLevel,
      })
    )
    history.push('building')
  }

  const handleOnModelLoadedCallback = () => {
    setModelLoadState(true)
    callUnityFunction('SetHighlightColor', { Value: '#0000FF' })
    callUnityFunction('ZoomCamera', {
      Value: cameraZoomValueInConfig,
    })
    callUnityFunction('RotateCameraBy', {
      Value: cameraAngleValueInConfig,
    })
    callUnityFunction('GetBuildingIDs', null)
    setCurrentZoomValue(cameraZoomValueInConfig)
  }

  const handleModelDownload = async (data: string) => {
    const obj = JSON.parse(data)

    const source = obj.Value || ''
    if (!source) return

    const downloadElement = document.createElement('a')
    downloadElement.href = source
    downloadElement.download = MODEL_NAME

    document.body.appendChild(downloadElement)

    downloadElement.click()

    document.body.removeChild(downloadElement)
  }

  const postMessageEventListener = React.useCallback(
    (event: MessageEvent) => {
      try {
        if (event && event.data.payload) {
          const {
            data: {
              eventType,
              payload: { eventName, eventData },
            },
          } = event
          if (eventType !== ON_UNITY_MESSAGE) {
            return
          }
          if (eventName === ON_LEVEL_SELECTED) {
            handleLevelClick(eventData)
          }
          if (eventName === ON_MODEL_LOADED) {
            handleOnModelLoadedCallback()
          }
          if (eventName === GET_BUILDING_IDS) {
            handleGetBuildingIDsCallback(eventData)
          }
          if (eventName === GET_LEVELS) {
            handleGetLevelsCallback(eventData)
          }
          if (eventName === ON_LEVEL_HOVERED) {
            handleLevelHoveredCallback(eventData)
          }
          if (eventName === ON_LEVEL_HOVERED_OUT) {
            handleLevelHoveredOutCallback()
          }
          if (eventName === ON_MODEL_DOWNLOAD) {
            handleModelDownload(eventData)
          }
        }
      } catch (error) {
        console.error('Incorrect format was given in mapping.', error)
      }
    },
    [building]
  )

  const handleRotateCamera = (arg: boolean) => {
    if (!isModelLoaded) return
    if (arg === cameraState) return
    setCameraState(arg)
    if (arg) {
      callUnityFunction('SetAutoRotateSpeed', { Value: 0.2 })
      callUnityFunction('AutoRotateNow', null)
      callUnityFunction('EnableAutoRotation', null)
    } else {
      callUnityFunction('SetAutoRotateSpeed', { Value: 0 })
      callUnityFunction('DisableAutoRotation', null)
    }
  }

  const handleRotateCameraBy = (settings: {
    direction: string
    triggerKey: string
  }) => {
    const { direction, triggerKey } = settings
    if (!isModelLoaded) return
    if (direction === '' || triggerKey === '') return
    if (triggerKey === rotateCameraByTriggerKey) return
    setRotateCameraByTrigger(triggerKey)
    const value = direction === 'left' ? 30 : -30
    callUnityFunction('RotateCameraBy', { Value: value })
  }

  const handleZoomCamera = (settings: {
    action: string
    triggerKey: string
  }) => {
    const { action, triggerKey } = settings

    if (!isModelLoaded) return
    if (action === '' || triggerKey === '') return
    if (triggerKey === zoomCameraByTriggerKey) return
    setZoomCameraByTrigger(triggerKey)
    const value =
      action === 'in' ? currentZoomValue - 0.1 : currentZoomValue + 0.1
    if (value > 1 || value < 0) return
    setCurrentZoomValue(value)
    callUnityFunction('ZoomCamera', { Value: value })
  }

  const handleModelReset = (arg: string) => {
    if (!isModelLoaded || arg === '') return
    if (arg === modelResetTriggerKey) return

    setModelResetTriggerKey(arg)
    callUnityFunction('UnfocusAll', null)
    callUnityFunction('ZoomCamera', { Value: cameraZoomValueInConfig })
    setCurrentZoomValue(cameraZoomValueInConfig)
    callUnityFunction('RotateCameraBy', { Value: 0 })
    callUnityFunction('SetAutoRotateSpeed', { Value: 0 })
    callUnityFunction('DisableAutoRotation', null)
  }

  const getFilteredData = (): Array<string> => {
    const filteredLevelList: Array<string> = []

    const { levels } = building

    levels.forEach((level) => {
      if (!filteredLevelList.includes(level.level)) {
        level.data.some((unit) => {
          const shouldFilter =
            (activeBuilding === '' || unit.blockId === activeBuilding) &&
            filterUnitUtil(unit, unitFilter, showPrice)

          if (shouldFilter) {
            filteredLevelList.push(level.level)
            return true
          }
          return false
        })
      }
    })

    return filteredLevelList
  }

  const resetHighlightedLevels = (): void =>
    callUnityFunction('ClearFilters', null)

  const highlightLevels = (filteredItems: Array<string>) => {
    if (filteredItems.length < 1) return

    const matchedItems: Array<{ building: string; level: any }> = []
    filteredItems.forEach((item: string) => {
      if (levelList?.find((lvl: string) => lvl === item)) {
        matchedItems.push({
          building: activeBuilding,
          level: item,
        })
      }
    })

    if (matchedItems.length < 1) return

    setLevelHighlightState(true)
    callUnityFunction('HighlightLevels', {
      Value: matchedItems,
    })
  }

  const applyFilter = () => {
    const { levels } = building

    if (levels.length < 1) return

    const { apply } = unitFilter

    if (iframeRef.current?.contentWindow && isLevelHighlighted && !apply) {
      setLevelHighlightState(false)
      resetHighlightedLevels()
    }

    if (!apply) return

    resetHighlightedLevels()
    const filteredLevelList = getFilteredData()
    highlightLevels(filteredLevelList)
  }

  React.useEffect(() => {
    const { building: buildingData, blockOrders: blockOrdersData } =
      buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          blockOrders: blockOrdersData,
          levels: buildingData,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
  }, [buildingPayload])

  React.useEffect(() => {
    applyFilter()
  }, [unitFilter, envisionVRBuildings, levelList])

  React.useEffect(() => {
    if (envisionVRBuildings.length) {
      envisionVRBuildings.forEach((block) => {
        callUnityFunction('GetLevels', { Value: block })
      })
    }
  }, [envisionVRBuildings])

  React.useEffect(() => {
    if (
      envisionVRLevelsByBlock &&
      building &&
      Object.keys(envisionVRLevelsByBlock).length
    ) {
      callUnityFunction('DisableLevels', {
        Value: getInactiveLevelAndBlocks(
          building.levels,
          envisionVRLevelsByBlock
        ),
      })
    }
  }, [building, envisionVRLevelsByBlock])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload])

  React.useEffect(() => {
    const { building: buildingData } = buildingPayload

    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          activeLevel: buildingData[0]?.level,
          activeBlock: buildingData[0]?.data[0].blockId || '',
        })
      )
    }
  }, [buildingPayload, building])

  React.useEffect(() => {
    if (envisionVRBuildings.length === envisionVRLevels.length) {
      const obj = {} as EnvisionVRLevelsByBlockInterface
      envisionVRBuildings.forEach((block, i) => {
        obj[block] = envisionVRLevels[i]
      })
      setEnvisionVRLevelsByBlock(obj)
    }
  }, [envisionVRLevels])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: { unitFilter: unitFilterFirebase },
        envisionVR: {
          rotateCamera: rotateCameraFirebase,
          rotateCameraBy: rotateCameraByFirebase,
          zoomCamera: zoomCameraFirebase,
          activeBuilding: activeBuildingFirebase,
          modelResetTriggerKey: modelResetTriggerKeyFirebase,
        },
      } = session
      dispatch(setFilter(unitFilterFirebase))
      setRemoteAppConnectionState(connected)
      setActiveBuilding(activeBuildingFirebase)
      handleRotateCamera(rotateCameraFirebase)
      handleRotateCameraBy(rotateCameraByFirebase)
      handleZoomCamera(zoomCameraFirebase)
      handleModelReset(modelResetTriggerKeyFirebase)
    }
  }, [session])

  React.useEffect(() => {
    if (session) {
      const {
        envisionVR: { activeBuilding: activeBuildingFirebase },
      } = session
      handleActiveBuildingFirebase(activeBuildingFirebase)
    }
  }, [session, envisionVRLevelsByBlock])

  React.useEffect(() => {
    if (envisionVRLevelsByBlock) {
      setActiveBuilding(building.activeBlock)
      const levelsByBlock = envisionVRLevelsByBlock[building.activeBlock]
      if (levelsByBlock) {
        setLevelList(levelsByBlock)
      }
    }
  }, [building.activeBlock, envisionVRLevelsByBlock])

  React.useEffect(() => {
    if (isRemoteAppConnected) {
      firebaseControlQuery.update({
        [`envisionVR.isLoaded`]: false,
      })
    }
  }, [isRemoteAppConnected])

  React.useEffect(() => {
    window.addEventListener('message', postMessageEventListener, false)
    return () =>
      window.removeEventListener('message', postMessageEventListener, false)
  }, [building])

  if (!hasEnvisionVR(envisionVRConfiguration))
    return <DataNotFound message="EnvisionVR has not configured properly." />

  return (
    <Container>
      <DataHandler
        payload={{
          ...buildingPayload,
          data: building.levels,
          apiData: buildingPayload.building,
        }}
        skeletonFrame={<Skeleton />}
      >
        <div
          className={`absolute left-5 top-5 z-20 ${
            session?.connected || !isModelLoaded ? 'invisible' : ''
          }`}
        >
          <IdleTimeHandler>
            <div className="flex items-center gap-4">
              <button
                data-testid="toggle-filter"
                onClick={() => toggleFilter(!isFilterOpen)}
                type="button"
                className="rounded bg-white p-2.5 drop-shadow-40"
              >
                <FilterSvg className="h-5 w-5" />
              </button>
              {showDownloadOption === 'true' && (
                <button
                  data-testid="model-download-button"
                  onClick={() => callUnityFunction('DownloadModel', null)}
                  type="button"
                  className="rounded bg-white p-2.5 drop-shadow-40"
                >
                  <ArrowDownTraySvg
                    className="h-5 w-5"
                    fill="none"
                    stroke={2}
                  />
                </button>
              )}
            </div>
          </IdleTimeHandler>
        </div>

        <div
          className={`absolute right-2 top-[45%] rounded bg-white p-4 text-lg font-bold drop-shadow-40 ${
            hoveredLevel === '' ? 'hidden' : ''
          }`}
        >
          {hoveredLevel}
        </div>

        <iframe
          title="envisionVR"
          ref={iframeRef}
          src={getModelSrc()}
          className="h-screen w-screen"
        ></iframe>

        <FilterPopup
          isOpen={isFilterOpen}
          toggle={toggleFilter}
          hideLevelOption
        />
      </DataHandler>
    </Container>
  )
}
export default connect(
  ({
    projectIdentity,
    projectConfig: { showPrice, envisionVRConfiguration },
    firestore,
    building,
    unitFilter,
    appConfig: { envisionAssetConfig },
  }: RootStateTypeExtra) => ({
    projectIdentity,
    showPrice,
    envisionVRConfiguration,
    session: getSession(firestore),
    building,
    unitFilter,
    envisionAssetConfig,
  })
)(EnvisionVR)
