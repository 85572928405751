import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { VisionData } from '@api/vision'

import isOffline from '@utilities/offline-mode-util'

let initialState = {
  gallery: [],
  title: '',
} as VisionData

if (isOffline()) {
  const visionData = localStorage.getItem('visionApiData')
  if (visionData) {
    initialState = JSON.parse(visionData)
  }
}

const visionSlice = createSlice({
  name: 'vision',
  initialState,
  reducers: {
    setVision: (state: VisionData, action: PayloadAction<VisionData>) => {
      if (isOffline()) {
        localStorage.setItem(
          'visionApiData',
          JSON.stringify({
            ...state,
            ...action.payload,
          })
        )
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    resetVision: () => {
      localStorage.removeItem('visionApiData')
      return {
        gallery: [],
        title: '',
      }
    },
  },
})

export const { setVision, resetVision } = visionSlice.actions

export const selectVision = (state: RootStateTypeExtra) => state.vision

export default visionSlice.reducer
