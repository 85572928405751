import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { selectHouseAndLand } from '@store/actionSlices/houseAndLand'
import { filterShortlist, removeShortlist } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  RootStateFirebase,
  SessionMap,
  ShortlistPropertyType,
} from '@store/types'

import { DotsVerticalSvg } from '@components/adgroup-svg/react'
import { GenericModalCard as ModalCard } from '@components/modals'

import {
  LotInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'

import AssetHandler from '@utilities/asset-handler'
import getSession from '@utilities/firebase-util'
import { formatPrice } from '@utilities/helper'
import useParseLotPackageConfiguration from '@utilities/lot-package-util'

import LotThumbnail from './lot-thumbnail'

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

interface LotsListProps {
  session: SessionMap | undefined
  shortlists: LotShortlistInterface[]
  projectId: string
}

const LotsList = ({ session, shortlists, projectId }: LotsListProps) => {
  const { matrixData, packages } = useSelector(selectHouseAndLand)
  const dispatch = useDispatch()
  const isConnected = React.useMemo(
    () => !!session?.connected,
    [session?.connected]
  )
  const [actionableLot, setActionableLot] = React.useState<{
    active: boolean
    data?: LotShortlistInterface
  }>()
  const [parsePackageConfiguration] = useParseLotPackageConfiguration()

  const [showActions, setShowActions] = React.useState(false)
  const [promptClearAllConfirmation, setPromptClearAllConfirmation] =
    React.useState(false)

  const clearAllActionContainerRef = React.useRef<HTMLDivElement | null>(null)

  const parseLotShortlist = React.useCallback(
    (lotShortlist: LotShortlistInterface) => {
      const { precinctId, stageId } = lotShortlist.references
      const matchedStage = Object.values(
        matrixData?.[precinctId]?.stages || {}
      )?.find((stg) => stg.label === stageId)
      const matchedLot = matchedStage?.lots.find(
        (lot) => lot.id === lotShortlist.propertyId
      )
      const matchedPackage = packages.find(
        (pkg) => pkg.id === lotShortlist.configuration?.floorplan
      )
      return {
        lot: matchedLot,
        package: matchedPackage,
      }
    },
    [matrixData, packages]
  )

  const actionableLotData = React.useMemo(() => {
    if (!actionableLot?.data) {
      return undefined
    }
    const { lot, package: lotPackage } = parseLotShortlist(actionableLot.data)
    if (!lot) {
      return undefined
    }
    const { configurationTitle, price, photo } = parsePackageConfiguration({
      lot,
      package: lotPackage,
      configuration: actionableLot.data.configuration,
      projectId,
    })

    return {
      name: lot.name,
      configurationTitle,
      photo,
      price,
    }
  }, [parsePackageConfiguration, actionableLot, parseLotShortlist])

  const lotThumbnails = React.useMemo(
    () =>
      shortlists.reduce<
        {
          id: string
          lot: LotInterface
          package?: PackageOptionsInterface
          configuration?: PackageConfigurationInterface
          shortlistItem: LotShortlistInterface
        }[]
      >((a, c) => {
        const key = `${c.propertyId}__${Object.keys(c.configuration || {})
          .map(
            (key) =>
              (c.configuration || {})[
                key as keyof PackageConfigurationInterface
              ]
          )
          .join('__')}`
        const { lot, package: lotPackage } = parseLotShortlist(c)

        if (lot) {
          a.push({
            id: key,
            lot,
            package: lotPackage,
            configuration: c.configuration,
            shortlistItem: c,
          })
        }
        return a
      }, []),
    [shortlists, parseLotShortlist]
  )

  const handleToggleActions = React.useCallback(() => {
    setShowActions((prevVal) => !prevVal)
  }, [])

  const handleClickAction = React.useCallback(() => {
    setPromptClearAllConfirmation(true)
  }, [])

  const handleRemoveShortlist = React.useCallback(
    (lot: LotShortlistInterface) => () => {
      if (isConnected) return

      setActionableLot({ active: true, data: lot })
    },
    [isConnected]
  )

  const handleToggleActionableLot = React.useCallback((show: boolean) => {
    setActionableLot((prevVal) => ({ ...prevVal, active: show }))
  }, [])

  const handleRemove = React.useCallback(() => {
    if (actionableLot?.data) {
      dispatch(
        filterShortlist({
          propertyId: actionableLot.data.propertyId,
          type: ShortlistPropertyType.Lot,
        })
      )
    }
  }, [actionableLot])

  const handleRemoveAll = React.useCallback(() => {
    setShowActions(false)
    dispatch(removeShortlist())
  }, [])

  const handleClearAllOutsideClick = React.useCallback(
    (event) => {
      if (
        showActions &&
        clearAllActionContainerRef.current &&
        !clearAllActionContainerRef.current.contains(event.target as Node)
      ) {
        setShowActions(false)
      }
    },
    [showActions]
  )

  React.useEffect(() => {
    if (!showActions) {
      document.removeEventListener('click', handleClearAllOutsideClick)
      return undefined
    }
    document.addEventListener('click', handleClearAllOutsideClick)
    return () => {
      document.removeEventListener('click', handleClearAllOutsideClick)
    }
  }, [handleClearAllOutsideClick])

  return (
    <>
      <div className="relative mb-[38px] flex items-center justify-between">
        <p className="text-2xl font-semibold">
          {lotThumbnails.length} Shortlisted Residence
          {lotThumbnails.length > 1 ? 's' : ''}
        </p>
        {lotThumbnails.length ? (
          <button
            className="ml-auto"
            type="button"
            onClick={handleToggleActions}
          >
            <DotsVerticalSvg
              className="h-[25px] w-[25px] !rotate-90"
              fill="fill-black"
            />
          </button>
        ) : null}
        {showActions ? (
          <div
            className="absolute right-0 top-10 z-10 whitespace-nowrap rounded bg-white text-lg shadow-md"
            ref={clearAllActionContainerRef}
          >
            <button
              className="p-3 font-medium text-red-700"
              type="button"
              onClick={handleClickAction}
            >
              Clear shortlist
            </button>
          </div>
        ) : null}
      </div>
      <div className="no-scrollbar flex flex-auto flex-col gap-10 overflow-y-auto">
        {lotThumbnails.map((item) => (
          <LotThumbnail
            key={item.id}
            lot={item.lot}
            package={item.package}
            configuration={item.configuration}
            onRemove={handleRemoveShortlist(item.shortlistItem)}
          />
        ))}
      </div>

      <ModalCard
        headerLabel="Remove from shortlist"
        toggle={handleToggleActionableLot}
        cancelAction={() => {}}
        okAction={handleRemove}
        isVisible={!!actionableLot?.active}
        okLabel="Remove"
        cancelLabel="Cancel"
        widthClassName="md:w-2/3 lg:w-1/4"
        headerClassName="text-[21px] font-medium px-2 pb-2"
        closeClassName="ml-auto pb-2"
        footerClassName="flex flex-row-reverse gap-3 border-t border-gray-300 py-4 px-6"
        okClassName="rounded px-3 py-2 text-[#FF0000] bg-[#EEEEEE] text-[17px]"
        cancelClassName="rounded bg-blue-500 px-3 py-2 text-white transition-all duration-300 text-[17px]"
        fitToScreen
      >
        <div className="px-6 py-4 text-left">
          <p className="text-[17px]">
            Are you sure you want to remove this from your shortlist?
          </p>
          {actionableLotData ? (
            <div className="mt-5 flex  gap-4">
              <div className="h-20 w-20 shrink-0 overflow-hidden rounded">
                <img
                  className="h-full w-full rounded-t-md object-cover"
                  alt="thumbnail"
                  src={AssetHandler({
                    url: String(
                      actionableLotData.photo.url || PLACEHOLDER_IMAGE
                    ),
                    type: actionableLotData?.photo?.config?.type || 'new',
                    staticUrl:
                      actionableLotData?.photo?.config?.staticUrl ?? true,
                    noSpliceUrl:
                      actionableLotData?.photo?.config?.noSpliceUrl ?? false,
                  })}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[17px] font-bold leading-[20px]">
                  Lot {actionableLotData.name}
                </p>
                <p className="font-medium leading-[19px]">
                  {formatPrice(actionableLotData.price)}
                </p>
                {actionableLotData.configurationTitle ? (
                  <p className="font-medium italic leading-[19px] text-[#666666]">
                    {actionableLotData.configurationTitle}
                  </p>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </ModalCard>

      <ModalCard
        headerLabel="Clear shortlists"
        toggle={setPromptClearAllConfirmation}
        cancelAction={() => {}}
        okAction={handleRemoveAll}
        isVisible={promptClearAllConfirmation}
        okLabel="Clear"
        cancelLabel="Cancel"
        headerClassName="text-[21px] font-medium px-2 pb-2"
        closeClassName="ml-auto pb-2"
        footerClassName="flex flex-row-reverse gap-3 border-t border-gray-300 py-4 px-6"
        okClassName="rounded px-3 py-2 text-[#FF0000] bg-[#EEEEEE] text-[17px]"
        cancelClassName="rounded bg-blue-500 px-3 py-2 text-white transition-all duration-300 text-[17px]"
      >
        <div className="px-4 text-left">
          <p>Are you sure you want to clear all shortlists?</p>
        </div>
      </ModalCard>
    </>
  )
}

export default connect(
  ({ firestore, projectIdentity: { projectId } }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectId,
  })
)(LotsList)
