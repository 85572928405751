import React from 'react'

import { SvgProps } from './types'

const ArrowDownTraySvg = ({
  width,
  height,
  className,
  styles,
  fill,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    className={className}
    style={{
      ...styles,
    }}
    width={width || '48'}
    height={height || '48'}
    fill={fill || 'currentColor'}
    viewBox="0 0 24 24"
    strokeWidth={stroke || 1.5}
    stroke={strokeColor || 'currentColor'}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
    />
  </svg>
)

export default ArrowDownTraySvg
