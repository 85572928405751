import { RootStateTypeExtra } from '@store/types'

const prepareHeaders = (
  headers: Headers,
  { getState }: { getState: () => unknown }
) => {
  const {
    token: {
      access_token: { token },
      email,
    },
  } = getState() as RootStateTypeExtra
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }
  if (email) {
    headers.set('user', email)
  }
  return headers
}

export default prepareHeaders
