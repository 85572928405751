import React from 'react'

import { SvgProps } from './types'

const BedSvg = ({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      ...styles,
    }}
    width={width || '48'}
    height={height || '48'}
    fill="none"
    stroke={strokeColor || 'currentColor'}
    viewBox="0 0 18 17"
    strokeWidth={stroke || 1.4}
  >
    <path d="M15.625 2.83335V14.1667C15.625 14.5424 15.4757 14.9027 15.2101 15.1684C14.9444 15.4341 14.5841 15.5834 14.2083 15.5834H4.29167C3.91594 15.5834 3.55561 15.4341 3.28993 15.1684C3.02426 14.9027 2.875 14.5424 2.875 14.1667V2.83335C2.875 2.45763 3.02426 2.0973 3.28993 1.83162C3.55561 1.56594 3.91594 1.41669 4.29167 1.41669H14.2083C14.5841 1.41669 14.9444 1.56594 15.2101 1.83162C15.4757 2.0973 15.625 2.45763 15.625 2.83335Z" />
    <path d="M2.875 5.66667H8.54167V4.25M15.625 5.66667H9.95833V4.25" />
  </svg>
)

export default BedSvg
