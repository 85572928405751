import React from 'react'

export interface ToggleSwitchInterface {
  initialState: boolean
  onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  onColor?: string
  offColor?: string
}
const ToggleSwitch = ({
  initialState,
  onChangeCallback,
  isDisabled = false,
  onColor = 'bg-lime-500',
  offColor = 'bg-gray-400',
}: ToggleSwitchInterface) => {
  const [backgroundColor, setBackgroundColor] = React.useState(offColor)
  const [sliderPosition, setSliderPosition] = React.useState('left-[0.200rem]')
  React.useEffect(() => {
    setSliderPosition(initialState ? 'left-[1.35rem]' : 'left-[0.200rem]')
    setBackgroundColor(initialState ? onColor : offColor)
  }, [initialState])
  return (
    <label
      className={`relative h-[1.125rem] w-9 cursor-pointer rounded-full ${backgroundColor}`}
      aria-label="toggle switch control"
    >
      <input
        disabled={isDisabled}
        type="checkbox"
        checked={initialState}
        className="sr-only"
        onChange={onChangeCallback}
      />
      <span
        className={`absolute top-[0.19rem] h-3 w-3 rounded-full bg-white transition-all duration-300 ${sliderPosition}`}
      ></span>
    </label>
  )
}
export default ToggleSwitch
