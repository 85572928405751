import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

const currentOfflineMode = localStorage.getItem('offlineMode') === 'true'
const currentFirebasePort = localStorage.getItem('firebasePort')
const currentFirebaseIp = localStorage.getItem('firebaseIp')
const currentAssetPort = localStorage.getItem('assetPort')
const currentEnvisionAssetConfig = localStorage.getItem('envisionAssetConfig')
const currentRoom = localStorage.getItem('room')
const currentSearchQuery = localStorage.getItem('searchQuery')
const currentShortlistPropertyHash = localStorage.getItem(
  'shortlistPropertyHash'
)

export interface EnvisionAssetConfigInterface {
  host: string
  port: string
}

export interface AppConfigInterface {
  offlineMode: boolean
  firebasePort: string
  firebaseIp: string
  assetPort: string
  envisionAssetConfig: EnvisionAssetConfigInterface
  room: string
  searchQuery: boolean
  shortlistPropertyHash: string
}

const initialState = {
  offlineMode: currentOfflineMode || false,
  searchQuery: currentSearchQuery || false,
  firebasePort: currentFirebasePort || '',
  firebaseIp: currentFirebaseIp || 'localhost',
  assetPort: currentAssetPort || '',
  envisionAssetConfig: currentEnvisionAssetConfig
    ? JSON.parse(currentEnvisionAssetConfig)
    : {
        host: '127.0.0.1',
        port: '',
      },
  room: currentRoom || '',
  shortlistPropertyHash: currentShortlistPropertyHash || '',
} as AppConfigInterface

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setOfflineMode: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem('offlineMode', JSON.stringify(action.payload))
      return {
        ...state,
        offlineMode: action.payload,
      }
    },
    setFirebasePort: (state, action: PayloadAction<string>) => {
      localStorage.setItem('firebasePort', action.payload)
      return {
        ...state,
        firebasePort: action.payload,
      }
    },
    setFirebaseIp: (state, action: PayloadAction<string>) => {
      localStorage.setItem('firebaseIp', action.payload)
      return {
        ...state,
        firebaseIp: action.payload,
      }
    },
    setAssetPort: (state, action: PayloadAction<string>) => {
      localStorage.setItem('assetPort', action.payload)
      return {
        ...state,
        ...{ assetPort: action.payload },
      }
    },
    setEnvisionAssetConfig: (
      state: AppConfigInterface,
      action: PayloadAction<EnvisionAssetConfigInterface>
    ) => {
      localStorage.setItem(
        'envisionAssetConfig',
        JSON.stringify(action.payload)
      )
      return { ...state, envisionAssetConfig: action.payload }
    },
    setRoom: (state, action: PayloadAction<string>) => {
      localStorage.setItem('room', action.payload)
      return {
        ...state,
        ...{ room: action.payload },
      }
    },
    setSearchQuery: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem('searchQuery', JSON.stringify(action.payload))
      return {
        ...state,
        ...{ searchQuery: action.payload },
      }
    },
    setShortlistPropertyHash: (state, action: PayloadAction<string>) => {
      localStorage.setItem('shortlistPropertyHash', action.payload)
      return {
        ...state,
        ...{ shortlistPropertyHash: action.payload },
      }
    },
    removeAppConfig: (state) => {
      localStorage.removeItem('offlineMode')
      localStorage.removeItem('firebasePort')
      localStorage.removeItem('firebaseIp')
      localStorage.removeItem('assetPort')
      localStorage.removeItem('envisionAssetConfig')
      localStorage.removeItem('searchQuery')
      localStorage.removeItem('room')
      localStorage.removeItem('shortlistPropertyHash')
      return {
        ...state,
        offlineMode: false,
        searchQuery: false,
        firebaseIp: 'localhost',
        firebasePort: '',
        assetPort: '',
        envisionAssetConfig: {
          host: '127.0.0.1',
          port: '',
        },
        room: '',
        shortlistPropertyHash: '',
      }
    },
  },
})

export const {
  setOfflineMode,
  setFirebasePort,
  setAssetPort,
  setEnvisionAssetConfig,
  setRoom,
  setFirebaseIp,
  removeAppConfig,
  setSearchQuery,
  setShortlistPropertyHash,
} = appConfigSlice.actions

export const selectOfflineMode = (state: RootStateTypeExtra) => state.appConfig

export default appConfigSlice.reducer
