import { Store } from 'react-notifications-component'

const broadcastNotification = (
  title: string,
  type: 'success' | 'danger' | 'warning',
  duration: number,
  showIcon: boolean
): string =>
  Store.addNotification({
    title,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration,
      showIcon,
    },
  })

const removeAllNotifications = () => {
  Store.removeAllNotifications()
}

const removeNotification = (id: string) => {
  Store.removeNotification(id)
}

const notifySuccess = (
  title: string,
  showIcon = false,
  duration = 5000
): string => broadcastNotification(title, 'success', duration, showIcon)

const notifyError = (
  title: string,
  showIcon = false,
  duration = 5000
): string => broadcastNotification(title, 'danger', duration, showIcon)

const notifyWarning = (
  title: string,
  showIcon = false,
  duration = 5000
): string => broadcastNotification(title, 'warning', duration, showIcon)

export {
  notifySuccess,
  notifyError,
  notifyWarning,
  removeAllNotifications,
  removeNotification,
}
