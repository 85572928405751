import React, { MouseEventHandler } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { BuildingInterface, setBuilding } from '@store/actionSlices/building'
import { RootStateFirebase } from '@store/types'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-util'

import Layer from './layer'

interface LayerApartmentProps {
  building: BuildingInterface
  label: string
  actionRoute: string
}

const LayerApartment = ({
  building,
  label,
  actionRoute,
}: LayerApartmentProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [parseMapActionRoute] = useParseMapLayerActionRoute()
  const { fullPath, path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )
  const navigateTo = React.useMemo(
    () => (path === 'building' || path === 'level-view' ? fullPath : path),
    [fullPath, path]
  )

  const handleClick = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    async (e) => {
      e.preventDefault()
      history.push(navigateTo)
      dispatch(
        setBuilding({
          ...building,
          activeBlock: params.block || '',
          activeLevel: params.level || '',
          activeUnit: params.unit || '',
        })
      )
    },
    [navigateTo, params]
  )

  return (
    <Layer label={label} path={path} isValid={isValid} onClick={handleClick} />
  )
}

export default connect(({ building }: RootStateFirebase) => ({
  building,
}))(LayerApartment)
