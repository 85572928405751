const analyzePixels = (image: HTMLImageElement) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext && canvas.getContext('2d')
  const defaultRGB = [255, 255, 255]
  const width = 10
  const height = 10
  const numLines = 1
  canvas.width = width
  canvas.height = height

  if (ctx) {
    ctx.drawImage(image, 0, 0, width, height)

    try {
      const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data

      const linesPixels = []
      for (let i = 0; i < numLines * canvas.width; i += 1) {
        const index = i * 4
        const rgb = [
          pixelData[index],
          pixelData[index + 1],
          pixelData[index + 2],
        ]
        linesPixels.push(rgb)
      }

      const averageRGB = linesPixels
        .reduce(
          (acc, rgb) => {
            acc[0] += rgb[0]
            acc[1] += rgb[1]
            acc[2] += rgb[2]
            return acc
          },
          [0, 0, 0]
        )
        .map((val) => Math.round(val / linesPixels.length))
      return averageRGB
    } catch (e) {
      return defaultRGB
    }
  }
  return defaultRGB
}

export default analyzePixels
