import React, { RefObject } from 'react'
import InputRange from 'react-input-range'
import ReactPlayer from 'react-player'

interface ScrubBarProps {
  playerReference: RefObject<ReactPlayer>
  videoLengthInMilliseconds: number
  isPlaying: boolean
  isBuffing: boolean
}

const ScrubBar = ({
  playerReference,
  videoLengthInMilliseconds,
  isPlaying,
  isBuffing,
}: ScrubBarProps) => {
  const [sliderRangeValue, setSliderRangeValue] = React.useState(0)
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const setSliderRangeInterval = () => {
    if (intervalId) return

    setIntervalId(
      setInterval(() => {
        setSliderRangeValue((previousValue) => {
          const newValue = previousValue + 250
          return newValue > videoLengthInMilliseconds ? 0 : newValue
        })
      }, 250)
    )
  }

  const resetSliderRangeInterval = () => {
    if (intervalId) clearInterval(intervalId)
    setIntervalId(undefined)
  }

  const handleOnChangeComplete = (valueInMilliseconds: number) => {
    playerReference.current?.seekTo(valueInMilliseconds / 1000)
    if (isPlaying && !isBuffing) setSliderRangeInterval()
  }

  const handleOnChange = (valueInMilliseconds: number) => {
    resetSliderRangeInterval()
    setSliderRangeValue(valueInMilliseconds)
  }

  React.useEffect(() => {
    if (isPlaying && !isBuffing) {
      setSliderRangeInterval()
    } else {
      resetSliderRangeInterval()
    }
  }, [isPlaying, isBuffing])

  React.useEffect(
    () => () => {
      resetSliderRangeInterval()
    },
    []
  )

  return (
    <div className="flex items-center">
      <InputRange
        minValue={0}
        maxValue={videoLengthInMilliseconds}
        value={sliderRangeValue}
        onChange={(value) => handleOnChange(Number(value))}
        onChangeComplete={(value) => handleOnChangeComplete(Number(value))}
        formatLabel={() => ''}
      />
    </div>
  )
}

export default ScrubBar
