import { Color, Quaternion, Vector3 } from 'three'

const removeMaterialHighlight = (material: any) => {
  if (typeof material.originalEmissive !== 'undefined') {
    const newMaterial = { ...material }
    newMaterial.emissive.setHex(material.originalEmissive)
    delete newMaterial.originalEmissive
    return newMaterial
  }
  return material
}

const removeHighlight = (node: any) => {
  node.traverse((itSubNode: any) => {
    if (itSubNode.type !== 'Mesh' || !itSubNode.material) return

    if (Array.isArray(itSubNode.material)) {
      itSubNode.material.forEach((material: any) =>
        removeMaterialHighlight(material)
      )
    } else {
      removeMaterialHighlight(itSubNode.material)
    }
  })
}

const addMaterialHighlight = (material: any, color: any) => {
  const newMaterial = material.clone()
  newMaterial.uniqueMaterial = true

  if (newMaterial.emissive) {
    if (typeof newMaterial.originalEmissive === 'undefined') {
      newMaterial.originalEmissive = newMaterial.emissive.getHex()
    }

    newMaterial.emissive.setHex(color.getHex())
  }
  return newMaterial
}

const addHighlight = (node: any) => {
  const color = new Color(0xdd6502)

  const nodeArray: Array<any> = [node]

  if (node.children && node.children.length > 0) {
    nodeArray.push(...node.children)
  }

  nodeArray.forEach((childNode: any, index: number) => {
    if (childNode.type !== 'Mesh' || !childNode.material) return

    let newMaterial: any = null

    if (Array.isArray(childNode.material)) {
      newMaterial = childNode.material.map((material: any) =>
        addMaterialHighlight(material, color)
      )
    } else {
      newMaterial = addMaterialHighlight(childNode.material, color)
    }

    nodeArray[index].material = newMaterial
  })
}

const findSubNodeByName = (
  parentNode: any,
  nodeName: string,
  level: number
): any => {
  let result

  parentNode.children.forEach((itChild: any) => {
    if (itChild.name === nodeName) {
      result = itChild
      return
    }

    if (itChild.children && itChild.children.length) {
      const subChildNode = findSubNodeByName(itChild, nodeName, level + 1)
      if (subChildNode) {
        result = subChildNode
      }
    }
  })

  return result
}

const findNodeByName = (scene: any, nodeName: string) => {
  try {
    return findSubNodeByName(scene, nodeName, 0)
  } catch (e) {
    console.error(`throw - failed finding findNodeByName[${nodeName}]`, e)
  }
  return undefined
}

const getCameraOrientation = (targetStep: any) => {
  const stepCameraPosition = targetStep.camera.position
  const stepCameraRotation = targetStep.camera.rotation
  const stepIndicatorPosition = targetStep.indicator.position
  const stepTargetDistance = targetStep.targetDistance
  const endPosition = new Vector3(
    stepCameraPosition.x,
    stepCameraPosition.y,
    stepCameraPosition.z
  )
  const endRotation = new Quaternion(
    stepCameraRotation.x,
    stepCameraRotation.y,
    stepCameraRotation.z,
    stepCameraRotation.w
  )
  const endTargetDistance =
    stepTargetDistance ||
    new Vector3(
      stepIndicatorPosition.x,
      stepIndicatorPosition.y,
      stepIndicatorPosition.z
    ).distanceTo(endPosition)

  return { endPosition, endRotation, endTargetDistance }
}

const handleLoadingState = (interval: NodeJS.Timeout) => {
  const container = document.querySelector(
    '.snaploader-v3d-navigation-container'
  ) as HTMLElement
  const loadingElement = document.querySelector(
    '.snaploader-v3d-loading'
  ) as HTMLElement
  const genericLoaderElement = document.querySelector(
    '.snaploader-v3d-loading-indicator-container.generic-loader'
  ) as HTMLElement

  const progressbarPathElement = document.querySelector(
    '.snaploader-v3d-loading-indicator-container.generic-loader svg path:nth-child(2)'
  ) as HTMLElement

  const cloneProgressbarPathElement = document.querySelector(
    '.snaploader-v3d-loading-indicator-container.generic-loader svg path:nth-child(3)'
  ) as HTMLElement

  const hiddenLoader = document.querySelector(
    '.snaploader-v3d-loading.snaploader-v3d-hidden'
  ) as HTMLElement

  if (
    container &&
    loadingElement &&
    !container.classList.contains('bg-mainColour')
  ) {
    container.classList.add('bg-mainColour')
    loadingElement.style.zIndex = '10'
    genericLoaderElement.style.backgroundImage =
      "url('/generic-snap-loader.svg')"
  }

  if (progressbarPathElement && !cloneProgressbarPathElement) {
    const clonedPath = progressbarPathElement.cloneNode(true)

    if (progressbarPathElement.parentNode) {
      progressbarPathElement.style.visibility = 'hidden'
      progressbarPathElement.parentNode.insertBefore(
        clonedPath,
        progressbarPathElement.nextSibling
      )
    }
  }

  if (cloneProgressbarPathElement && progressbarPathElement) {
    const { strokeDashoffset } = progressbarPathElement.style
    cloneProgressbarPathElement.setAttribute('stroke', 'rgb(136,136,136)')
    cloneProgressbarPathElement.style.strokeDashoffset = strokeDashoffset
  }

  if (hiddenLoader) {
    container.classList.remove('bg-mainColour')
    clearInterval(interval)
  }
}

export {
  findNodeByName,
  addHighlight,
  removeHighlight,
  getCameraOrientation,
  handleLoadingState,
}
