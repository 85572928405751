import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface ProjectListDetails {
  id: string
  title: string
  titleCanonical: string
}

export interface ProjectPagination {
  items: number
  page: number
  total: number
}

type ProjectListType = {
  email: string
  page: number
  query: string
}

export const projectListApi = createApi({
  reducerPath: 'projectListApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getProjectList: builder.query<
      ApiResponse<Array<ProjectListDetails>>,
      ProjectListType
    >({
      query: (params: ProjectListType) => ({
        url: `/v1/project/lists/${params.email}`,
        method: 'get',
        params: {
          page: params.page,
          query: params.query,
          show: 5,
          archived: false,
          showcaseActive: true,
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  status,
  data,
  error,
  isError,
  isLoading,
}: UseQueryStateResult<any, any>): {
  list: []
  pagination: ProjectPagination
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  list: data?.data?.lists || [],
  pagination: data?.data?.meta?.pagination || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetProjectListQuery } = projectListApi
