import React from 'react'

import { SvgProps } from './types'

const PlusCircleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  stroke,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 20 21"
      stroke="currentColor"
      strokeWidth={stroke || '1'}
    >
      <path
        d="M6.4 10.5H10M10 10.5H13.6M10 10.5V6.9M10 10.5V14.1M10 19.5C14.9707 19.5 19 15.4707 19 10.5C19 5.5293 14.9707 1.5 10 1.5C5.0293 1.5 1 5.5293 1 10.5C1 15.4707 5.0293 19.5 10 19.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusCircleSvg
