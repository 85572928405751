import React from 'react'
import { connect } from 'react-redux'

import { ConfigInterface } from '@store/actionSlices/projectConfig'
import { RootStateFirebase } from '@store/types'

import { ProjectType } from '@utilities/adgroup-utilities/types/common'

import Apartment from './apartment'
import HouseAndLand from './house-and-land'

interface PanoramicViewProps {
  projectConfig: ConfigInterface
}

const PanoramicView = ({ projectConfig }: PanoramicViewProps) =>
  projectConfig.type === ProjectType.HouseAndLand ? (
    <HouseAndLand />
  ) : (
    <Apartment />
  )

export default connect(({ projectConfig }: RootStateFirebase) => ({
  projectConfig,
}))(PanoramicView)
