import { CarouselProvider, CarouselProviderProps } from 'pure-react-carousel'
import React from 'react'
import { useSelector } from 'react-redux'

import { GalleryControlInterface, RootStateFirebase } from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'

interface DefaultSlideProp {
  id: string
}

export interface GalleryProviderInterface<T extends DefaultSlideProp>
  extends Omit<CarouselProviderProps, 'totalSlides'> {
  galleryName: string
  galleryControl?: GalleryControlInterface
  originalSlides: Array<T>
  children: (
    slideContent: Array<T>,
    activeIndex: number,
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  ) => React.ReactChild
  totalSlides?: number
  skipInfinite?: boolean
  isConnected?: boolean
}

const GalleryProvider = <T extends DefaultSlideProp>({
  galleryName,
  children,
  galleryControl,
  originalSlides,
  totalSlides,
  skipInfinite = false,
  isConnected,
  ...props
}: GalleryProviderInterface<T>) => {
  const { projectIdentity, transitionTime, transitionType } = useSelector(
    (state: RootStateFirebase) => ({
      projectIdentity: state.projectIdentity,
      transitionType: state.projectConfig?.gallerySettings?.slideShow?.type,
      transitionTime:
        Number(
          state.projectConfig?.gallerySettings?.slideShow?.intervalInSeconds ||
            7
        ) * 1000,
    })
  )

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [intervalId, setIntervalId] = React.useState<NodeJS.Timer>()

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [slideContent, setSlideContent] = React.useState<Array<T>>(
    originalSlides || []
  )

  const handleIntervals = () => {
    const extraSlide = originalSlides[0]

    setSlideContent(() => {
      if (skipInfinite || transitionType === 'fadeIn') {
        return [...originalSlides]
      }
      return [...originalSlides, extraSlide]
    })

    const interval = setInterval(() => {
      setActiveIndex((currIndex) => {
        if (
          (totalSlides && currIndex < totalSlides - 1) ||
          (currIndex < originalSlides.length && transitionType === 'slide') ||
          (currIndex < originalSlides.length - 1 && transitionType === 'fadeIn')
        ) {
          return currIndex + 1
        }

        return 0
      })
    }, transitionTime)

    setIntervalId(interval)
  }

  React.useEffect(() => {
    if (galleryControl) {
      const {
        activeItemID: activeItemIDFirebase,
        isPlaying: isPlayingFirebase,
      } = galleryControl

      const activeItemIndexFirebase = originalSlides.findIndex(
        (item) => item.id === activeItemIDFirebase
      )
      setActiveIndex(
        activeItemIndexFirebase !== -1 ? activeItemIndexFirebase : 0
      )
      setIsPlaying(isPlayingFirebase)
    }
  }, [galleryControl, originalSlides])

  React.useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId)
    }

    if (isPlaying) {
      handleIntervals()
    } else {
      setSlideContent(originalSlides)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isPlaying, originalSlides])

  React.useEffect(() => {
    if (isPlaying && !galleryControl?.isPlaying) {
      if (isConnected) {
        firebaseControlQuery.update({
          [`${galleryName}.galleryControl.activeItemID`]:
            activeIndex === originalSlides.length ? 0 : activeIndex,
        })
      }
    }
  }, [isPlaying, galleryControl?.isPlaying, isConnected, activeIndex])

  return (
    <CarouselProvider
      {...props}
      totalSlides={totalSlides ?? slideContent.length}
      interval={transitionTime}
      currentSlide={activeIndex}
      disableAnimation={activeIndex === 0 && isPlaying}
      infinite
    >
      {children(slideContent, activeIndex, setActiveIndex)}
    </CarouselProvider>
  )
}

export default GalleryProvider
