import type {
  BedConfigInterface,
  Configurations,
  MinMaxInterface,
  UnitFilterInterface,
} from '@store/types'

import { Configuration as ConfigurationInterface, Unit } from '@api/building'

const STATUS_AVAILABLE = 'available'

const compareRange = (range: MinMaxInterface, value: number): boolean => {
  if (range.min === 'Any' && range.max === 'Any') return true

  if (range.min === 'Any' && range.max !== 'Any')
    return value <= Number(range.max)

  if (range.min !== 'Any' && range.max === 'Any')
    return value >= Number(range.min)

  return value >= Number(range.min) && value <= Number(range.max)
}

const checkPrice = (unit: Unit, range: MinMaxInterface, showPrice: boolean) => {
  if (!showPrice) {
    return true
  }
  if (!unit.metas.price) {
    return false
  }
  return compareRange(range, unit.metas.price)
}

const compareConfigurations = (
  filterConf: Configurations,
  unitConf: ConfigurationInterface
): boolean =>
  compareRange(filterConf.bed, Number(unitConf.bed ?? 0)) &&
  compareRange(filterConf.study, Number(unitConf.study ?? 0)) &&
  compareRange(filterConf.bath, Number(unitConf.bath ?? 0)) &&
  compareRange(filterConf.powderRoom, Number(unitConf.powderRoom ?? 0)) &&
  compareRange(filterConf.car, Number(unitConf.car ?? 0))

const filterUnit = (
  unit: Unit,
  filter: UnitFilterInterface,
  showPrice: boolean
): boolean => {
  const { apply, configurations, range, showAvailable, aspect, unitType } =
    filter

  if (!apply) {
    return true
  }

  const isInPriceRange = checkPrice(unit, range, showPrice)

  const isInConfig = compareConfigurations(
    configurations ?? {},
    unit.metas.configurations ?? {}
  )

  const isAvailable = unit.metas.status === STATUS_AVAILABLE

  let isInAspect = true
  let isInUnitTypes = true

  if (aspect && aspect.length && unit.metas.aspect) {
    isInAspect = aspect.includes(unit.metas.aspect.toLocaleLowerCase())
  }

  if (unitType && unitType !== 'Any' && unit.metas.classification) {
    isInUnitTypes = unitType === unit.metas.classification
  }

  if (showAvailable) {
    return (
      isInAspect && isInUnitTypes && isAvailable && isInPriceRange && isInConfig
    )
  }

  return isInAspect && isInUnitTypes && isInPriceRange && isInConfig
}

function countFilterChanges(
  oldObject: UnitFilterInterface,
  newObject: UnitFilterInterface
) {
  let changes = 0

  Object.keys(oldObject).forEach((key) => {
    if (
      typeof oldObject[key as keyof UnitFilterInterface] === 'object' &&
      typeof newObject[key as keyof UnitFilterInterface] === 'object'
    ) {
      if (key === 'configurations') {
        Object.keys(oldObject[key as keyof UnitFilterInterface]).forEach(
          (configKey: keyof BedConfigInterface) => {
            const oldConfigValue = oldObject[key as keyof UnitFilterInterface]
            const newConfigValue = newObject[key as keyof UnitFilterInterface]
            const oldValue =
              oldConfigValue[configKey as keyof typeof oldConfigValue]
            const newValue =
              newConfigValue[configKey as keyof typeof newConfigValue]

            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
              changes += 1
            }
          }
        )
      } else if (
        JSON.stringify(oldObject[key as keyof UnitFilterInterface]) !==
        JSON.stringify(newObject[key as keyof UnitFilterInterface])
      ) {
        changes += 1
      }
    } else if (
      key !== 'apply' &&
      oldObject[key as keyof UnitFilterInterface] !==
        newObject[key as keyof UnitFilterInterface]
    ) {
      changes += 1
    }
  })

  return changes
}

export { filterUnit, checkPrice, compareConfigurations, countFilterChanges }
