import React from 'react'

import { SvgProps } from './types'

const FullScreenSvg = ({ width, height, className, stroke }: SvgProps) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.75 2H2V5.75M18.25 2H22V5.75M5.75 22H2V18.25M18.25 22H22V18.25"
      stroke="currentColor"
      strokeWidth={stroke || 2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FullScreenSvg
