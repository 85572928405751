import React from 'react'
import { useHistory, useLocation } from 'react-router'

import { ExternalViewsData } from '@api/external-views'

interface ComponentPropsInterface {
  externalViews: Array<ExternalViewsData>
  externalMenuItemsToggle: boolean
  setExternalMenuItemsToggle: (arg: boolean) => void
}

const ExternalMenuItems = ({
  externalViews,
  externalMenuItemsToggle,
  setExternalMenuItemsToggle,
}: ComponentPropsInterface) => {
  const history = useHistory()
  const location = useLocation()

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)

  const handleClick = (index: number) => {
    setActiveIndex(index)
    history.push({
      pathname: 'external-views',
      state: index,
    })
    setExternalMenuItemsToggle(false)
  }

  React.useEffect(() => {
    const { state } = location
    if (state !== undefined) {
      setActiveIndex(state)
    }
  }, [location])

  return (
    <div
      onMouseEnter={() => setExternalMenuItemsToggle(true)}
      onMouseLeave={() => setExternalMenuItemsToggle(false)}
      className={`no-scrollbar absolute top-9 z-1 max-h-[400px] overflow-hidden overflow-y-scroll rounded-md transition-all duration-500 ease-in-out ${
        externalMenuItemsToggle ? 'visible opacity-100' : 'invisible opacity-0'
      }`}
    >
      <ul className="mt-2 space-y-2 whitespace-nowrap rounded-md bg-neutralColour py-3">
        {externalViews.map((externalView: ExternalViewsData, index: number) => (
          <li key={`${externalView.title}-${index.toString()}`}>
            <button
              type="button"
              className={`w-full px-6 py-3 text-left text-xs font-semibold uppercase tracking-widest hover:bg-secondaryColour ${
                activeIndex === index ? 'bg-secondaryColour' : ''
              }`}
              onClick={() => handleClick(index)}
            >
              {externalView.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ExternalMenuItems
