import { useMemo } from 'react'
import { useLocation } from 'react-router'

const useCurrentPage = () => {
  const { pathname, state } = useLocation()
  const paths = useMemo(() => pathname.split('/'), [pathname])
  const previousPaths = useMemo(() => state?.from?.split('/') || [], [state])
  const currentPage = paths[2] ?? ''
  const previousPage = previousPaths[2] ?? ''

  return [currentPage, previousPage]
}

export default useCurrentPage
