import React from 'react'
import { useDispatch } from 'react-redux'

import {
  EnvisionAssetConfigInterface,
  setEnvisionAssetConfig,
} from '@store/actionSlices/appConfig'

import { GenericModalCard as ModalCard } from '@components/modals'

import InputHandler from '@utilities/form-util'

import { ChevronSvg } from '@svg/react'

interface ComponentPropsInterface {
  assetConfig: EnvisionAssetConfigInterface
}

const DEFAULT_HOST = '127.0.0.1'
const MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT = 65535
const IPV4_REGEX = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/

const EnvisionAssetHandler = ({ assetConfig }: ComponentPropsInterface) => {
  const dispatch = useDispatch()

  const [toggled, setToggleModal] = React.useState(false)
  const [inputHostValue, setInputHostValue] = React.useState({
    value: assetConfig.host,
    isValid: true,
    message: '',
  })
  const [inputPortValue, setInputPortValue] = React.useState({
    value: assetConfig.port,
    isValid: true,
    message: '',
  })

  const isValidHost = (value: string) => IPV4_REGEX.test(value)

  const isValidPort = (value: string) =>
    Number(value) > 0 && Number(value) <= MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT

  const handleSaveEnvisionAssetConfig = () => {
    dispatch(
      setEnvisionAssetConfig({
        host: inputHostValue.value,
        port: inputPortValue.value,
      })
    )
  }

  const resetInputs = () => {
    setInputHostValue({
      value: DEFAULT_HOST,
      isValid: true,
      message: '',
    })
    setInputPortValue({
      value: '',
      isValid: true,
      message: '',
    })
  }

  const handleRemoveEnvisionAssetConfig = () => {
    resetInputs()
    dispatch(
      setEnvisionAssetConfig({
        host: DEFAULT_HOST,
        port: '',
      })
    )
  }

  React.useEffect(() => {
    setInputHostValue({
      ...inputHostValue,
      value: assetConfig.host,
    })
    setInputPortValue({
      ...inputPortValue,
      value: assetConfig.port,
    })
  }, [toggled])

  return (
    <>
      <ModalCard
        headerLabel="Envision Asset Configuration"
        okLabel="Save"
        toggle={(status: boolean) => setToggleModal(status)}
        cancelAction={() => resetInputs()}
        okAction={() => handleSaveEnvisionAssetConfig()}
        isVisible={toggled}
        disableOk={
          inputPortValue.value.length === 0 ||
          !inputPortValue.isValid ||
          inputHostValue.value.length === 0 ||
          !inputHostValue.isValid
        }
        hideOk={assetConfig.host.length > 0 && assetConfig.port.length > 0}
      >
        <div className="px-4 text-left">
          <p>
            For improved performance you can choose to use envision model hosted
            on your local machine:
          </p>
        </div>
        <div className="px-4">
          <p className="pt-4 text-left font-bold">Host/Port:</p>
          <div className="mt-2 flex items-center">
            <input
              onChange={(event) =>
                InputHandler(
                  event,
                  setInputHostValue,
                  'The given host is invalid',
                  isValidHost
                )
              }
              className={`w-full border ${
                !inputHostValue.isValid && inputHostValue.value
                  ? 'border-red-500 focus:border-red-500'
                  : 'border-zinc-200 focus:border-zinc-200'
              } p-2 focus:outline-none focus:ring-0`}
              placeholder={DEFAULT_HOST}
              type="text"
              value={inputHostValue.value}
            />
            <input
              onChange={(event) =>
                InputHandler(
                  event,
                  setInputPortValue,
                  'The given port is invalid',
                  isValidPort
                )
              }
              className={`w-full border ${
                !inputPortValue.isValid && inputPortValue.value
                  ? 'border-red-500 focus:border-red-500'
                  : 'border-zinc-200 focus:border-zinc-200'
              } p-2 focus:outline-none focus:ring-0`}
              placeholder="8000"
              type="text"
              value={inputPortValue.value}
            />
          </div>
          <div className="flex">
            {((!inputHostValue.isValid && inputHostValue.value) ||
              (!inputPortValue.isValid && inputPortValue.value)) && (
              <p className="text-left text-sm text-red-500">
                Invalid Host or Port
              </p>
            )}
            {assetConfig.host && assetConfig.port && (
              <button
                onClick={() => handleRemoveEnvisionAssetConfig()}
                type="button"
                className="ml-auto text-red-700"
              >
                Remove
              </button>
            )}
          </div>
        </div>
      </ModalCard>
      <button
        onClick={() => setToggleModal(!toggled)}
        type="button"
        className="flex w-full items-center rounded border border-2 border-gray-300 p-3 px-5 text-left"
      >
        <span className="text-sm">Serve envision model from local</span>
        <ChevronSvg
          className="ml-auto h-5 w-5 py-1"
          stroke={4}
          rotate="right"
        />
      </button>
    </>
  )
}

export default EnvisionAssetHandler
