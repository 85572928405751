import { fabric } from 'fabric'

import { Point, Size } from '@api/interactive-plan'

import { CustomObjectTypes, MarkerType, TransparentFullFill } from './constants'
import {
  getPercentageValue,
  getRelativePostition,
  getScaledAttribute,
} from './utils/utils'

export interface OblongParams {
  size: Size
  bgSize: Size
  coordinates: Point
  groupId: string
  oblCoordinates?: Point
  oblRadius?: Point
  oblSize?: Size
  type?: string
  noArrow?: boolean
  label?: string
  subLabel?: string
  font?: string
  brandColour?: string
  color?: string
  markerColor?: string
}

declare global {
  interface Window {
    chevronSvg: fabric.Object
    arrowSvg: fabric.Object
  }
}

const Oblong = (): {
  createOblong: (arg: OblongParams) => fabric.Group
} => {
  const GroupBase = {
    originX: 'center',
    originY: 'center',
    hasBorders: false,
    hasControls: false,
    objectCaching: false,
  }

  const TextObjectBase = {
    fontFamily: 'Helvetica Neue',
    objectCaching: false,
    originX: 'center',
    originY: 'center',
  }

  const OblongBase = {
    fill: '#cccccc',
    width: 200,
    height: 5,
    objectCaching: false,
    stroke: 'none',
    strokeWidth: 0,
    originX: 'center',
    originY: 'center',
  }

  const CircleBase = {
    radius: 18,
    fill: '#cccccc',
    scaleX: 1,
    scaleY: 1,
    objectCaching: false,
    transparentCorners: false,
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
  }

  const setLabel = ({
    label,
    maxTextHeight,
    left,
    originY,
    font,
    name,
    position,
    fontWeight,
  }: {
    label: string
    name: string
    maxTextWidth: number
    maxTextHeight: number
    left: number
    originY?: 'center' | 'top' | 'bottom'
    font: string
    position: 'top' | 'bottom' | ''
    fontWeight?: string
  }) => {
    const textObject = new fabric.Text(label.toString(), {
      ...TextObjectBase,
      fontWeight: fontWeight || '600',
      name,
    })

    if (font) {
      textObject.fontFamily = font
    }

    textObject.scaleToHeight(maxTextHeight)

    textObject.fill = '#000000'
    textObject.left = -Math.abs(getPercentageValue(left, 50))

    if (position === 'top') {
      textObject.top = -Math.abs(
        getPercentageValue(textObject.getScaledHeight(), 30)
      )
    }

    if (position === 'bottom') {
      textObject.top = 0
    }

    if (originY) {
      textObject.originY = originY
      textObject.top = getPercentageValue(textObject.getScaledHeight(), 30)
    }

    return textObject
  }

  const setColourFill = ({
    brandColour,
    type,
  }: {
    brandColour: string
    type: string
  }) => {
    if (MarkerType.arrowSmall === type) {
      return TransparentFullFill
    }
    return brandColour || '#cccccc'
  }

  const setChevron = ({ scaledHeight }: { scaledHeight: number }) => {
    const chevron = fabric.util.object.clone(window.chevronSvg)

    if (Object.keys(chevron).length === 0) {
      return {}
    }

    chevron.scaleToHeight(getPercentageValue(scaledHeight, 40))
    chevron.set({
      stroke: '#ffffff',
      top: -Math.abs(chevron.getScaledHeight() / 2),
      left: -Math.abs(chevron.getScaledWidth() / 2),
      objectCaching: false,
    })

    return chevron
  }

  const setArrow = ({ scaledHeight }: { scaledHeight: number }) => {
    const arrow = fabric.util.object.clone(window.arrowSvg)
    if (Object.keys(arrow).length === 0) {
      return {}
    }
    arrow.scaleToHeight(getPercentageValue(scaledHeight, 40))
    arrow.set({
      top: -Math.abs(arrow.getScaledHeight() / 2),
      left: -Math.abs(arrow.getScaledWidth()),
      objectCaching: false,
    })
    return arrow
  }

  const createStatusCircle = ({
    color,
    containerWidth,
    containerHeight,
  }: {
    containerWidth: number
    containerHeight: number
    color: string
  }) => {
    const circle = new fabric.Circle({
      ...CircleBase,
      fill: color,
    })
    circle.scaleToHeight(getPercentageValue(containerHeight, 47))
    circle.left = -Math.abs(
      getPercentageValue(containerWidth, 50) - circle.getScaledWidth()
    )
    return circle
  }

  const createArrowCircle = ({
    containerWidth,
    containerHeight,
    brandColour,
    type,
  }: {
    containerWidth: number
    containerHeight: number
    brandColour: string
    type: string
  }) => {
    const circle = new fabric.Circle({
      ...CircleBase,
      fill: setColourFill({ brandColour, type }),
    })

    circle.scaleToHeight(
      containerHeight - getPercentageValue(containerHeight, 25)
    )
    const arrowCircleGroup = new fabric.Group([circle], {
      ...GroupBase,
    })

    arrowCircleGroup.add(setChevron({ scaledHeight: circle.getScaledHeight() }))
    arrowCircleGroup.set({
      originX: 'left',
      left: Math.abs(
        getPercentageValue(containerWidth, 50) -
          circle.getScaledWidth() -
          getPercentageValue(circle.getScaledWidth(), 20)
      ),
    })

    return arrowCircleGroup
  }

  const createArrowPoint = ({
    containerWidth,
    containerHeight,
    noArrow = false,
  }: {
    containerWidth: number
    containerHeight: number
    noArrow?: boolean
  }) => {
    const circle = new fabric.Circle({
      ...CircleBase,
      fill: TransparentFullFill,
    })

    circle.scaleToHeight(
      containerHeight - getPercentageValue(containerHeight, 25)
    )
    const arrowCircleGroup = new fabric.Group([circle], {
      ...GroupBase,
    })

    if (!noArrow) {
      arrowCircleGroup.add(setArrow({ scaledHeight: circle.getScaledHeight() }))
    }

    const circleScaledWidth = noArrow ? 0 : circle.getScaledWidth()

    arrowCircleGroup.set({
      originX: 'left',
      left: Math.abs(
        getPercentageValue(containerWidth, 50) - circleScaledWidth
      ),
    })

    return arrowCircleGroup
  }

  const setOblong = ({ bgSize, oblSize, oblRadius, color }: OblongParams) =>
    new fabric.Rect({
      ...OblongBase,
      width: getScaledAttribute(oblSize?.width || 0, bgSize.width),
      height: getScaledAttribute(oblSize?.height || 0, bgSize.height),
      rx: getScaledAttribute(oblRadius?.x || 0, bgSize.width),
      ry: getScaledAttribute(oblRadius?.y || 0, bgSize.height),
      fill: color || '#ffffff',
    })

  const createArrowSmall = (oblParams: OblongParams) => {
    const mainOblong = setOblong(oblParams)

    mainOblong.set({
      shadow: new fabric.Shadow({
        color: '#000000',
        blur: 6,
        offsetX: 1,
        offsetY: 1,
      }),
    })

    const arrowCircle = createArrowPoint({
      containerWidth: mainOblong.getScaledWidth(),
      containerHeight: mainOblong.getScaledHeight(),
      noArrow: oblParams.noArrow,
    })

    const oblongGroup = new fabric.Group([mainOblong, arrowCircle], {
      ...oblParams,
      name: CustomObjectTypes.oblongGroup,
      left: oblParams.coordinates
        ? getRelativePostition(
            oblParams.coordinates?.x || 0,
            oblParams.oblCoordinates?.x || 0,
            oblParams.size.width,
            oblParams.bgSize.width
          )
        : 0,
      top: oblParams.coordinates
        ? getRelativePostition(
            oblParams.coordinates?.y || 0,
            oblParams.oblCoordinates?.y || 0,
            oblParams.size.height,
            oblParams.bgSize.height
          )
        : 0,
    })

    const labelText = setLabel({
      label: oblParams?.label || oblParams?.groupId,
      maxTextWidth:
        mainOblong.getScaledWidth() -
        arrowCircle.getScaledWidth() -
        getPercentageValue(mainOblong.getScaledWidth(), 20),
      maxTextHeight: getPercentageValue(mainOblong.getScaledHeight(), 65),
      font: oblParams?.font || '',
      name: CustomObjectTypes.oblongLabel,
      position: oblParams?.subLabel ? 'top' : '',
      fontWeight: '800',
      left: 0,
    })

    oblongGroup.add(labelText)

    oblongGroup.add(
      createStatusCircle({
        containerWidth: mainOblong.getScaledWidth(),
        containerHeight: mainOblong.getScaledHeight(),
        color: oblParams.markerColor || '',
      })
    )

    return oblongGroup
  }

  const createArrow = (oblParams: OblongParams) => {
    const mainOblong = setOblong(oblParams)

    const arrowCircle = createArrowCircle({
      containerWidth: mainOblong.getScaledWidth(),
      containerHeight: mainOblong.getScaledHeight(),
      brandColour: oblParams.brandColour || '',
      type: oblParams.type || '',
    })

    const oblongGroup = new fabric.Group([mainOblong, arrowCircle], {
      ...oblParams,
      name: CustomObjectTypes.oblongGroup,
      left: oblParams.coordinates
        ? getRelativePostition(
            oblParams.coordinates?.x || 0,
            oblParams.oblCoordinates?.x || 0,
            oblParams.size.width,
            oblParams.bgSize.width
          )
        : 0,
      top: oblParams.coordinates
        ? getRelativePostition(
            oblParams.coordinates?.y || 0,
            oblParams.oblCoordinates?.y || 0,
            oblParams.size.height,
            oblParams.bgSize.height
          )
        : 0,
    })

    const labelText = setLabel({
      label: oblParams?.label || oblParams?.groupId,
      maxTextWidth:
        mainOblong.getScaledWidth() -
        arrowCircle.getScaledWidth() -
        getPercentageValue(mainOblong.getScaledWidth(), 20),
      maxTextHeight: oblParams?.subLabel
        ? getPercentageValue(arrowCircle.getScaledHeight(), 50)
        : getPercentageValue(arrowCircle.getScaledHeight(), 70),
      font: oblParams?.font || '',
      name: CustomObjectTypes.oblongLabel,
      position: oblParams?.subLabel ? 'top' : '',
      left: 0,
      fontWeight: oblParams?.subLabel ? '700' : '1000',
    })

    if (oblParams?.subLabel) {
      labelText.set({
        originX: 'left',
        left: -Math.abs(
          getPercentageValue(mainOblong.getScaledWidth(), 50) -
            getPercentageValue(arrowCircle.getScaledHeight(), 60)
        ),
      })
    }

    if (!oblParams?.subLabel) {
      labelText.set({
        originX: 'right',
        left: getPercentageValue(labelText.getScaledWidth(), 40),
      })
    }

    oblongGroup.add(labelText)

    if (oblParams?.subLabel) {
      const subLabel = setLabel({
        label: oblParams?.subLabel,
        maxTextWidth:
          mainOblong.getScaledWidth() -
          arrowCircle.getScaledWidth() -
          getPercentageValue(mainOblong.getScaledWidth(), 20),
        maxTextHeight: getPercentageValue(labelText.getScaledHeight(), 75),
        originY: 'top',
        font: oblParams?.font || '',
        name: CustomObjectTypes.oblongSubLabel,
        position: 'bottom',
        fontWeight: '100',
        left: 0,
      })

      subLabel.set({
        originX: 'left',
        left: labelText.left || 0,
      })

      oblongGroup.add(subLabel)
    }

    return oblongGroup
  }

  const createOblong = (oblParams: OblongParams) => {
    if (oblParams.type === MarkerType.arrowSmall) {
      return createArrowSmall(oblParams)
    }

    return createArrow(oblParams)
  }

  return {
    createOblong,
  }
}

export default Oblong
