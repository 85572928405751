import React from 'react'

import Skeleton from '@components/skeleton'

import { ArrowSvg, FilterSvg } from '@svg/react'

import SidePanelSkeleton from './side-panel-skeleton'

const BuildingSkeleton = () => (
  <div>
    <Skeleton>
      <div className="absolute left-0 top-0 z-2 h-screen w-[297px] items-center gap-4 bg-[#EEEEEE]">
        <div className="flex justify-between bg-white p-4">
          <button
            className="rounded-full border border-zinc-400 p-1"
            type="button"
          >
            <ArrowSvg className="h-6 w-6 stroke-zinc-400" rotate="left" />
          </button>

          <button
            type="button"
            className="relative rounded bg-mainColour px-3 py-1 text-white"
          >
            <div className="inline-flex items-center">
              <span className="text-xs font-bold tracking-widest">
                Add Filter
              </span>
              <FilterSvg className="ml-1 h-4 w-4" />
            </div>
            <span className="absolute -right-2 -top-2 h-5 w-5 rounded-full border-2 border-mainColour bg-white text-xs font-bold text-mainColour">
              0
            </span>
          </button>
        </div>
        <SidePanelSkeleton />
      </div>
    </Skeleton>
  </div>
)

export default BuildingSkeleton
