import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { BrochureData } from '@api/brochure'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<BrochureData>

if (isOffline()) {
  const brochureData = localStorage.getItem('brochureApiData')
  if (brochureData) {
    initialState = JSON.parse(brochureData)
  }
}

const brochureSlice = createSlice({
  name: 'brochure',
  initialState,
  reducers: {
    setBrochure: (
      state: Array<BrochureData>,
      action: PayloadAction<Array<BrochureData>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'brochureApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeBrochure: () => {
      localStorage.removeItem('brochureApiData')
      return []
    },
  },
})

export const { setBrochure, removeBrochure } = brochureSlice.actions

export const selectBrochure = (state: RootStateTypeExtra) => state.brochure

export default brochureSlice.reducer
