import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

type PanoramicType = {
  projectName: string
}

export const panoramicApi = createApi({
  reducerPath: 'panoramicApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getPanoramic: builder.query<
      ApiResponse<Array<PannellumDataInterface>>,
      PanoramicType
    >({
      query: (params: PanoramicType) => ({
        url: '/v2/panoramas',
        method: 'get',
        params: {
          projectId: params.projectName,
          pagination: false,
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  panoramic: Array<PannellumDataInterface>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  panoramic: data?.data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetPanoramicQuery } = panoramicApi
