import React from 'react'
import { useDispatch } from 'react-redux'

import { addShortlist, filterShortlist } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  ShortlistPropertyType,
} from '@store/types'

import { CheckSvg, StarSvg } from '@components/adgroup-svg/react'

import { LotInterface, LotStatusType } from '@api/types/house-and-land-type'

import { notifyError, notifySuccess } from '@adUtilities/notifier'

interface ShortlistActionProps {
  actionType: 'icon' | 'button'
  lot: LotInterface
  stageId: string
  precinctId: string
  isShortlisted: boolean
  totalShortlist: number
  packageConfiguration: PackageConfigurationInterface
}

const MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST =
  Number(process.env.REACT_APP_MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) || 4

const ShortlistAction = ({
  actionType,
  lot,
  stageId,
  precinctId,
  isShortlisted,
  totalShortlist,
  packageConfiguration,
}: ShortlistActionProps) => {
  const dispatch = useDispatch()
  const handleShortlist: React.MouseEventHandler = React.useCallback(
    (e) => {
      e.stopPropagation()

      if (lot.status !== LotStatusType.Available) {
        notifyError(
          'This lot is not available, you can only shortlist available lot.'
        )
        return
      }

      if (isShortlisted) {
        dispatch(
          filterShortlist({
            propertyId: lot.id,
            type: ShortlistPropertyType.Lot,
          })
        )
        return
      }

      if (totalShortlist >= MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) {
        notifyError(
          `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} lots.`
        )
        return
      }

      dispatch(
        addShortlist({
          propertyId: lot.id,
          propertyName: lot.name,
          references: {
            precinctId,
            stageId,
          },
          configuration: packageConfiguration,
          type: ShortlistPropertyType.Lot,
        } as LotShortlistInterface)
      )
      notifySuccess('Your selections have been added to your shortlist.')
    },
    [
      totalShortlist,
      lot,
      stageId,
      precinctId,
      isShortlisted,
      packageConfiguration,
    ]
  )

  return actionType === 'icon' ? (
    <button type="button" title="Shortlist lot" onClick={handleShortlist}>
      <StarSvg
        className={`h-4 w-4 ${
          isShortlisted
            ? 'stroke-amber-500 text-amber-500'
            : 'stroke-zinc-400 text-zinc-400'
        }`}
      />
    </button>
  ) : (
    <div
      className={`flex cursor-pointer items-center justify-center gap-2 rounded-[7px] border border-mainColour px-2 py-4 text-mainColour ${
        isShortlisted ? 'bg-mainColour' : ''
      }`}
      role="none"
      onClick={handleShortlist}
    >
      {isShortlisted ? (
        <CheckSvg className="h-[16px] w-[16px] text-white" />
      ) : (
        <StarSvg className="h-[16px] w-[16px] stroke-zinc-400 text-zinc-400" />
      )}
      <span
        className={`text-[19px] font-medium leading-[19px] ${
          isShortlisted ? 'text-white' : 'text-mainColour'
        }`}
      >
        {isShortlisted ? 'Added to Shortlist' : 'Save to Shortlist'}
      </span>
    </div>
  )
}

export default ShortlistAction
