import { Level, Unit } from '@api/building'
import { EnvisionVRLevelsByBlockInterface } from '@api/config'

const getInactiveLevelAndBlocks = (
  levels: Array<Level>,
  envisionVRLevelsByBlock: EnvisionVRLevelsByBlockInterface
) => {
  const filteredLevels: Record<string, string[]> = {}
  const levelByBlock: Record<string, string[]> = {}
  let inactiveLevels: Array<{ building: string; level: string }> = []

  levels.forEach((level: Level) => {
    const levelNumber = level.level
    const blockIds = level.data.map((item: Unit) => item.blockId)

    if (!filteredLevels[levelNumber]) {
      filteredLevels[levelNumber] = ['']
    }

    blockIds.forEach((blockId) => {
      if (blockId && !filteredLevels[levelNumber].includes(blockId)) {
        filteredLevels[levelNumber].push(blockId)
      }
    })
  })

  Object.keys(filteredLevels).forEach((level) => {
    const blockIds = filteredLevels[level]
    blockIds.forEach((blockId) => {
      if (!levelByBlock[blockId]) {
        levelByBlock[blockId] = []
      }
      levelByBlock[blockId].push(level)
    })
  })

  Object.keys(envisionVRLevelsByBlock).forEach((key) => {
    if (!levelByBlock[key]) {
      const missingLevels = envisionVRLevelsByBlock[key].map(
        (level: string) => ({
          building: key,
          level,
        })
      )
      inactiveLevels = inactiveLevels.concat(missingLevels)
    } else {
      const missingLevels = envisionVRLevelsByBlock[key].filter(
        (value: string) => !levelByBlock[key].includes(value)
      )
      if (missingLevels.length > 0) {
        const allLevels = missingLevels.map((level: string) => ({
          building: key,
          level,
        }))
        inactiveLevels = inactiveLevels.concat(allLevels)
      }
    }
  })

  return inactiveLevels
}

export default getInactiveLevelAndBlocks
