import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setFilter } from '@store/actionSlices/unitFilter'
import {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { DataNotFound } from '@components/data-handler/errors'

import {
  Level,
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import { SnaploaderConfigurationInterface } from '@api/config'

import useScript from '@utilities/adgroup-utilities/hooks/script'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import {
  addHighlight,
  findNodeByName,
  getCameraOrientation,
  handleLoadingState,
  removeHighlight,
} from './snaploader-util'

interface SnapDataInterface {
  scene: any
  virtualTourSteps: Array<any>
  originalInteractiveNodes: Array<any>
}

interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  snaploaderConfiguration: SnaploaderConfigurationInterface
  building: BuildingInterface
  showPrice: boolean
  unitFilter: UnitFilterInterface
}

const SnaploaderView = ({
  session,
  projectIdentity,
  snaploaderConfiguration,
  building,
  showPrice,
  unitFilter,
}: PagePropsInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const dispatch = useDispatch()
  const history = useHistory()

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [isSessionConnected, setIsSessionConnected] = React.useState(false)
  const [activeBlock, setActiveBlock] = React.useState('')
  const [activeView, setActiveView] = React.useState('')
  const [snaploaderSDKLoadError, setSnaploaderSDKLoadError] =
    React.useState(false)
  const [viewer, setViewer] = React.useState<any>(null)
  const [snapData, setSnapData] = React.useState<SnapDataInterface>()
  const [isSnapDataLoaded, setIsSnapDataLoaded] = React.useState(false)

  const [currentHighlightedNodes, setCurrentHighlightedNodes] = React.useState<
    Array<any>
  >([])

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  useScript('https://web.snaploader.com/3d/v1/js/snaploader-v3d.js', {
    onLoadCallback: () => {
      if (containerRef.current && !viewer) {
        const { sceneSnapId, sceneModelId } = snaploaderConfiguration
        setViewer(
          new window.snaploader.Viewer3d(containerRef.current, {
            sceneSnapId,
            sceneModelId,
          })
        )
      }
    },
    onErrorCallback: (err: any) => {
      setSnaploaderSDKLoadError(true)
      console.error('Snaploader SDK failed to load!', err)
    },
  })

  const prepareSnapDataOnScreenLoad = () => {
    let originalInteractiveNodes: any = {}

    if (viewer.pluginOptions?.developerStack?.interactiveNodes) {
      originalInteractiveNodes = JSON.parse(
        JSON.stringify(viewer.pluginOptions?.developerStack?.interactiveNodes)
      )
    }

    let virtualTourSteps: any = {}

    if (viewer.pluginOptions?.virtualTour?.steps) {
      virtualTourSteps = JSON.parse(
        JSON.stringify(viewer.pluginOptions.virtualTour.steps)
      )
    }

    setIsSnapDataLoaded(true)

    setSnapData({
      scene: viewer.scene,
      virtualTourSteps,
      originalInteractiveNodes,
    })
  }

  const getLevelList = () => {
    if (!snapData?.originalInteractiveNodes) return []

    const levelList: Array<{ key: any; value: any }> = []
    Object.keys(snapData.originalInteractiveNodes).forEach((x: any) => {
      const itNode = snapData.originalInteractiveNodes[x]
      levelList.push({
        key: x,
        value: itNode.mouseOverText ? itNode.mouseOverText : x,
      })
    })
    return levelList
  }

  const getViewList = () => {
    if (!snapData?.virtualTourSteps) return []

    const viewList: Array<string> = []
    Object.keys(snapData.virtualTourSteps).forEach((x: any) => {
      const itNode = snapData.virtualTourSteps[x]
      viewList.push(itNode.title ? itNode.title : x)
    })
    return viewList
  }

  const getCorrectBlockValue = (
    buildingData: BuildingInterface,
    block: string,
    activeLevel: string
  ) => {
    const level = buildingData.levels.find(
      (lvl: Level) => activeLevel === lvl.level
    )
    if (!level) {
      return ''
    }
    const unit = level.data.find(
      (unt: Unit) => (unt?.blockId || '').replace(/\s/g, '') === block
    )
    if (!unit) {
      return ''
    }
    return unit.blockId || ''
  }

  const resetHighlightedNodes = () => {
    if (currentHighlightedNodes.length === 0) return

    currentHighlightedNodes.forEach((node: any) => {
      removeHighlight(node)
      viewer.invalidate()
    })
    setCurrentHighlightedNodes([])
  }

  const highlightLevels = (filteredLevelList: Array<string>) => {
    const levelList = getLevelList()

    filteredLevelList.forEach((filteredLevel: string) => {
      const foundLevel = levelList.find(
        (levelObj) => levelObj.value === filteredLevel
      )

      if (!foundLevel) return

      const targetNode = findNodeByName(snapData?.scene, foundLevel.key)

      if (targetNode) {
        addHighlight(targetNode)
        setCurrentHighlightedNodes((prevState) => [...prevState, targetNode])
        viewer.invalidate()
      }
    })
  }

  const getFilteredData = (): Array<string> => {
    const filteredLevelList: Array<string> = []

    const { levels } = building

    levels.forEach((level: Level) => {
      let units: Array<Unit> = level.data

      if (activeBlock !== '') {
        units = units.filter((unit: Unit) => {
          const blockId = unit.blockId ? unit.blockId.replace(/\s/g, '') : ''
          return blockId === activeBlock
        })
      }

      units.forEach((unit: Unit) => {
        let identifier = ''

        if (unit.blockId && unit.blockId !== '') {
          identifier += `${unit.blockId.replace(/\s/g, '')}_`
        }

        identifier += level.level

        if (!filteredLevelList.includes(identifier)) {
          const response = filterUnitUtil(unit, unitFilter, showPrice)
          if (response) {
            filteredLevelList.push(identifier)
          }
        }
      })
    })

    return filteredLevelList
  }

  const autoRotateCamera = (state: boolean) => {
    if (viewer) {
      viewer.setAutoRotate(state)
    }
  }

  const setView = (value: string) => {
    const { apply } = unitFilter

    if (!apply) {
      resetHighlightedNodes()
    }

    if (!snapData?.virtualTourSteps) return

    const viewList = getViewList()
    const index = viewList.findIndex((item: string) => item === value)
    const targetStep = snapData.virtualTourSteps[index]

    if (!targetStep) return

    const cameraOrientation = getCameraOrientation(targetStep)
    viewer.setCameraOrientation(
      cameraOrientation.endPosition,
      cameraOrientation.endRotation,
      cameraOrientation.endTargetDistance
    )
  }

  const goToBuilding = (buildingData: BuildingInterface, value: string) => {
    const [block, level] = value.split('_')

    dispatch(
      setByFlag({
        flag: 'activeBlock',
        value: getCorrectBlockValue(buildingData, block, level),
      })
    )

    dispatch(
      setByFlag({
        flag: 'activeLevel',
        value: level || '',
      })
    )

    if (level) {
      history.push('building')
      return
    }

    console.error('Incorrect building data.')
  }

  const handleOnLevelClick = React.useCallback(
    (event: MessageEvent) => {
      try {
        const { name } = JSON.parse(event.data)
        if (name) {
          goToBuilding(building, name)
        }
      } catch (error) {
        console.log('Incorrect format was given in mapping.')
      }
    },
    [building]
  )

  React.useEffect(
    () => () => {
      if (viewer) {
        viewer.removeListener('sceneLoaded', prepareSnapDataOnScreenLoad)
      }

      if (session) {
        firebaseControlQuery.update({
          [`snaploader.isLoaded`]: false,
          [`snaploader.data.views`]: [],
          [`snaploader.data.levels`]: [],
        })
      }

      window.parent.removeEventListener('message', handleOnLevelClick)
    },
    []
  )

  React.useEffect(() => {
    const { building: buildingData, blockOrders: blockOrdersData } =
      buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          blockOrders: blockOrdersData,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
  }, [buildingPayload])

  React.useEffect(() => {
    if (viewer) {
      viewer.addListener('sceneLoaded', prepareSnapDataOnScreenLoad)
      viewer.setControlsVisible(false)

      window.parent.addEventListener('message', handleOnLevelClick)
    }
  }, [viewer, building])

  React.useEffect(() => {
    if (session) {
      firebaseControlQuery.update({
        [`snaploader.isLoaded`]: isSnapDataLoaded,
        [`snaploader.data.views`]: getViewList(),
        [`snaploader.data.levels`]: getLevelList(),
      })
    }
  }, [snapData])

  React.useEffect(() => {
    const { levels } = building
    if (levels.length < 1) return

    resetHighlightedNodes()

    const { apply } = unitFilter

    if (!apply) return

    const filteredLevelList = getFilteredData()
    highlightLevels(filteredLevelList)
  }, [unitFilter, activeBlock])

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleLoadingState(interval)
    }, 200)

    return () => {
      clearInterval(interval)
    }
  }, [])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        snaploader: {
          rotateCamera: rotateCameraFirebase,
          activeView: activeViewFirebase,
          activeBlock: activeBlockFirebase,
        },
        building: { unitFilter: unitFilterFirebase },
      } = session

      setIsSessionConnected(connected)
      setActiveBlock(activeBlockFirebase)
      if (activeViewFirebase && activeViewFirebase !== activeView) {
        setActiveView(activeViewFirebase)
        setView(activeViewFirebase)
      }
      autoRotateCamera(rotateCameraFirebase)
      dispatch(setFilter(unitFilterFirebase))
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payload={{
          ...buildingPayload,
          data: building.levels,
          apiData: buildingPayload.building,
        }}
      >
        {snaploaderSDKLoadError ? (
          <DataNotFound message="Failed to load SnapLoader SDK" />
        ) : (
          isSnapDataLoaded &&
          !isSessionConnected && (
            <>
              {/* <div className="absolute left-5 top-5 z-snaploader">
                <IdleTimeHandler>
                  <div className="flex items-center gap-5">
                    {!hideFilter && (
                    <button
                      onClick={() => toggleFilter(!isFilterOpen)}
                      type="button"
                    >
                      <MenuToggleSvg size="m" className="drop-shadow-70" />
                    </button>
                  )}
                  </div>
                </IdleTimeHandler>
              </div>
              <FilterPopup
                isOpen={isFilterOpen}
                toggle={toggleFilter}
                zIndex="z-snaploader"
              /> */}
            </>
          )
        )}
        <div ref={containerRef}></div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity,
    projectConfig: { snaploaderConfiguration, hideFilter, showPrice },
    building,
    unitFilter,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    snaploaderConfiguration,
    building,
    hideFilter,
    showPrice,
    unitFilter,
  })
)(SnaploaderView)
