import React from 'react'

import { GenericModalCard as ModalCard } from '@components/modals'

import useMatrixCacheCleaner from '@utilities/matrix-cache-cleaner'

import { ChevronSvg } from '@svg/react'

interface MatrixCacheHandlerProps {
  projectName: string
  email: string
}

const MatrixCacheHandler = ({
  projectName,
  email,
}: MatrixCacheHandlerProps) => {
  const [toggled, setToggleModal] = React.useState(false)
  const [, handleMatrixCacheCleanerTrigger] = useMatrixCacheCleaner({
    projectName,
    email,
  })

  return (
    <>
      <ModalCard
        headerLabel="Matrix Data Cache"
        toggle={(status: boolean) => setToggleModal(status)}
        okAction={() => handleMatrixCacheCleanerTrigger(true)}
        isVisible={toggled}
        okLabel="Clear Cache"
      >
        <div className="px-4 text-left">
          <p>Click below to clear the matrix data for the cache.</p>
        </div>
      </ModalCard>
      <button
        onClick={() => setToggleModal(!toggled)}
        type="button"
        className="flex w-full items-center rounded border-2 border-gray-300 p-3 px-5 text-left"
      >
        <span className="text-sm">Clean matrix data cache</span>
        <ChevronSvg
          className="ml-auto h-5 w-5 py-1"
          stroke={4}
          rotate="right"
        />
      </button>
    </>
  )
}

export default MatrixCacheHandler
