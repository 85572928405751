import React from 'react'

import { SvgProps } from './types'

const BathSvg = ({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      ...styles,
    }}
    width={width || '48'}
    height={height || '48'}
    fill="none"
    stroke={strokeColor || 'currentColor'}
    viewBox="0 0 18 17"
    strokeWidth={stroke || 1.4}
  >
    <path d="M15.625 9.20833V11.3333C15.625 12.0848 15.3265 12.8054 14.7951 13.3368C14.2638 13.8682 13.5431 14.1667 12.7917 14.1667H5.70833C4.95689 14.1667 4.23622 13.8682 3.70486 13.3368C3.17351 12.8054 2.875 12.0848 2.875 11.3333V9.63333C2.875 9.52062 2.91978 9.41252 2.99948 9.33281C3.07918 9.25311 3.18728 9.20833 3.3 9.20833H15.625ZM15.625 9.20833V4.95833C15.625 4.20689 15.3265 3.48622 14.7951 2.95486C14.2638 2.42351 13.5431 2.125 12.7917 2.125H9.25M12.0833 14.1667L12.7917 15.5833M6.41667 14.1667L5.70833 15.5833" />
    <path d="M11.6585 5.66667H6.84186C6.6074 5.66667 6.4197 5.47683 6.4452 5.24308C6.54861 4.30313 7.02815 2.125 9.2502 2.125C11.4722 2.125 11.9518 4.30313 12.0552 5.24308C12.0807 5.47683 11.893 5.66667 11.6585 5.66667Z" />
  </svg>
)

export default BathSvg
