import React from 'react'

interface FadeContainerProps {
  show: boolean
  children: React.ReactNode
  className?: string
}

const FadeContainer = ({ show, children, className }: FadeContainerProps) => (
  <div
    className={`transition-opacity ${
      show ? 'opacity-100' : 'pointer-events-none opacity-0'
    } ${className}`}
  >
    {children}
  </div>
)

export default FadeContainer
