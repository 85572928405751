import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { ExternalViewsData } from '@api/external-views'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<ExternalViewsData>

const externalViewsData = localStorage.getItem('externalViewsApiData')

if (isOffline()) {
  if (externalViewsData) {
    initialState = JSON.parse(externalViewsData)
  }
}

const externalViewsSlice = createSlice({
  name: 'externalViews',
  initialState,
  reducers: {
    setExternalViews: (
      state: Array<ExternalViewsData>,
      action: PayloadAction<Array<ExternalViewsData>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'externalViewsData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeExternalViews: () => {
      localStorage.removeItem('externalViewsData')
      return []
    },
  },
})

export const { setExternalViews, removeExternalViews } =
  externalViewsSlice.actions

export const selectExternalViews = (
  state: RootStateTypeExtra
): ExternalViewsData[] => state.externalViews

export default externalViewsSlice.reducer
