import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LotFilterInterface, RootStateTypeExtra } from '../types'

export interface LotConfigInterface {
  [key: string]: Array<number | string>
}

const lotFilter = localStorage.getItem('lotFilter')

export const initialFilter: LotFilterInterface = {
  configurations: {
    bed: {
      min: 'Any',
      max: 'Any',
    },
    study: {
      min: 'Any',
      max: 'Any',
    },
    bath: {
      min: 'Any',
      max: 'Any',
    },
    powderRoom: {
      min: 'Any',
      max: 'Any',
    },
    car: {
      min: 'Any',
      max: 'Any',
    },
  },
  builder: [],
  storey: [],
  lotConfiguration: {
    frontage: {
      min: 'Any',
      max: 'Any',
    },
    area: {
      min: 'Any',
      max: 'Any',
    },
    aspects: [],
    price: {
      min: 'Any',
      max: 'Any',
    },
  },
  showOnlyAvailable: false,
  anyStage: true,
  apply: false,
}

let initialState = { ...initialFilter }

const prepareData = (lotFilterData: LotFilterInterface): LotFilterInterface => {
  let isValidData = true
  Object.entries(lotFilterData.configurations).forEach(([, value]) => {
    if (typeof value !== 'object') {
      isValidData = false
    }
  })
  return isValidData ? lotFilterData : initialState
}

if (lotFilter) {
  initialState = prepareData(JSON.parse(lotFilter))
}

export const lotFilterSlice = createSlice({
  name: 'lotFilter',
  initialState,
  reducers: {
    setFilter: (
      state: LotFilterInterface,
      action: PayloadAction<LotFilterInterface>
    ) => {
      localStorage.setItem(
        'lotFilter',
        JSON.stringify({ ...state, ...action.payload })
      )
      return {
        ...state,
        ...action.payload,
      }
    },
    resetFilter: () => {
      localStorage.setItem('lotFilter', JSON.stringify(initialFilter))
      return initialFilter
    },
    removeFilter: () => {
      localStorage.removeItem('lotFilter')
      return initialFilter
    },
  },
})

export const { setFilter, resetFilter, removeFilter } = lotFilterSlice.actions

export const selectFilteredUnit = (state: RootStateTypeExtra) => state.lotFilter

export default lotFilterSlice.reducer
