import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<PannellumDataInterface>

if (isOffline()) {
  const panoramicData = localStorage.getItem('panoramicApiData')
  if (panoramicData) {
    initialState = JSON.parse(panoramicData)
  }
}

const panoramicSlice = createSlice({
  name: 'panoramic',
  initialState,
  reducers: {
    setPanoramicData: (
      state: Array<PannellumDataInterface>,
      action: PayloadAction<Array<PannellumDataInterface>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'panoramicApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removePanoramicData: () => {
      localStorage.removeItem('panoramicApiData')
      return []
    },
  },
})

export const { setPanoramicData, removePanoramicData } = panoramicSlice.actions

export const selectPanoramicData = (state: RootStateTypeExtra) =>
  state.panoramic

export default panoramicSlice.reducer
