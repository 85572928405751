import React from 'react'

export type Path = 'area-view' | 'level-view' | 'building'
export type Params = {
  block?: string
  level?: string
  unit?: string
}

const useParseMapActionRoute = () => {
  const checkValidity = React.useCallback(
    (path: Path | undefined, params: Params) => {
      const isValidPath =
        path === 'area-view' || path === 'level-view' || path === 'building'
      if (!isValidPath) {
        return false
      }
      if (path === 'building') {
        if (!params.level) {
          return false
        }
      }
      return true
    },
    []
  )

  const parseActionRoute = React.useCallback((actionRoute?: string) => {
    let path: Path | undefined
    const params: Params = {}
    actionRoute?.split('&').forEach((i) => {
      const [index, value] = i.split('=')
      if (index === 'page') {
        path = value as Path
      } else {
        params[index as keyof typeof params] = value
      }
    })
    const urlParams = new URLSearchParams(params).toString()

    return {
      path,
      fullPath: urlParams ? `${path}?${urlParams}` : path,
      params,
      isValid: checkValidity(path, params),
    }
  }, [])

  return [parseActionRoute]
}

export default useParseMapActionRoute
