import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import {
  InteractivePlanData,
  MapContent,
  MappingCollection,
  MappingPrecinctCollection,
  MappingStageCollection,
} from '@api/interactive-plan'

import isOffline from '@utilities/offline-mode-util'

const LOCALSTORAGE_KEY = 'interactivePlanApiData'

let initialState = {
  areaView: {
    image: '',
    polygons: [],
  },
  blocks: {},
  floorplan: {},
  precinct: {},
  stages: {},
} as InteractivePlanData

if (isOffline()) {
  const interactivePlanData = localStorage.getItem(LOCALSTORAGE_KEY)
  if (interactivePlanData) {
    initialState = JSON.parse(interactivePlanData)
  }
}

const interactivePlanSlice = createSlice({
  name: 'interactivePlan',
  initialState,
  reducers: {
    setInteractivePlan: (
      state: InteractivePlanData,
      action: PayloadAction<InteractivePlanData>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          LOCALSTORAGE_KEY,
          JSON.stringify({
            ...state,
            ...action.payload,
          })
        )
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    setAreaView: (
      state: InteractivePlanData,
      action: PayloadAction<MapContent>
    ) => {
      const newState = { ...state, areaView: action.payload }
      if (isOffline()) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      }
      return newState
    },
    setPrecincts: (
      state: InteractivePlanData,
      action: PayloadAction<MappingPrecinctCollection>
    ) => {
      const newState = {
        ...state,
        precinct: { ...state.precinct, ...action.payload },
      }
      if (isOffline()) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      }
      return newState
    },
    setStages: (
      state: InteractivePlanData,
      action: PayloadAction<MappingStageCollection>
    ) => {
      const newState = {
        ...state,
        stages: { ...state.stages, ...action.payload },
      }
      if (isOffline()) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      }
      return newState
    },
    setFloorPlans: (
      state: InteractivePlanData,
      action: PayloadAction<MappingCollection>
    ) => {
      const newState = {
        ...state,
        floorplan: { ...state.floorplan, ...action.payload },
      }
      if (isOffline()) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      }
      return newState
    },
    removeInteractivePlan: () => {
      localStorage.removeItem(LOCALSTORAGE_KEY)
      return initialState
    },
  },
})

export const {
  setInteractivePlan,
  setAreaView,
  setPrecincts,
  setStages,
  setFloorPlans,
  removeInteractivePlan,
} = interactivePlanSlice.actions

export const selectInteractivePlan = (state: RootStateTypeExtra) =>
  state.interactivePlan

export default interactivePlanSlice.reducer
