import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  Configurations as ConfigurationInterface,
  MinMaxInterface,
  RootStateTypeExtra,
  UnitFilterInterface,
} from '../types'

export interface UnitFilterInterfaceOptional {
  range?: MinMaxInterface
  configurations?: ConfigurationInterface
  aspect?: Array<string>
  unitType?: string
  showAvailable?: boolean
  anyLevel?: boolean
  apply?: boolean
  block?: string
  level?: string
}

const unitFilter = localStorage.getItem('unitFilter')

export const initialFilter = {
  range: {
    min: 'Any',
    max: 'Any',
  },
  aspect: [],
  unitType: 'Any',
  configurations: {
    bed: {
      min: 'Any',
      max: 'Any',
    },
    study: {
      min: 'Any',
      max: 'Any',
    },
    bath: {
      min: 'Any',
      max: 'Any',
    },
    powderRoom: {
      min: 'Any',
      max: 'Any',
    },
    car: {
      min: 'Any',
      max: 'Any',
    },
  },
  showAvailable: false,
  anyLevel: true,
  apply: false,
} as UnitFilterInterface

let initialState = { ...initialFilter }

const prepareData = (
  unitFilterData: UnitFilterInterface
): UnitFilterInterface => {
  let isValidData = true
  Object.entries(unitFilterData.configurations)?.forEach(([, value]) => {
    if (typeof value !== 'object') {
      isValidData = false
    }
  })
  return isValidData ? unitFilterData : initialState
}

if (unitFilter) {
  initialState = prepareData(JSON.parse(unitFilter))
}

export const unitFilterSlice = createSlice({
  name: 'unitFilter',
  initialState,
  reducers: {
    setFilter: (
      state: UnitFilterInterface,
      action: PayloadAction<UnitFilterInterfaceOptional>
    ) => {
      localStorage.setItem(
        'unitFilter',
        JSON.stringify({ ...state, ...action.payload })
      )
      return {
        ...state,
        ...action.payload,
      }
    },
    resetFilter: () => {
      localStorage.setItem('unitFilter', JSON.stringify(initialFilter))
      return initialFilter
    },
    removeFilter: () => {
      localStorage.removeItem('unitFilter')
      return initialFilter
    },
  },
})

export const { setFilter, resetFilter, removeFilter } = unitFilterSlice.actions

export const selectFilteredUnit = (state: RootStateTypeExtra) =>
  state.unitFilter

export default unitFilterSlice.reducer
