import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

import { TokenPayloadInterface, setToken } from '@store/actionSlices/token'

const hasToken = (token: TokenPayloadInterface): boolean => {
  const {
    access_token: { token: accessToken },
    refresh_token: { token: refreshToken },
  } = token
  return accessToken !== '' && refreshToken !== ''
}
const isTokenExpired = (token: TokenPayloadInterface): boolean => {
  const {
    access_token: { expires_at },
  } = token
  const currentTime = new Date().getTime()
  const tokenExpiryTime = new Date(expires_at).getTime()
  const timeDifference = tokenExpiryTime - currentTime
  const timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24)
  return timeDifferenceInDays <= 7
}
const renewUserAccessToken = async (
  token: TokenPayloadInterface,
  api: any,
  extraOptions: any
): Promise<boolean> => {
  let status = true
  const baseUrl = process.env.REACT_APP_API_URL
  const client_id = process.env.REACT_APP_CLIENT_ID
  const client_secret = process.env.REACT_APP_CLIENT_SECRET
  const {
    refresh_token: { token: refreshToken },
    email: userEmail,
  } = token

  const headers = new Headers()
  headers.set('user', userEmail)

  const baseQuery = fetchBaseQuery({ baseUrl })
  const response = await baseQuery(
    {
      url: '/v1/user/refresh-token',
      method: 'post',
      body: {
        client_id,
        client_secret,
        refresh_token: refreshToken,
      },
      headers,
    },
    api,
    extraOptions
  )
  if (response.data) {
    const {
      data: {
        user: { _id, access_token, refresh_token, email },
      },
    } = response.data as {
      data: { user: TokenPayloadInterface }
    }
    api.dispatch(setToken({ _id, access_token, refresh_token, email }))
  } else {
    console.error('Failed to renew the user token.', response)
    status = false
  }
  return status
}
export { hasToken, isTokenExpired, renewUserAccessToken }
