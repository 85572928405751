import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface Configuration {
  bed: string | number
  study: string | number
  bath: string | number
  powderRoom: string | number
  car: string | number
}

export interface ViewLineImage {
  id: string
  src: string
  label: string
}

export interface Metas {
  internalArea: number
  externalArea: number
  totalArea: number
  aspect: string | null
  aspectViewlines: string | null
  aspectShort: string
  viewImage: Array<string> | null
  viewLineImages: Array<ViewLineImage> | null
  floorImage: string
  floorLabel?: string
  floorPdf: string
  level: string
  classification: string
  notes: string | null
  projectUrl: string
  bedConfig: string
  price: number
  pricem2: number
  status: string
  class: string
  statusName: string
  productShort: Array<string> | string
  configurations: Configuration
}

export interface Unit {
  id: string
  order: string
  aspect: string
  agentDimmed: boolean
  area: number
  name: string
  width: number
  metas: Metas
  blockId: string | null
  extras?: any
}

export interface Level {
  level: string
  data: Array<Unit>
  height: number
}

interface BlockInterface {
  label: string
  order: number
  levels: {
    [key: string]: {
      order: number
      label: string
      units: {
        [key: string]: Unit
      }
    }
  }
}

export interface BlockCollectionInterface {
  [key: string]: BlockInterface
}

interface BlocksPayloadInterface {
  building: BlockCollectionInterface
}

type BuildingType = {
  projectName: string
}

type BlockRequest = {
  projectName: string
  block: string
}

export const buildingApi = createApi({
  reducerPath: 'buildingApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBuilding: builder.query<ApiResponse<Array<Level>>, BuildingType>({
      query: (params: BuildingType) => ({
        url: `/v1/${params.projectName}/building`,
        method: 'get',
      }),
    }),
    getBlocks: builder.query<ApiResponse<BlocksPayloadInterface>, BlockRequest>(
      {
        query: (params: BlockRequest) => ({
          url: `/v2/building/${params.projectName}`,
          method: 'get',
          params: {
            block: params.block,
          },
        }),
      }
    ),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  building: Level[]
  blockOrders: string[]
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  building:
    data?.data?.lists?.toSorted(
      (itemA: Level, itemB: Level) => itemA?.height - itemB?.height
    ) || [],
  blockOrders: data?.data?.blockOrders || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const selectBlocksFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  blocks: BlockCollectionInterface
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  blocks: data?.data?.building || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetBuildingQuery, useGetBlocksQuery } = buildingApi
