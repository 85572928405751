import React from 'react'

import { ShortlistState } from '@store/actionSlices/shortlist'
import type { Channel, ProjectIdentity } from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'

interface ComponentProps {
  projectIdentity: ProjectIdentity
  channels: Array<Channel>
  activeLevel: string
  activeUnit: string
  shortlists: ShortlistState['shortlists']
}

const LightMapHandler = ({
  projectIdentity,
  channels,
  activeLevel,
  activeUnit,
  shortlists,
}: ComponentProps): void => {
  const CHANNEL_TYPE_SHORTLIST = 'shortlist'
  const CHANNEL_TYPE_UNIT = 'unit'
  const CHANNEL_TYPE_LEVEL = 'level'

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const handleActiveLevelMap = (level: string) => {
    if (channels.length === 0) {
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type !== CHANNEL_TYPE_LEVEL) {
        return {
          ...channel,
          status: false,
        }
      }

      return {
        ...channel,
        status: channel.name === level,
      }
    })

    firebaseControlQuery.updateLightChannel({
      [`channels`]: updatedChannels,
    })
  }

  const handleActiveUnitMap = (unit: string) => {
    if (channels.length === 0) {
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type !== CHANNEL_TYPE_UNIT) {
        return {
          ...channel,
          status: false,
        }
      }
      return {
        ...channel,
        status: channel.name === unit,
      }
    })

    firebaseControlQuery.updateLightChannel({
      [`channels`]: updatedChannels,
    })
  }

  const handleShortlistedUnitMap = () => {
    if (channels.length === 0) {
      return
    }

    if (
      channels.filter((res) => res.type === CHANNEL_TYPE_SHORTLIST).length === 0
    ) {
      return
    }

    if (shortlists.length === 0) {
      const updatedChannels = channels.map((channel: Channel) => {
        if (channel.type === CHANNEL_TYPE_SHORTLIST) {
          return {
            ...channel,
            status: false,
          }
        }
        return channel
      })

      firebaseControlQuery.updateLightChannel({
        [`channels`]: updatedChannels,
      })
      return
    }

    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type !== CHANNEL_TYPE_SHORTLIST) {
        return channel
      }

      const foundItem = shortlists.filter(
        (shortlistedUnit) => shortlistedUnit.propertyName === channel.name
      )

      return {
        channel,
        status: foundItem.length > 0,
      }
    })

    firebaseControlQuery.updateLightChannel({
      [`channels`]: updatedChannels,
    })
  }

  const resetChannels = () => {
    const updatedChannels = channels.map((channel: Channel) => {
      if (channel.type === CHANNEL_TYPE_SHORTLIST) {
        return channel
      }

      return {
        ...channel,
        status: false,
      }
    })

    firebaseControlQuery.updateLightChannel({
      [`channels`]: updatedChannels,
    })
  }

  React.useEffect(() => {
    if (activeUnit) {
      handleActiveUnitMap(activeUnit)
      return
    }
    handleActiveLevelMap(activeLevel)
  }, [activeLevel, activeUnit])

  React.useEffect(() => {
    handleShortlistedUnitMap()
  }, [shortlists])

  React.useEffect(
    () => () => {
      if (channels.length > 0) {
        resetChannels()
      }
    },
    []
  )
}

export default LightMapHandler
