import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface GalleryData {
  id: string
  label: string
  distance: string
  imageSource: string
  imageName: string
}

export interface LocationData {
  label: string
  Images: Array<GalleryData>
}

type LocationType = {
  projectName: string
}

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getLocation: builder.query<ApiResponse<Array<LocationData>>, LocationType>({
      query: (params: LocationType) => ({
        url: `/v1/${params.projectName}/location`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  locations: Array<LocationData>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  locations: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetLocationQuery } = locationApi
