import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'
import { hasToken } from '@utilities/helper'
import useMatrixCacheCleaner from '@utilities/matrix-cache-cleaner'

import { ArrowPathSvg } from '@svg/react'

interface ErrorHandlerProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
}

const ErrorHandler = ({
  session,
  projectIdentity,
  token,
}: ErrorHandlerProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const history = useHistory()
  const [isConnected, setIsConnected] = useState(false)

  const [isMatrixCacheCleaned, handleMatrixCacheCleanerTrigger] =
    useMatrixCacheCleaner({
      projectName: projectIdentity.projectName,
      email: token.email,
    })

  const resetClearCacheTriggerKeyFromFirebaseAndReload = async () => {
    if (session && session.connected) {
      await firebaseControlQuery.update({
        [`clearCacheTriggerKey`]: '',
        [`clearCacheKeyState`]: 'cleared',
      })
    }
    window.location.reload()
  }

  const handleRefreshClick = () => {
    handleMatrixCacheCleanerTrigger(true)
  }

  useEffect(() => {
    if (isMatrixCacheCleaned) {
      history.push(`/${projectIdentity.projectName}/vision`)

      resetClearCacheTriggerKeyFromFirebaseAndReload().catch((err) =>
        console.error(err)
      )
    }
  }, [isMatrixCacheCleaned])

  useEffect(() => {
    if (hasToken(token) || !session || !session.connected) {
      return
    }

    const { clearCacheTriggerKey: firebaseClearCacheTriggerKey } = session
    handleMatrixCacheCleanerTrigger(firebaseClearCacheTriggerKey !== '')
  }, [session?.connected, session?.clearCacheTriggerKey])

  useEffect(() => {
    if (!session) {
      return
    }

    const { connected } = session
    setIsConnected(connected)
  }, [session])

  return (
    <div className="h-screen">
      <div className="flex h-full">
        <div className="flex w-1/2 items-center justify-center bg-mainColour">
          <ArrowPathSvg className="h-32 w-32 text-white" />
        </div>
        <div className="flex w-1/2 items-center bg-white">
          <div className="mx-8">
            <h3 className="text-4xl font-bold">New updates available!</h3>
            <p className="mt-5 text-xl">
              {isConnected ? (
                <>
                  It looks like this page hasn&apos;t loaded correctly, possibly
                  due to recent updates. Please click on the Refresh button
                  within the REMOTE.
                </>
              ) : (
                <>
                  It looks like this page hasn&apos;t loaded correctly, possibly
                  due to recent updates. Please go back to the main page or use
                  the menu to explore other sections. Alternatively, click below
                  to refresh.
                </>
              )}
            </p>
            {!isConnected && (
              <button
                type="button"
                className="mt-5 rounded-md bg-mainColour px-8 py-4 text-[18px] font-semibold leading-none text-white"
                onClick={handleRefreshClick}
              >
                Refresh Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({ firestore, projectIdentity, token }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    token,
  })
)(ErrorHandler)
