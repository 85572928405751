export enum PanoramaType {
  Level = 'level',
  Block = 'block',
  Unit = 'unit',
  Precinct = 'precinct',
  Stage = 'stage',
  Lot = 'lot',
  Gallery = 'gallery',
}

export enum PanoramaImageType {
  Sphere = 'sphere',
  Rect = 'rect',
}

export interface PannellumHotspotInterface {
  id?: string
  pitch: number
  yaw: number
  type: string
  text: string
  sceneId: string
}

export interface PannellumDataInterface {
  id: string
  projectId: string
  nameMap: string
  label: string
  type: PanoramaType
  panoramaType: PanoramaImageType
  imageUrl: string
  controlUrl: string
  pitch: number
  yaw: number
  panoramaGroup: string
  default: boolean
  hotspots: Array<PannellumHotspotInterface>
}

export interface PannellumSceneInterface {
  title: string
  pitch: number
  yaw: number
  type: string
  panorama: string
  hotSpots: Array<PannellumHotspotInterface>
}

export interface PannellumViewRefInterface {
  pannellum: PannellumRefInterface
}

export interface PannellumRefInterface {
  startAutoRotate(value: number): void
  stopAutoRotate(): void
  on(type: string, listener: (e: any) => void): void
  off(type: string, listener: (e: any) => void): void
  getYaw(): number
  getPitch(): number
  getHfov(): number
  setHfov(hfov: number, duration?: number, fn?: () => void): void
  loadScene(sceneId: string, pitch?: number, yaw?: number): void
  setPitch(pitch: number): void
  setYaw(yaw: number): void
  mouseEventToCoords(e: any): Array<number>
  addHotSpot(hotSpot: PannellumHotspotInterface): void
  removeHotSpot(hotSpotId: string): void
}
