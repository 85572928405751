import React from 'react'

import { PackageConfigurationInterface } from '@store/types'

import { LotInterface, LotStatusType } from '@api/types/house-and-land-type'

import ShortlistAction from './shortlist-action'

interface LotCardHeaderProps {
  lot: LotInterface
  totalPackage: number
  stageId: string
  precinctId: string
  isShortlisted: boolean
  totalShortlist: number
  packageConfiguration: PackageConfigurationInterface
}

const LotCardHeader = ({
  lot,
  totalPackage,
  stageId,
  precinctId,
  isShortlisted,
  totalShortlist,
  packageConfiguration,
}: LotCardHeaderProps) => (
  <div className="mb-2 flex justify-between gap-1">
    <div className="flex min-w-0 items-center">
      <div className="flex-1 truncate">
        <span className="text-[18px] font-bold leading-[21.5px]">
          Lot {lot.name}
        </span>
      </div>
      <span
        className={`-mt-[2px] ml-4 h-2 w-2 rounded-full ${
          lot.status === LotStatusType.Available ? 'bg-green-500' : 'bg-red-500'
        }`}
      ></span>
      {lot.status === LotStatusType.Available ? (
        <span className="ml-1 text-xs font-semibold uppercase leading-[14px] tracking-widest text-green-500">
          {totalPackage} {totalPackage > 1 ? 'Packages' : 'Package'}
        </span>
      ) : (
        <span className="ml-1 text-xs font-semibold uppercase leading-[14px] tracking-widest text-red-500">
          Sold
        </span>
      )}
    </div>
    <ShortlistAction
      actionType="icon"
      lot={lot}
      stageId={stageId}
      precinctId={precinctId}
      isShortlisted={isShortlisted}
      totalShortlist={totalShortlist}
      packageConfiguration={packageConfiguration}
    />
  </div>
)

export default LotCardHeader
