import { fabric } from 'fabric'

import { CustomObjectTypes, MarkerType, TransparentFill } from '../constants'
import { CustomGroup, Polygon } from '../types'

const objectZoomInFade = ({
  canvas,
  groupId,
}: {
  canvas: fabric.Canvas
  groupId: string
}) => {
  if (canvas) {
    const obj = canvas.getObjects().find((canvObj) => {
      if (!canvObj.isType('group')) {
        return false
      }
      return (canvObj as CustomGroup).id === groupId
    })

    if (obj) {
      const mousePoint = obj.getCenterPoint() as fabric.Point

      const circle = (obj as CustomGroup)?._objects.find(
        (res) => res.name === CustomObjectTypes.markerGroup
      ) as fabric.Circle

      const canvasRect = new fabric.Rect({
        width: canvas.width,
        height: canvas.height,
        fill: '#000',
        opacity: 0,
        objectCaching: true,
      })

      canvas.add(canvasRect)

      canvasRect.animate('opacity', 1, {
        duration: 700,
        easing: fabric.util.ease.easeInCubic,
      })

      fabric.util.animate({
        startValue: canvas.getZoom(),
        endValue: 3,
        duration: 1000,
        easing: fabric.util.ease.easeInCubic,
        onChange: (zoomvalue) => {
          canvas.zoomToPoint(
            {
              x: mousePoint.x + (circle?.left || 0),
              y: mousePoint.y + (circle?.top || 0),
            },
            zoomvalue
          )
          canvas.renderAll.bind(canvas)
        },
        onComplete: () => {
          const customGroupObj = obj as CustomGroup
          if (customGroupObj.onGroupSelect) {
            customGroupObj.onGroupSelect()
          }
        },
      })
    }
  }
}

const animateHighlight = ({
  fabricRef,
  activeByDefault,
  polObj,
  color,
  markerType,
}: {
  color: string
  markerType: string
  fabricRef: fabric.Canvas
  activeByDefault?: boolean
  polObj: fabric.Object
}) => {
  if (markerType === MarkerType.arrow || markerType === MarkerType.arrowSmall) {
    return
  }
  polObj.animate(
    {
      fill: activeByDefault ? color : TransparentFill,
      opacity: activeByDefault ? 0.7 : 0.1,
    },
    {
      onChange: fabricRef?.renderAll.bind(fabricRef),
      duration: 300,
      easing: fabric.util.ease.easeInSine,
    }
  )
}

const fadeInOutAnimation = ({
  fabricRef,
  outerGroup,
  type,
  isHidden,
  activeByDefault,
  isInvisible,
}: {
  fabricRef: fabric.Canvas
  outerGroup: fabric.Group
  type: string
  isHidden?: boolean
  activeByDefault?: boolean
  isInvisible?: boolean
}) => {
  if (type === MarkerType.arrow && !isInvisible) {
    outerGroup.animate(
      {
        opacity: isHidden ? 0 : 1,
      },
      {
        onChange: fabricRef?.renderAll.bind(fabricRef),
        duration: 300,
        easing: fabric.util.ease.easeInSine,
      }
    )
  }
  if (type === MarkerType.arrowSmall && !isInvisible) {
    outerGroup.animate(
      {
        opacity: activeByDefault ? 0.6 : 1,
      },
      {
        onChange: fabricRef?.renderAll.bind(fabricRef),
        duration: 300,
        easing: fabric.util.ease.easeInSine,
      }
    )
  }
}

const setObjectActive = ({
  fabricRef,
  polygons,
}: {
  fabricRef: fabric.Canvas
  polygons: Array<Polygon>
}) => {
  polygons.forEach(
    ({
      isHidden,
      type: markerType,
      groupId,
      activeByDefault,
      color,
      subLabel,
      isInvisible,
    }) => {
      fabricRef.forEachObject((outerGroup) => {
        if (!outerGroup.isType('group')) {
          return
        }

        if ((outerGroup as CustomGroup)?.id === groupId) {
          fadeInOutAnimation({
            fabricRef,
            outerGroup: outerGroup as fabric.Group,
            type: markerType || '',
            isHidden,
            activeByDefault,
            isInvisible,
          })
        }

        const customOuterGroup = outerGroup as CustomGroup
        customOuterGroup?.forEachObject((obj) => {
          if (obj.name === CustomObjectTypes.oblongGroup) {
            const oblongGroup = obj as CustomGroup
            if ((outerGroup as CustomGroup)?.id === groupId) {
              oblongGroup.forEachObject((oblObj) => {
                if (oblObj.name === CustomObjectTypes.oblongSubLabel) {
                  const oblTextObj = oblObj as fabric.Text
                  oblTextObj?.set({
                    text: subLabel,
                  })
                }
              })
              fabricRef.renderAll()
            }
          }
          if (obj.name === CustomObjectTypes.polyGroup) {
            const polyGroup = obj as CustomGroup
            if ((outerGroup as CustomGroup)?.id === groupId) {
              polyGroup.forEachObject((polObj) => {
                animateHighlight({
                  fabricRef,
                  activeByDefault,
                  polObj,
                  color,
                  markerType: markerType || '',
                })
              })
            }
          }
        })
      })
    }
  )
}

export { objectZoomInFade, setObjectActive }
