import React from 'react'
import { useDispatch } from 'react-redux'

import { BuildingInterface, setBuilding } from '@store/actionSlices/building'

import { ChevronSvg } from '@svg/react'

interface ComponentProps {
  building: BuildingInterface
  level: string
  active: boolean
}

const LevelHead = ({ building, level, active }: ComponentProps) => {
  const dispatch = useDispatch()

  const handleClick = (myLevel: string) => {
    dispatch(
      setBuilding({
        ...building,
        activeLevel: myLevel,
        activeUnit: '',
      })
    )
  }

  return (
    <button
      id={`label-${level}`}
      className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#EEEEEE] py-3"
      type="button"
      onClick={() => handleClick(level)}
    >
      <span
        className={`${
          active ? 'active-level border-b-2 border-zinc-800' : ''
        } text-md font-bold tracking-widest`}
      >
        Level {level}
      </span>
      <ChevronSvg className="h-6 w-6" rotate="right" />
    </button>
  )
}

export default LevelHead
