import React from 'react'

import { OfflineSvg } from '@svg/react'

const ConnectionRefused = () => (
  <div className="background-cover flex items-center justify-center bg-mainColour">
    <div className="inline-flex items-center">
      <OfflineSvg className="h-20 w-20" />
      <h1 className="text-4xl">Showcase refused to connect.</h1>
    </div>
  </div>
)

export default ConnectionRefused
