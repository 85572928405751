import React from 'react'

import Skeleton from '@components/skeleton'

const InteractiveMapSkeleton = () => (
  <Skeleton>
    <div className="absolute bottom-8 left-8 z-20">
      <div className="h-40 w-68 rounded-lg bg-gray-100/30">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    </div>
  </Skeleton>
)

export default InteractiveMapSkeleton
