import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface TeamsInfo {
  id: string
  development: string
  name: string
  label: string
  imageSource: string
  imageName: string
  description: string
  caption?: string
  type: string
}

export interface TeamsData {
  projectId: string
  teamsInfo: Array<TeamsInfo>
}

type TeamsType = {
  projectName: string
}

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getTeams: builder.query<ApiResponse<Array<TeamsData>>, TeamsType>({
      query: (params: TeamsType) => ({
        url: `/v1/${params.projectName}/teams`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  teamsInfo: TeamsInfo[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  teamsInfo: data?.data.teamsInfo || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetTeamsQuery } = teamsApi
