import React from 'react'

import { SvgProps } from './types'

const ArrowCircleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '43'}
      height={height || '43'}
      fill={fill || 'currentColor'}
    >
      <circle cx="21.5" cy="21.5" r="21.5" />
      <path
        d="M29 21.5H14M14 21.5L21.0833 15M14 21.5L21.0833 28"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowCircleSvg
