import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra, Token } from '@store/types'

export interface TokenPayloadInterface {
  _id: string
  access_token: Token
  refresh_token: Token
  email: string
}

const tokens = localStorage.getItem('tokens')

let initialState = {
  _id: '',
  access_token: {
    token: '',
    expires_at: '',
  },
  refresh_token: {
    token: '',
    expires_at: '',
  },
  email: '',
} as TokenPayloadInterface

if (tokens) {
  initialState = JSON.parse(tokens)
}

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (
      state: TokenPayloadInterface,
      action: PayloadAction<TokenPayloadInterface>
    ) => {
      localStorage.setItem('tokens', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload,
      }
    },
    removeToken: (state: TokenPayloadInterface) => {
      localStorage.removeItem('tokens')
      return {
        ...state,
        ...{
          _id: '',
          access_token: {
            token: '',
            expires_at: '',
          },
          refresh_token: {
            token: '',
            expires_at: '',
          },
          email: '',
        },
      }
    },
  },
})

export const { setToken, removeToken } = tokenSlice.actions

export const selectToken = (state: RootStateTypeExtra) => state.token

export default tokenSlice.reducer
