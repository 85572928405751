import React from 'react'

import { SvgProps } from './types'

const AreaSvg = ({
  width,
  height,
  className,
  size,
  styles,
  stroke,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '17'}
      height={height || '18'}
      viewBox={`0 0 ${width || 17} ${height || 18}`}
      stroke={stroke ? String(stroke) : 'currentColor'}
      fill="none"
    >
      <g clipPath="url(#clip0_2675_88717)">
        <path
          d="M2.41667 11.0166V1.09998M2.41667 11.0166L1 9.24581M2.41667 11.0166L3.83333 9.24581M2.41667 1.09998L1 2.51664M2.41667 1.09998L3.83333 2.51664"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
        />
        <path
          d="M15.9167 14.6833L6 14.6833M15.9167 14.6833L14.1458 16.1M15.9167 14.6833L14.1458 13.2666M6 14.6833L7.41667 16.1M6 14.6833L7.41667 13.2666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
        />
        <path
          d="M14.3667 9.89164H7.425C7.19028 9.89164 7 9.70138 7 9.46664V2.52498C7 2.29026 7.19028 2.09998 7.425 2.09998H14.3667C14.6014 2.09998 14.7917 2.29026 14.7917 2.52498V9.46664C14.7917 9.70138 14.6014 9.89164 14.3667 9.89164Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
        />
      </g>
      <defs>
        <clipPath id="clip0_2675_88717">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 0.0999756)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AreaSvg
