import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface Layer {
  groupId: string
  label: string
  controlSrc: string
  src: string
  animation?: string
  visible: boolean
  controls: boolean
  type: string
  category: string
  baseImageOverride: string
  categoryDefault: boolean
  actionRoute: string
}

export interface Stack {
  src: string
  layers: Array<Layer>
  storyMode: boolean
}

type InteractiveMapType = {
  projectName: string
}

export const interactiveMapApi = createApi({
  reducerPath: 'interactiveMapApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getInteractiveMap: builder.query<
      ApiResponse<Array<Stack>>,
      InteractiveMapType
    >({
      query: (params: InteractiveMapType) => ({
        url: `/v1/${params.projectName}/interactive-map`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

const defaultStack = {
  src: '',
  layers: [],
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  stack: Stack
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  stack: Object.keys(data?.data || {}).length > 0 ? data?.data : defaultStack,
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetInteractiveMapQuery } = interactiveMapApi
