import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { BedConfig } from '@api/bed-config'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<BedConfig>

if (isOffline()) {
  const bedConfigData = localStorage.getItem('bedConfigApiData')
  if (bedConfigData) {
    initialState = JSON.parse(bedConfigData)
  }
}

const bedConfigSlice = createSlice({
  name: 'bedConfig',
  initialState,
  reducers: {
    setBedConfig: (
      state: Array<BedConfig>,
      action: PayloadAction<Array<BedConfig>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'bedConfigApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeBedConfig: () => {
      localStorage.removeItem('bedConfigApiData')
      return []
    },
  },
})

export const { setBedConfig, removeBedConfig } = bedConfigSlice.actions

export const selectBedConfig = (state: RootStateTypeExtra) => state.bedConfig

export default bedConfigSlice.reducer
