import { Dot } from 'pure-react-carousel'
import React from 'react'

import { generateId } from '@adUtilities/generate-id-util'

export interface SlideScrollbarProps {
  setCurrentSlide: (arg: number) => void
  currentSlide: number
  slideLength: number
}

const SlideScrollbar = ({
  currentSlide,
  setCurrentSlide,
  slideLength,
}: SlideScrollbarProps) => {
  const handleOnMoveScroller = (index: number) => {
    setCurrentSlide(index)
  }

  return (
    <div className="relative flex h-[5px] flex-nowrap rounded-md bg-white/[0.35]">
      {Array.from({ length: slideLength }).map((_, index) => (
        <Dot
          key={generateId(5)}
          className="flex-1 cursor-pointer"
          onClick={() => handleOnMoveScroller(index)}
          slide={index}
        />
      ))}
      <div
        className="absolute left-0 top-0 z-1 h-full cursor-pointer rounded-md bg-white/80 transition-transform duration-300"
        style={{
          transform: `translateX(${currentSlide * 100}%)`,
          width: `${100 / slideLength}%`,
        }}
      />
    </div>
  )
}

export default SlideScrollbar
