import React from 'react'

import { SvgProps } from './types'

const ArrowSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
  strokeColor,
  stroke,
}: SvgProps) => {
  const sizeKey = size || 's'
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const rotateDegrees = {
    left: '180deg',
    right: '0deg',
    top: '270deg',
    down: '90deg',
  }

  const scale = {
    xl: 3.0,
    lg: 1.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '58px'}
      height={height || '52px'}
      viewBox="20 5 20 42"
      fill="none"
    >
      <path
        d="M15.1675 26H44.9122M44.9122 26L30.6347 13M44.9122 26L30.6347 39"
        stroke={strokeColor}
        strokeWidth={stroke || '3.25'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowSvg
