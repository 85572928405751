import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase } from '@store/types'

import Skeleton from '../skeleton'

export interface LoadingIndicatorInterface {
  loadingIndicator: { isVisible: boolean }
}

const LoadingIndicator = ({ loadingIndicator }: LoadingIndicatorInterface) => {
  if (!loadingIndicator.isVisible) return null

  return (
    <div className="fixed inset-0 z-50 bg-gray-100 bg-opacity-50">
      <Skeleton />
    </div>
  )
}

export default connect(({ loadingIndicator }: RootStateFirebase) => ({
  loadingIndicator,
}))(LoadingIndicator)
