import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

export interface BuildingPageConfigInterface {
  unitInfoPopupOpen: boolean
  unitPanelFolded: boolean
}

let initialState = {
  unitInfoPopupOpen: false,
  unitPanelFolded: false,
} as BuildingPageConfigInterface

const buildingPageConfig = localStorage.getItem('buildingPageConfig')

if (buildingPageConfig) {
  initialState = JSON.parse(buildingPageConfig)
}

const buildingPageConfigSlice = createSlice({
  name: 'buildingPageConfig',
  initialState,
  reducers: {
    setBuildingPageConfigByKey: (
      state: BuildingPageConfigInterface,
      action: PayloadAction<{
        key: 'unitInfoPopupOpen' | 'unitPanelFolded'
        value: boolean
      }>
    ) => {
      localStorage.setItem(
        'buildingPageConfig',
        JSON.stringify({
          ...state,
          [action.payload.key]: action.payload.value,
        })
      )
      return { ...state, [action.payload.key]: action.payload.value }
    },
    resetBuildingPageConfig: () => {
      localStorage.removeItem('buildingPageConfig')
      return {
        unitInfoPopupOpen: false,
        unitPanelFolded: false,
      }
    },
  },
})

export const { setBuildingPageConfigByKey, resetBuildingPageConfig } =
  buildingPageConfigSlice.actions

export const selectProjectConfig = (state: RootStateTypeExtra) =>
  state.projectConfig

export default buildingPageConfigSlice.reducer
