import React from 'react'
import { useLocation } from 'react-router-dom'

import { ThemeConfigInterface } from '@api/config'

import { getQueryStringParams } from '@utilities/helper'

const fontWeights = {
  Light: 400,
  Regular: 500,
  Medium: 600,
  DemiBold: 700,
}

const parseJSON = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return ''
  }
}

export default function styleUtil(themeProjectConfig?: ThemeConfigInterface) {
  const location = useLocation()

  const storageKey = 'theme'
  const storageKeyObject = 'themeObject'
  const bucketUrl = process.env.REACT_APP_BUCKET

  const theme = localStorage.getItem(storageKey)
  const themeParamObject = parseJSON(
    localStorage.getItem(storageKeyObject) || '{}'
  )

  const themeConfig =
    themeProjectConfig ??
    parseJSON(localStorage.getItem('projectConfig') || '{}')?.theme

  const { themeParam, resetTheme } = getQueryStringParams(location.search)

  const parsedThemeParam = parseJSON(themeParam || '{}')

  const handleColour = (colour: string) => {
    if (!colour) {
      return ''
    }
    if (colour && colour.match(/[0-9A-Fa-f]{6}/g)) {
      return `${colour}`
    }
    return colour
  }

  const themeObject = {
    font:
      parsedThemeParam?.font ||
      themeParamObject?.font ||
      themeConfig?.font ||
      'TT Commons',
    mainColour: handleColour(
      parsedThemeParam?.mainColour ||
        themeParamObject?.mainColour ||
        themeConfig?.mainColour
    ),
    secondaryColour: handleColour(
      parsedThemeParam?.secondaryColour ||
        themeParamObject?.secondaryColour ||
        themeConfig?.secondaryColour
    ),
    tertiaryColour: handleColour(
      parsedThemeParam?.tertiaryColour ||
        themeParamObject?.tertiaryColour ||
        themeConfig?.tertiaryColour
    ),
    neutralColour: handleColour(
      parsedThemeParam?.neutralColour ||
        themeParamObject?.neutralColour ||
        themeConfig?.neutralColour
    ),
  }

  React.useEffect(() => {
    const style = document.getElementById('dynamic-theme')

    const dynamicStyle =
      (!resetTheme ? theme : undefined) ??
      `
      ${Object.entries(fontWeights)
        .map(
          ([weight, value]) => `
      @font-face {
        font-family: '${themeObject.font}';
        src: url('${bucketUrl}/font-ttf/${themeObject.font.replace(
            / /g,
            '_'
          )}_${weight}.ttf') format('truetype');
        font-weight: ${value};
        font-style: normal;
      }
      `
        )
        .join('')}

      * {
        font-family: '${themeObject.font}', sans-serif;
      }

      :root {
        --main-colour: ${themeObject.mainColour};
        --secondary-colour: ${themeObject.secondaryColour};
        --tertiary-colour: ${themeObject.tertiaryColour};
        --neutral-colour: ${themeObject.neutralColour};
      }
    `

    if (themeParam || resetTheme) {
      localStorage.setItem(storageKeyObject, JSON.stringify(themeObject || {}))
      localStorage.setItem(storageKey, dynamicStyle)
    }

    if (style) {
      style.innerHTML = dynamicStyle
    }
  }, [themeProjectConfig])

  React.useEffect(() => {
    if (resetTheme) {
      localStorage.removeItem(storageKey)
      localStorage.removeItem(storageKeyObject)
    }
  }, [])

  return themeObject
}
