import { ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel'
import React, { useContext } from 'react'

import type { PlayerControlInterface } from '@store/types'

import FadeContainer from '@components/fade-container'
import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
} from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'
import Player from '@components/player'

import { GalleryData } from '@api/floor-plate-gallery'

import AssetHandler from '@utilities/asset-handler'
import handleMouseWheel from '@utilities/gallery-image-control-util'

interface CarouselContentProps {
  slideContent: Array<GalleryData>
  isRemoteAppConnected: boolean
  getVideoPlayerState: (arg: boolean) => void
  playerControls: PlayerControlInterface | undefined
}

const StageCarouselContent = ({
  slideContent,
  isRemoteAppConnected,
  getVideoPlayerState,
  playerControls,
}: CarouselContentProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = React.useState(
    carouselContext.state.currentSlide
  )
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)
  const [toggleControls, setToggleControls] = React.useState(true)

  const handleGallerySliderClick = () => {
    setToggleControls((state) => !state)
  }

  React.useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const updatePlayerState = (arg: React.SetStateAction<boolean>) => {
    setIsVideoPlaying(arg)
    if (isRemoteAppConnected === false) {
      getVideoPlayerState(arg === true)
    }
  }

  const labelContent = [
    ...slideContent.map((item) => ({
      text: item.imageSrc ?? '',
      src: item.imageSrc ?? '',
      type: 'legacy',
      noSpliceUrl: false,
    })),
  ]

  return (
    <div
      onWheel={(e) => {
        if (!isVideoPlaying) {
          handleMouseWheel(
            e,
            'nextButton',
            'prevButton',
            currentSlide,
            slideContent.length
          )
        }
      }}
      className="h-full"
    >
      <ButtonNext id="nextButton" className="hidden">
        Next button
      </ButtonNext>
      <ButtonBack id="prevButton" className="hidden">
        Prev button
      </ButtonBack>

      {!isRemoteAppConnected && !isVideoPlaying && (
        <IdleTimeHandler className="absolute left-0 top-0 z-5">
          <FadeContainer className="relative z-2 w-full" show={toggleControls}>
            <div className="pointer-events-none fixed bottom-0 h-1/4 w-full bg-shadow-horizontal" />
            <div className="fixed bottom-5 z-10 flex pl-5">
              <SlideTrigger
                type="thumbnail"
                items={labelContent}
                setCurrentSlide={setCurrentSlide}
                currentSlide={currentSlide}
                nextButtonId="nextButton"
                previousButtonId="prevButton"
                dotContainerRef={dotContainerRef}
                toggleControls={toggleControls}
              />
            </div>
          </FadeContainer>
        </IdleTimeHandler>
      )}

      <GallerySlider onClick={handleGallerySliderClick}>
        {slideContent.map((value, index) => (
          <GallerySlide
            key={`${value.imageSrc}-gallery`}
            index={index}
            className="overflow-hidden"
          >
            <div className="relative">
              <ImageHandler
                className="background-cover image-blur absolute inset-0 z-2"
                url={value.imageSrc ?? ''}
                type="legacy"
              />

              <ImageHandler
                className="background-contain absolute inset-0 z-3"
                url={value.imageSrc ?? ''}
                type="legacy"
                bgProps={{
                  gradiant: value.type === 'image' ? 0 : 0.5,
                }}
              >
                {value?.type === 'video' && (
                  <Player
                    galleryName="floorGallery"
                    getPlayerState={updatePlayerState}
                    videoUrl={AssetHandler({
                      url: value.videoSrc ?? '',
                      type: 'legacy',
                    })}
                    isActive={currentSlide === index}
                    playerControls={playerControls}
                    onPlayerPauseOrStop={() => setToggleControls(true)}
                    timeoutInSeconds={3}
                  />
                )}
              </ImageHandler>
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default StageCarouselContent
