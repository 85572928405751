import { LotConfigInterface } from '@store/actionSlices/lotFilter'
import { LotFilterInterface } from '@store/types'

function countFilterChanges(
  oldObject: LotFilterInterface,
  newObject: LotFilterInterface
) {
  let changes = 0

  Object.keys(oldObject).forEach((key) => {
    if (
      typeof oldObject[key as keyof LotFilterInterface] === 'object' &&
      typeof newObject[key as keyof LotFilterInterface] === 'object'
    ) {
      if (key === 'configurations') {
        Object.keys(oldObject[key as keyof LotFilterInterface]).forEach(
          (configKey: keyof LotConfigInterface) => {
            const oldConfigValue = oldObject[key as keyof LotFilterInterface]
            const newConfigValue = newObject[key as keyof LotFilterInterface]
            const oldValue =
              oldConfigValue[configKey as keyof typeof oldConfigValue]
            const newValue =
              newConfigValue[configKey as keyof typeof newConfigValue]

            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
              changes += 1
            }
          }
        )
      } else if (key === 'lotConfiguration') {
        Object.keys(oldObject[key as keyof LotFilterInterface]).forEach(
          (configKey: keyof LotConfigInterface) => {
            const oldConfigValue = oldObject[key as keyof LotFilterInterface]
            const newConfigValue = newObject[key as keyof LotFilterInterface]
            const oldValue =
              oldConfigValue[configKey as keyof typeof oldConfigValue]
            const newValue =
              newConfigValue[configKey as keyof typeof newConfigValue]

            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
              changes += 1
            }
          }
        )
      } else if (
        JSON.stringify(oldObject[key as keyof LotFilterInterface]) !==
        JSON.stringify(newObject[key as keyof LotFilterInterface])
      ) {
        changes += 1
      }
    } else if (
      key !== 'apply' &&
      oldObject[key as keyof LotFilterInterface] !==
        newObject[key as keyof LotFilterInterface]
    ) {
      changes += 1
    }
  })

  return changes
}

export default countFilterChanges
