import React from 'react'

import { ArrowSvg, FilterSvg } from '@svg/react'

const PrecinctSkeleton = () => (
  <div className="h-screen w-screen bg-mainColour">
    <div className="absolute left-5 top-5 z-2 flex items-center gap-4">
      <div className="rounded bg-white p-1 drop-shadow-40">
        <ArrowSvg className="h-8 w-8" strokeColor="#000" />
      </div>
      <div className="rounded bg-white p-2.5 drop-shadow-40">
        <FilterSvg className="h-5 w-5" />
      </div>
    </div>

    <div className="shimmer-container absolute left-0 top-0 z-1 h-full w-full"></div>
  </div>
)

export default PrecinctSkeleton
