import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra, UnitGalleryData } from '@store/types'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<UnitGalleryData>
if (isOffline()) {
  const unitGalleryData = localStorage.getItem('unitGalleryApiData')
  if (unitGalleryData) {
    initialState = JSON.parse(unitGalleryData)
  }
}
const unitGallerySlice = createSlice({
  name: 'unitGallery',
  initialState,
  reducers: {
    setUnitGallery: (
      state: Array<UnitGalleryData>,
      action: PayloadAction<UnitGalleryData>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'unitGalleryApiData',
          JSON.stringify([...state, ...[action.payload]])
        )
      }
      return [...state, ...[action.payload]]
    },
    removeUnitGallery: () => {
      localStorage.removeItem('unitGalleryApiData')
      return []
    },
  },
})
export const { setUnitGallery, removeUnitGallery } = unitGallerySlice.actions
export const selectUnitGallery = (state: RootStateTypeExtra) =>
  state.unitGallery
export default unitGallerySlice.reducer
