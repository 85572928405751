import React from 'react'

import Skeleton from '@components/skeleton'

import { ArrowSvg, FilterSvg } from '@svg/react'

const LevelViewSkeleton = () => (
  <Skeleton>
    <div className="absolute left-5 top-5 z-2 flex items-center gap-4">
      <div className="rounded bg-white p-1 drop-shadow-40">
        <ArrowSvg className="h-8 w-8" strokeColor="#000" />
      </div>
      <div className="rounded bg-white p-2.5 drop-shadow-40">
        <FilterSvg className="h-5 w-5" />
      </div>
    </div>
  </Skeleton>
)

export default LevelViewSkeleton
