import { PhoneNumberUtil } from 'google-libphonenumber'
import React from 'react'
import {
  PhoneInputProps,
  PhoneInputRefType,
  PhoneInput as ReactPhoneInput,
} from 'react-international-phone'

import './style.css'

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

const PhoneInput = React.forwardRef<
  PhoneInputRefType,
  PhoneInputProps & {
    onValidityChange: (status: boolean) => void
    showInputError?: boolean
  }
>((props, ref) => {
  const { onValidityChange, showInputError, ...inputProps } = props
  const isValid = isPhoneValid(props.value || '')

  React.useEffect(() => {
    onValidityChange(isValid)
  }, [isValid, onValidityChange])

  return (
    <>
      <ReactPhoneInput
        {...inputProps}
        ref={ref}
        inputClassName="my-2 w-full !rounded-r border-gray-200 focus:border-mainColour !px-6 !py-1.5 !h-auto focus:ring-0"
        countrySelectorStyleProps={{
          buttonClassName:
            '!h-auto !py-2.5 !px-2 border-r-0 hover:mr-0 !mr-0 !rounded-l',
        }}
        defaultCountry="au"
      />
      {!isValid && showInputError && (
        <p className="text-rose-500">Phone is not valid</p>
      )}
    </>
  )
})

export default PhoneInput
