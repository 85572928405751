import React from 'react'

import { FloorTabInterface } from '@store/types'

import { floorTabItems } from '@utilities/floor-tab'

interface ComponentPropsInterface {
  activeTab: string
  handleClick: (arg: string) => void
}

const StageGalleryTab = ({
  activeTab,
  handleClick,
}: ComponentPropsInterface) => (
  <div className="flex">
    {floorTabItems().map((tab: FloorTabInterface) => (
      <button
        key={tab.key}
        type="button"
        className={`text-md border border-mainColour first:rounded-l-md last:rounded-r-md ${
          activeTab === tab.key
            ? 'bg-mainColour font-bold text-white underline underline-offset-4'
            : 'bg-white font-medium text-mainColour'
        } px-8 py-2 tracking-widest`}
        onClick={() => handleClick(tab.key)}
      >
        {tab.label}
      </button>
    ))}
  </div>
)

export default StageGalleryTab
