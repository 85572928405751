import React from 'react'
import { useSelector } from 'react-redux'

import { RootStateFirebase } from '@store/types'

const events = ['mousemove', 'click', 'keyup', 'touchstart', 'touchmove']

interface IdleTimeHandlerProps {
  timeoutInSeconds?: number
}

export const useIdleTimeHandler = (props?: IdleTimeHandlerProps) => {
  const { controls } = useSelector(
    (state: RootStateFirebase) => state.projectConfig
  )
  const timeoutInSeconds = props?.timeoutInSeconds

  const timerId = React.useRef<NodeJS.Timeout | null>(null)
  const [isAppIdle, setIsAppIdle] = React.useState<boolean>(false)

  const resetTimer = React.useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = null
  }, [])

  const handleSetAppIdle = React.useCallback(() => {
    resetTimer()
    setIsAppIdle(true)
  }, [])

  const setTimer = React.useCallback(() => {
    timerId.current = setTimeout(
      handleSetAppIdle,
      (timeoutInSeconds ?? controls.duration) * 1000
    )
  }, [timeoutInSeconds, controls?.duration])

  const onUserInteraction = () => {
    setIsAppIdle(false)

    resetTimer()

    if (controls?.duration || timeoutInSeconds) {
      setTimer()
    }
  }

  React.useEffect(() => {
    if (controls?.duration || timeoutInSeconds) {
      setTimer()

      events.forEach((event: string) =>
        document.addEventListener(event, onUserInteraction, true)
      )
    }

    return () => {
      resetTimer()
      events.forEach((event: string) =>
        document.removeEventListener(event, onUserInteraction, true)
      )
    }
  }, [timeoutInSeconds, controls?.duration])

  return !timeoutInSeconds ? controls?.active && isAppIdle : isAppIdle
}

export default {}
