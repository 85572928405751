import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface ShortlistDocument {
  config?: string
  contentType: string
  driver: string
  fileName: string
  id: string
  location: string
  meta: ShortlistDocumentMeta
  name: string
  position: number
  subType?: string
  title?: string
  updated: string
}

export interface ShortlistDocumentMeta {
  logo: boolean
  map: boolean
  cover: boolean
}

interface GetShortlistParams {
  projectName: string
}

export const shortlistApi = createApi({
  reducerPath: 'shortlistedApi',
  baseQuery: baseQueryInterceptor,
  tagTypes: ['AgentsList'],
  endpoints: (builder) => ({
    getShortlistDocuments: builder.query<
      ApiResponse<Array<ShortlistDocument>>,
      GetShortlistParams
    >({
      query: (params: GetShortlistParams) => ({
        url: `/v1/${params.projectName}/shortlist/documents`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromShortlistDocumentsResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  data: Array<ShortlistDocument>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  data: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetShortlistDocumentsQuery } = shortlistApi
