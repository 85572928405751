import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { PackageConfigurationInterface } from '@store/types'

import {
  PackageInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'

import { Option } from './select-property/property-option'
import SelectProperty from './select-property/select-property'

export interface LotPackageProps {
  packages: Array<PackageInterface>
  packageOptions: Array<PackageOptionsInterface>
  values: PackageConfigurationInterface
  onChange: (
    propertyType: keyof PackageConfigurationInterface,
    value: string
  ) => void
  onExpandProperty?: (data: {
    propertyType: keyof PackageConfigurationInterface
    expanded: boolean
  }) => void
}

interface SelectPropertyProps {
  key: string
  label: string
  resetKey: string
  options: Option[]
  value: string
  onChange: (value: string) => void
  expandOptions: boolean
  onClick: () => void
  canDeselect?: boolean
  showAsThumbnail?: boolean
}

const LotPackage = ({
  packages,
  packageOptions,
  values,
  onChange,
  onExpandProperty,
}: LotPackageProps) => {
  const [expandedProperty, setExpandedProperty] = React.useState<
    keyof PackageConfigurationInterface | undefined
  >('floorplan')
  const activeOption = React.useMemo(
    () => packageOptions.find((pkg) => pkg.id === values.floorplan),
    [packageOptions, values.floorplan]
  )
  const activeFacade = React.useMemo(
    () => activeOption?.facades?.find((facade) => facade.id === values.facade),
    [activeOption, values.facade]
  )
  const activeInternalTheme = React.useMemo(
    () =>
      activeOption?.internalThemes?.find(
        (internalTheme) => internalTheme.id === values.internalTheme
      ),
    [activeOption, values.internalTheme]
  )

  const handleClick = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface) => () => {
      setExpandedProperty(
        expandedProperty === propertyType ? undefined : propertyType
      )
      onExpandProperty?.({
        propertyType,
        expanded: expandedProperty !== propertyType,
      })
    },
    [expandedProperty, onExpandProperty]
  )

  const handleChange = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface) => (value: string) => {
      onChange(propertyType, value)
    },
    [onChange]
  )

  const selectConfigurations = React.useMemo(() => {
    const result: SelectPropertyProps[] = []
    result.push({
      key: 'floorplan',
      label: 'FLOORPLAN',
      resetKey: 'floorplan',
      options: packages,
      value: values?.floorplan || '',
      onChange: handleChange('floorplan'),
      expandOptions: expandedProperty === 'floorplan',
      onClick: handleClick('floorplan'),
      canDeselect: true,
    })
    if (activeOption?.floorplanOptions?.length) {
      result.push({
        key: 'floorplanOption',
        label: 'FLOORPLAN OPTION',
        resetKey: `floorplanOption__${values?.floorplan}`,
        options: activeOption?.floorplanOptions || [],
        value: values?.floorplanOption || '',
        onChange: handleChange('floorplanOption'),
        expandOptions: expandedProperty === 'floorplanOption',
        onClick: handleClick('floorplanOption'),
        canDeselect: true,
      })
    }
    if (activeOption?.facades?.length) {
      result.push({
        key: 'facade',
        label: 'FACADE',
        resetKey: `facade__${values?.floorplan}`,
        options: activeOption?.facades || [],
        value: values?.facade || '',
        onChange: handleChange('facade'),
        expandOptions: expandedProperty === 'facade',
        onClick: handleClick('facade'),
        canDeselect: true,
        showAsThumbnail: true,
      })
    }
    if (activeFacade?.facadeColors?.length) {
      result.push({
        key: 'facadeColor',
        label: 'FACADE COLOUR',
        resetKey: `facadeColor__${activeFacade?.id}`,
        options: activeFacade?.facadeColors || [],
        value: values?.facadeColor || '',
        onChange: handleChange('facadeColor'),
        expandOptions: expandedProperty === 'facadeColor',
        onClick: handleClick('facadeColor'),
        canDeselect: true,
        showAsThumbnail: true,
      })
    }
    if (activeOption?.internalThemes?.length) {
      result.push({
        key: 'internalTheme',
        label: 'INTERNAL THEME',
        resetKey: `internalTheme__${values?.floorplan}`,
        options: activeOption?.internalThemes || [],
        value: values?.internalTheme || '',
        onChange: handleChange('internalTheme'),
        expandOptions: expandedProperty === 'internalTheme',
        onClick: handleClick('internalTheme'),
        canDeselect: true,
      })
    }
    if (activeInternalTheme?.internalColorSchemes?.length) {
      result.push({
        key: 'internalColorScheme',
        label: 'INTERNAL COLOUR SCHEME',
        resetKey: `internalColorScheme__${activeInternalTheme?.id}`,
        options: activeInternalTheme?.internalColorSchemes || [],
        value: values?.internalColorScheme || '',
        onChange: handleChange('internalColorScheme'),
        expandOptions: expandedProperty === 'internalColorScheme',
        onClick: handleClick('internalColorScheme'),
        canDeselect: true,
      })
    }
    return result
  }, [
    packages,
    values,
    handleChange,
    handleClick,
    expandedProperty,
    activeOption,
    activeFacade,
    activeInternalTheme,
  ])

  return (
    <TransitionGroup className="flex flex-col">
      {selectConfigurations.map((item, index) => (
        <CSSTransition
          key={item.key}
          classNames="transition-lot-select-property-expand"
          timeout={500}
        >
          <SelectProperty
            key={item.resetKey}
            label={`${index + 1}. ${item.label}`}
            options={item.options}
            value={item.value}
            onChange={item.onChange}
            expandOptions={item.expandOptions}
            onClick={item.onClick}
            canDeselect={item.canDeselect}
            showAsThumbnail={item.showAsThumbnail}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default LotPackage
