export enum LotStatusType {
  Sold = 'sold',
  Available = 'available',
}

export interface Configuration {
  bed: number
  study: number
  bath: number
  powderRoom: number
  car: number
}

export interface Package {
  id: string
  name: string
  storey: number
  builder: string
  configuration: Configuration
  lots: Array<string>
}

export interface Lot {
  id: string
  name: string
  area: string
  frontage: number
  aspect: string
  price: number
  status: LotStatusType
}

export interface Stage {
  id: string
  label: string
  lots: Lot[]
}

export type Stages = Record<string, Stage>

export interface PackageSummary {
  packages: Package[]
  stages: Stages
}

export interface MinMaxInterface {
  max: string
  min: string
}

export interface FilterOptionsConfigurations {
  bed?: MinMaxInterface
  study?: MinMaxInterface
  bath?: MinMaxInterface
  powderRoom?: MinMaxInterface
  car?: MinMaxInterface
}

export interface FilterOptionLotConfigurations {
  frontage?: MinMaxInterface
  area?: MinMaxInterface
  aspects?: Array<string>
  price?: MinMaxInterface
}

export interface FilterOptions {
  configurations?: FilterOptionsConfigurations
  builder?: Array<string>
  storey?: Array<number>
  lotConfiguration?: FilterOptionLotConfigurations
  showOnlyAvailable: boolean
}

export enum HouseAndLandPackageOptionType {
  FloorplanOption = 'floorplanOptions',
  Facade = 'facades',
  FacadeColor = 'facadeColors',
  InternalTheme = 'internalThemes',
  InternalColorScheme = 'internalColorSchemes',
}
