import React from 'react'
import InputRange from 'react-input-range'

interface VolumeBarProps {
  volume: number
  setVolume: (newVolume: number) => void
}

const VolumeBar = ({ volume, setVolume }: VolumeBarProps) => {
  const [sliderRangeValue, setSliderRangeValue] = React.useState(volume * 100)

  const handleOnChange = (newVolume: number) => {
    setSliderRangeValue(newVolume)

    setVolume(Math.round(newVolume + 0.1) / 100)
  }

  const handleOnChangeComplete = (newVolume: number) => {
    handleOnChange(newVolume)
  }

  return (
    <div className="rounded-track flex w-full items-center">
      <InputRange
        minValue={0}
        maxValue={100}
        value={sliderRangeValue}
        onChange={(value) => handleOnChange(Number(value))}
        onChangeComplete={(value) => handleOnChangeComplete(Number(value))}
        formatLabel={() => ''}
      />
    </div>
  )
}

export default VolumeBar
