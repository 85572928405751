import { CarouselContext } from 'pure-react-carousel'
import React from 'react'

import {
  DotInterface,
  LabelInterface,
  TextInterface,
  ThumbnailInterface,
} from '@store/types'

import { ArrowCircleSvg } from '@svg/react'

import { setScrollPosition } from '../helper'
import LabelDot from './label-dot'
import SlideScrollbar from './slide-scrollbar'
import TextDot from './text-dot'
import ThumbnailDot from './thumbnail-dot'

export interface SlideTriggerInterface {
  type: string
  items:
    | Array<DotInterface>
    | Array<TextInterface>
    | Array<LabelInterface>
    | Array<ThumbnailInterface>
  setCurrentSlide: (arg: number) => void
  currentSlide: number
  nextButtonId?: string
  previousButtonId?: string
  dotContainerRef: any
  alignClass?: string
  toggleControls?: boolean
  className?: string
  disableInfiniteScroll?: boolean
}

const SlideTrigger = ({
  type,
  items,
  setCurrentSlide,
  currentSlide,
  nextButtonId,
  previousButtonId,
  dotContainerRef,
  alignClass,
  toggleControls,
  className,
  disableInfiniteScroll = false,
}: SlideTriggerInterface) => {
  const carouselContext = React.useContext(CarouselContext)

  React.useEffect(() => {
    const onChange = async () => {
      await setCurrentSlide(carouselContext.state.currentSlide)
      setScrollPosition(dotContainerRef)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const dotAlignStyle = () => {
    let position = ''
    if (type === 'text') {
      position = 'text-left'
    } else if (type === 'thumbnail') {
      position = 'text-center'
    } else {
      position = 'text-left'
    }
    return position
  }

  return (
    <div
      className={`z-10 flex gap-4 ${
        alignClass || dotAlignStyle()
      } ${className}`}
    >
      {type === 'text' && (
        <div className="pointer-events-none absolute left-0 top-0 h-screen w-300px bg-shadow-vertical" />
      )}
      {items.length > 3 && previousButtonId && type !== 'text' && (
        <div className={`${type === 'thumbnail' && 'flex h-[85px]'}`}>
          <button
            type="button"
            className={`rounded-full drop-shadow-md ${
              type === 'label' && '-mt-1'
            } ${
              disableInfiniteScroll && currentSlide === 0
                ? 'cursor-not-allowed text-gray-300'
                : 'text-white'
            }`}
            onClick={() => {
              if (
                (disableInfiniteScroll && currentSlide === 0) ||
                !toggleControls
              )
                return
              document.getElementById(previousButtonId)?.click()
            }}
          >
            <ArrowCircleSvg size="m" rotate="left" />
          </button>
        </div>
      )}

      <div
        className={`${
          type === 'text'
            ? ''
            : 'max-w-[250px] flex-1 ipad-mini-land:max-w-[350px] ipad-pro-land:max-w-[30vw]'
        }`}
      >
        <div
          ref={dotContainerRef}
          className={`relative ${
            type === 'text'
              ? 'custom-secondary-scrollbar flex h-[50vh] flex-col gap-6 overflow-y-auto pl-[1.625rem] scroll-left'
              : 'no-scrollbar flex items-center gap-3 overflow-x-auto pb-1'
          }`}
        >
          {items.map((item, index) => (
            <div key={`${index as number}`} className="shrink-0">
              {type === 'text' && (
                <TextDot
                  item={item as TextInterface}
                  index={index}
                  currentSlide={currentSlide}
                  setCurrentSlide={setCurrentSlide}
                  disabled={!toggleControls}
                />
              )}
              {type === 'label' && (
                <LabelDot
                  item={item as LabelInterface}
                  index={index}
                  currentSlide={currentSlide}
                  setCurrentSlide={setCurrentSlide}
                  disabled={!toggleControls}
                />
              )}
              {type === 'thumbnail' && items.length > 0 && (
                <ThumbnailDot
                  item={item as ThumbnailInterface}
                  index={index}
                  currentSlide={currentSlide}
                  setCurrentSlide={setCurrentSlide}
                  disabled={!toggleControls}
                />
              )}
            </div>
          ))}
        </div>

        {items.length > 3 && type !== 'text' && (
          <div className="rounded-track mt-2 w-full drop-shadow-md">
            <SlideScrollbar
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              slideLength={items.length}
            />
          </div>
        )}
      </div>

      {items.length > 3 && nextButtonId && type !== 'text' && (
        <div className={`${type === 'thumbnail' && 'flex h-[85px]'}`}>
          <button
            type="button"
            className={`rounded-full drop-shadow-md ${
              type === 'label' && '-mt-1'
            } ${
              disableInfiniteScroll && currentSlide !== items.length - 1
                ? 'cursor-not-allowed text-gray-300'
                : 'text-white'
            }`}
            onClick={() => {
              if (
                (disableInfiniteScroll && currentSlide === items.length - 1) ||
                !toggleControls
              )
                return
              document.getElementById(nextButtonId)?.click()
            }}
          >
            <ArrowCircleSvg size="m" rotate="right" />
          </button>
        </div>
      )}
    </div>
  )
}

export default SlideTrigger
