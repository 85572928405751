import { Dot } from 'pure-react-carousel'
import React from 'react'

import { TextInterface } from '@store/types'

export interface TextDotInterface {
  item: TextInterface
  index: number
  currentSlide: number
  setCurrentSlide: (arg: number) => void
  disabled?: boolean
}

const TextDot = ({
  item,
  index,
  currentSlide,
  setCurrentSlide,
  disabled,
}: TextDotInterface) => (
  <Dot
    className="w-auto text-white"
    slide={index}
    onClick={() => setCurrentSlide(index)}
    disabled={disabled}
  >
    <div
      className={`flex transform items-center font-semibold uppercase tracking-widest transition duration-300 ease-in-out ${
        currentSlide === index
          ? 'underline decoration-2 underline-offset-[5px]'
          : ''
      }`}
    >
      {item.subText && (
        <span className="mr-4 text-base font-semibold drop-shadow">
          {item.subText}
        </span>
      )}
      <span className="text-base font-semibold drop-shadow-70">
        {item.text}
      </span>
    </div>
  </Dot>
)

export default TextDot
