import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { FloorPlateGalleryData } from '@api/floor-plate-gallery'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<FloorPlateGalleryData>

if (isOffline()) {
  const floorPlateGalleryData = localStorage.getItem('floorPlateGalleryApiData')
  if (floorPlateGalleryData) {
    initialState = JSON.parse(floorPlateGalleryData)
  }
}

const floorPlateGallerySlice = createSlice({
  name: 'floorPlateGallery',
  initialState,
  reducers: {
    setFloorPlateGallery: (
      state: Array<FloorPlateGalleryData>,
      action: PayloadAction<Array<FloorPlateGalleryData>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'floorPlateGalleryApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeFloorPlateGallery: () => {
      localStorage.removeItem('floorPlateGalleryApiData')
      return []
    },
  },
})

export const { setFloorPlateGallery, removeFloorPlateGallery } =
  floorPlateGallerySlice.actions

export const selectFloorPlateGallery = (state: RootStateTypeExtra) =>
  state.floorPlateGallery

export default floorPlateGallerySlice.reducer
