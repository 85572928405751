import React from 'react'

import { SvgProps } from './types'

const CloseSvg = ({
  width,
  height,
  className,
  fill,
  strokeColor,
}: SvgProps) => (
  <svg
    className={className}
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill={fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 18L10 10L18 18M18 2L9.99847 10L2 2"
      stroke={strokeColor || 'white'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CloseSvg
