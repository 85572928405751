import { ReactNode } from 'react'

import type { RootStateType } from '@store/store'

import { FileType } from '@utilities/adgroup-utilities/types/common'
import {
  FilterOptionLotConfigurations,
  FilterOptionsConfigurations,
  HouseAndLandPackageOptionType,
} from '@utilities/adgroup-utilities/types/houseAndLand'

export type RootStateTypeExtra = RootStateType

// TODO: This interface is kind of duplicate of FilterOptions (from utility submodule), this needs to be addressed (later)
// Not changing right now because the properties in the other interface are all options but required here, and need to be sorted
export interface LotFilterInterface {
  configurations: FilterOptionsConfigurations
  builder: Array<string>
  storey: Array<number>
  lotConfiguration: FilterOptionLotConfigurations
  showOnlyAvailable: boolean
  anyStage: boolean
  apply: boolean
}

export enum InteractivePlanType {
  AreaView = 'areaView',
  Blocks = 'blocks',
  Floorplan = 'floorplan',
  Precinct = 'precinct',
  Stages = 'stages',
}

export interface MinMaxInterface {
  max: string
  min: string
}

export interface DropDownOption {
  label: ReactNode
  value: string
}

export interface ConfigOption {
  max: Array<DropDownOption>
  min: Array<DropDownOption>
}

export interface PriceRange {
  max: Array<number>
  min: Array<number>
}

export interface BedConfigInterface {
  [key: string]: Array<number>
}

export interface Configurations {
  bed: MinMaxInterface
  study: MinMaxInterface
  bath: MinMaxInterface
  powderRoom: MinMaxInterface
  car: MinMaxInterface
}
export interface HouseAndLandConfigurationInterface {
  lotFrontage?: Array<number>
  lotArea?: Array<number>
  builder?: Array<string>
  storey?: Array<{ label: string; value: number }>
}

export interface UnitFilterInterface {
  range: MinMaxInterface
  configurations: Configurations
  aspect: Array<string>
  unitType: string
  showAvailable: boolean
  anyLevel: boolean
  apply: boolean
}

export interface RootStateFirebase extends RootStateType {
  firestore: FireStoreMap
}

export interface Channel {
  name: string
  status: boolean
  channel: string | number
  type: string
}

export interface FireStoreMap {
  data: {
    devsuite: SessionMap
    lightMap: {
      channels: Array<Channel>
    }
  }
  status: any
}

export interface SessionOwner {
  email: string
  name: string
  lastUpdated: string
}

export interface SnapDataInterface {
  views: Array<string>
  levels: Array<{ key: any; value: any }>
}

interface SnaploaderInterface {
  isLoaded: false
  data: SnapDataInterface
  activeView: string
  activeBlock: string
  rotateCamera: boolean
}

export interface ProjectSwitch {
  projectLabel: string
  projectId: string
  projectName: string
}

export interface EnvisionVRInterface {
  isLoaded: boolean
  buildings: {
    data: Array<string>
  }
  levels: {
    data: Array<string>
  }
  rotateCamera: true
  rotateCameraBy: {
    direction: string
    triggerKey: string
  }
  zoomCamera: {
    action: string
    triggerKey: string
  }
  activeBuilding: string
  modelResetTriggerKey: string
}

export interface SessionMap {
  client: { showcase: boolean }
  connected: boolean
  activeRoute: string
  appIdleKey: string
  showcaseRefreshTriggerKey: string
  clearCacheTriggerKey: string
  sessionOwners: Array<SessionOwner>
  interactiveMap: Array<string>
  interactiveMapActiveCategory: {
    label: string
    baseImageOverride: string
  }
  projectSwitch: ProjectSwitch
  vision: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
    sessionModal: boolean
  }
  areaView: {
    triggerBuilding: boolean
  }
  design: {
    galleryControl: GalleryControlInterface
  }
  brochure: {
    galleryControl: GalleryControlInterface
  }
  location: {
    galleryControl: GalleryControlInterface
  }
  team: {
    galleryControl: GalleryControlInterface
  }
  floorGallery: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
  }
  unitGallery: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
  }
  building: {
    activeFacade: string
    activeLevel: string
    activeBlock: string
    activeUnit: string
    filterPopup: boolean
    unitInfoPopup: boolean
    externalLinkPopup: boolean
    sidePanelFolded: boolean
    unitFilter: UnitFilterInterface
    levelsShowLabels: boolean
    unitsShowLabels: boolean
    aspect: string
    showAvailable: boolean
    apply: boolean
  }
  houseAndLand: {
    activeStageId: string
    activePrecinctId: string
    activeLotId: string
    activePackageId: string
    activeFloorplanOptionId: string
    activeFacadeId: string
    activeFacadeColorId: string
    activeInternalThemeId: string
    activeInternalColorSchemeId: string
    lastActiveOption: {
      type: HouseAndLandPackageOptionType
      id: string
    }
    filterPopup: boolean
    lotInfoPopup: boolean
    externalLinkPopup: boolean
    sidePanelFolded: boolean
    lotFilter: LotFilterInterface
    isDataLoaded: boolean
  }
  lotGallery: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
  }
  panoramic: {
    galleryControl: GalleryControlInterface
    pitch: number
    yaw: number
    zoom: number
    scene: string
    rotationSpeed: number
  }
  videoGallery: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
  }
  externalView: number
  snaploader: SnaploaderInterface
  envisionVR: EnvisionVRInterface
  shortlist: {
    properties: ShortlistItemInterface[]
    selectedDocumentIds: string[]
    selectedAgentId: string
  }
}

export interface User {
  _id: string
  name: string
  email: string
  email_verified_at: string
  updated_at: string
  created_at: string
}

export interface Token {
  token: string
  expires_at: string
}

export interface SessionKey {
  key: string
  project: string
}

export interface MasterSessionKey {
  projectId: string
  key: string
}

export interface MenuItemInterface {
  key: string
  label: string
  active: boolean
  route?: string
  relatedRoutes?: Array<string>
  order?: number
}

export interface ProjectIdentity {
  projectId: string
  projectName: string
  projectLabel: string
  sessionId: Array<SessionKey>
  lightMap: string
  masterKey: string
}

export interface Level {
  level: string
  aspect: string
  floorPlan: string
  units: Array<Unit>
}

export interface Unit {
  name: string
  configuration: string
  status: string
  image: string
  price?: number
  gallery?: Array<string>
  coordinates?: {
    x: number
    y: number
  }
  documents?: Array<{
    title: string
    url: string
  }>
}

export interface DotInterface {
  text: string
}

export interface TextInterface {
  text: string
  subText?: string
}
export interface SlideItemInterface {
  text: string
  subText?: string
}

export interface LabelInterface {
  text: string
}

export interface ThumbnailInterface {
  src: string
  type: 'legacy' | 'new'
  noSpliceUrl: boolean
  gradiant: number
}

export interface GalleryItemInterface {
  title: string
  imageSource: string
  videoSource?: string
}

export interface GalleryInterface {
  label?: string
  items: Array<GalleryItemInterface>
}

export interface TabInterface {
  index: number
  label: string
}

export interface GalleryControlInterface {
  activeTabIndex: number
  activeItemID: string
  transitionTime: number
  isPlaying: boolean
  transitionType: string
}

export interface PlayerControlInterface {
  isPlayerVisible: boolean
  isMuted: boolean
  volume: number
  playerState: string
  fullScreen: boolean
  scrubbedLength: number
  videoLengthInSeconds: number
  isBuffering: boolean
  scrubPositionInSeconds: number
  currentVideoPositionInSeconds: number
  videoSynchronizeTriggerKey: boolean
}

export interface UnitGallery {
  id: string
  src: string
  label: string
  type: string
  noSpliceUrl: boolean
  thumbnail?: string
  gradiant?: number
}

export interface UnitGalleryData {
  unit: string
  items: Array<UnitGallery>
}

export interface LotGallery {
  id: string
  src: string
  label: string
  type: FileType
  noSpliceUrl: boolean
  thumbnail?: string
  gradiant?: number
}

export interface LotGalleryData {
  lot: string
  items: Array<LotGallery>
}

export interface FloorTabInterface {
  key: string
  label: string
}

export interface PackageConfigurationInterface {
  floorplan?: string
  floorplanOption?: string
  facade?: string
  facadeColor?: string
  internalTheme?: string
  internalColorScheme?: string
}

export enum ShortlistPropertyType {
  Lot = 'lot',
  Unit = 'unit',
}

interface BaseShortlistInterface {
  propertyId: string
  propertyName: string
}

export interface UnitShortlistInterface extends BaseShortlistInterface {
  references: {
    blockId: string | null
    levelId: string
  }
  type: ShortlistPropertyType.Unit
}
export interface LotShortlistInterface extends BaseShortlistInterface {
  configuration?: PackageConfigurationInterface
  references: {
    precinctId: string
    stageId: string
  }
  type: ShortlistPropertyType.Lot
}

export type ShortlistItemInterface =
  | UnitShortlistInterface
  | LotShortlistInterface
