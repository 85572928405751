import React from 'react'

import { SvgProps } from './types'

const PlayCircleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  stroke,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.6,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
        strokeWidth={stroke || '0.5'}
        d="M12 5.2a7 7 0 100 14 7 7 0 000-14z"
      />
      <path
        fill="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={stroke || '0.5'}
        d="M15.1 12.168l-4.752-3.168v6.336z"
      />
    </svg>
  )
}

export default PlayCircleSvg
