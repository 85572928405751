import React from 'react'

import { SvgProps } from './types'

const PauseSvg = ({
  width,
  height,
  className,
  stroke,
  strokeColor,
  fill,
}: SvgProps) => (
  <svg
    width={width || '14'}
    height={height || '17'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={fill || 'currentColor'}
    viewBox="0 0 14 17"
    stroke={strokeColor}
    strokeWidth={stroke || 1.5}
  >
    <path d="M1 14.9V2.09996C1 1.94083 1.06321 1.78822 1.17574 1.6757C1.28826 1.56318 1.44087 1.49996 1.6 1.49996H4.4C4.55913 1.49996 4.71174 1.56318 4.82426 1.6757C4.93679 1.78822 5 1.94083 5 2.09996V14.9C5 15.0591 4.93679 15.2117 4.82426 15.3242C4.71174 15.4367 4.55913 15.5 4.4 15.5H1.6C1.44087 15.5 1.28826 15.4367 1.17574 15.3242C1.06321 15.2117 1 15.0591 1 14.9ZM9 14.9V2.09996C9 1.94083 9.06321 1.78822 9.17574 1.6757C9.28826 1.56318 9.44087 1.49996 9.6 1.49996H12.4C12.5591 1.49996 12.7117 1.56318 12.8243 1.6757C12.9368 1.78822 13 1.94083 13 2.09996V14.9C13 15.0591 12.9368 15.2117 12.8243 15.3242C12.7117 15.4367 12.5591 15.5 12.4 15.5H9.6C9.44087 15.5 9.28826 15.4367 9.17574 15.3242C9.06321 15.2117 9 15.0591 9 14.9Z" />
  </svg>
)

export default PauseSvg
