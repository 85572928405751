import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface GalleryData {
  id: string
  type: 'image' | 'video'
  videoSrc?: string
  imageSrc?: string
  showcaseRoom: string
}

export interface FloorPlateGalleryData {
  level: string
  block: string
  precinct: string
  stage: string
  assets: Array<GalleryData>
}

type FloorPlateGalleryType = {
  projectName: string
}

export const floorPlateGalleryApi = createApi({
  reducerPath: 'floorPlateGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getFloorPlateGallery: builder.query<
      ApiResponse<Array<FloorPlateGalleryData>>,
      FloorPlateGalleryType
    >({
      query: (params: FloorPlateGalleryType) => ({
        url: `/v1/${params.projectName}/floor-plates`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  floorPlateGalleryData: Array<FloorPlateGalleryData>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  floorPlateGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetFloorPlateGalleryQuery } = floorPlateGalleryApi
