const ON_UNITY_MESSAGE = 'onUnityMessage'
const ON_LEVEL_SELECTED = 'OnLevelSelectedCallback'
const ON_MODEL_LOADED = 'OnModelLoadedCallback'
const GET_BUILDING_IDS = 'GetBuildingIDsCallback'
const GET_LEVELS = 'GetLevelsCallback'
const ON_LEVEL_HOVERED = 'OnLevelHoveredCallback'
const ON_LEVEL_HOVERED_OUT = 'OnLevelHoveredOutCallback'
const ON_MODEL_DOWNLOAD = 'DownloadModelCallback'

export {
  ON_UNITY_MESSAGE,
  ON_LEVEL_SELECTED,
  ON_MODEL_LOADED,
  GET_BUILDING_IDS,
  GET_LEVELS,
  ON_LEVEL_HOVERED,
  ON_LEVEL_HOVERED_OUT,
  ON_MODEL_DOWNLOAD,
}
