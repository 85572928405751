import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'

import { setConnectionStatus } from '@store/actionSlices/connectionIndicator'
import { RootStateTypeExtra } from '@store/types'

import prepareHeaders from '@api/headers'
import {
  isTokenExpired,
  renewUserAccessToken,
} from '@api/utilities/token-helper'

const mutex = new Mutex()

const baseUrl = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders })

const baseQueryInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let connectionStatus = true

  const { token } = api.getState() as RootStateTypeExtra

  await mutex.waitForUnlock()

  if (isTokenExpired(token) && !mutex.isLocked()) {
    const release = await mutex.acquire()
    try {
      await renewUserAccessToken(token, api, extraOptions)
    } finally {
      release()
    }
  } else {
    await mutex.waitForUnlock()
  }

  let response = await baseQuery(args, api, extraOptions)

  if (response) {
    if (response.error) {
      const { status } = response.error

      if (status === 401) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire()

          try {
            const renewStatus = await renewUserAccessToken(
              token,
              api,
              extraOptions
            )
            if (renewStatus) {
              response = await baseQuery(args, api, extraOptions)
            }
          } finally {
            release()
          }
        } else {
          await mutex.waitForUnlock()
          response = await baseQuery(args, api, extraOptions)
        }
      }

      if (status === 'FETCH_ERROR') {
        connectionStatus = false
      }
    }
  } else {
    connectionStatus = false
  }

  api.dispatch(setConnectionStatus(connectionStatus))

  return response
}

export default baseQueryInterceptor
