import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { ApiResponse } from './types/api-response.type'

type ExternalLinkListInterface = {
  id: string
  linkUrl: string
  label: string
}

type ExternalLinksRequestParameters = {
  projectName: string
  propertyId: string
}

const API_VERSION = 'v3'

export const externalLinksApi = createApi({
  reducerPath: 'externalLinksApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getExternalLinksByProperty: builder.query<
      ApiResponse<ExternalLinkListInterface>,
      ExternalLinksRequestParameters
    >({
      query: (params: ExternalLinksRequestParameters) => ({
        url: `/${API_VERSION}/${params.projectName}/property-external-links/properties/${params.propertyId}/list`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
  isFetching,
}: UseQueryStateResult<any, any>) => ({
  externalLinks: data?.data || [],
  isLoaded: !isLoading,
  isError,
  error,
  isFetching,
  status,
})

export const { useGetExternalLinksByPropertyQuery } = externalLinksApi
