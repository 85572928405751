import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { setRoom } from '@store/actionSlices/appConfig'

import { GenericModalCard as ModalCard } from '@components/modals'

import InputHandler from '@utilities/form-util'
import { getQueryStringParams } from '@utilities/helper'

interface RoomHandlerProps {
  room: string
}

const RoomHandler = ({ room }: RoomHandlerProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const VISION_ROUTE = 'vision'

  const [toggled, setToggleModal] = React.useState(false)
  const [inputRoomValue, setInputRoomValue] = React.useState({
    value: room,
    isValid: true,
    message: '',
  })

  const isRoomValid = (value: string) => !!value

  const handleSaveRoom = () => {
    if (inputRoomValue.value) {
      dispatch(setRoom(inputRoomValue.value))
      history.push(VISION_ROUTE)
      window.location.reload()
    }
  }

  const resetInputRoom = () =>
    setInputRoomValue({
      value: '',
      isValid: true,
      message: '',
    })

  const handleRemoveRoom = () => {
    resetInputRoom()
    dispatch(setRoom(''))
    history.push(VISION_ROUTE)
    window.location.reload()
  }

  React.useEffect(() => {
    setInputRoomValue({
      ...inputRoomValue,
      value: room,
    })
  }, [toggled])

  React.useEffect(() => {
    const { room: roomParam } = getQueryStringParams(location.search)
    if (roomParam !== undefined) {
      dispatch(setRoom(roomParam))
    }
  }, [])

  return (
    <>
      <ModalCard
        headerLabel="Room"
        okLabel="Save"
        toggle={(status: boolean) => setToggleModal(status)}
        cancelAction={() => resetInputRoom()}
        okAction={() => handleSaveRoom()}
        isVisible={toggled}
        disableOk={inputRoomValue.value.length === 0 || !inputRoomValue.isValid}
        hideOk={room.length > 0}
      >
        <div className="px-4 text-left">
          <b>Add a valid room:</b>
        </div>
        <div
          className={`mx-4 my-3 flex items-center justify-center rounded border text-lg
          ${
            !inputRoomValue.isValid && inputRoomValue.value
              ? 'border-red-700'
              : 'border-gray-300'
          }
        `}
        >
          <input
            onChange={(event) =>
              InputHandler(
                event,
                setInputRoomValue,
                'The given room is invalid',
                isRoomValid
              )
            }
            className="w-full border-none focus:ring-0"
            placeholder="Enter your room"
            type="text"
            value={inputRoomValue.value}
          />
          {room && (
            <button
              onClick={() => handleRemoveRoom()}
              type="button"
              className="ml-auto p-2 text-red-700"
            >
              Remove
            </button>
          )}
        </div>
        <div className="px-4 text-left text-sm">
          {!inputRoomValue.isValid && inputRoomValue.value && (
            <span className="ml-auto text-red-700">
              {inputRoomValue.message}
            </span>
          )}
        </div>
      </ModalCard>
      <button
        onClick={() => setToggleModal(!toggled)}
        type="button"
        className="text-blue-500 underline underline-offset-2"
      >
        Room
      </button>
    </>
  )
}

export default RoomHandler
