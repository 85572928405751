import { FireStoreMap, ProjectIdentity, SessionKey } from '@store/types'

interface FirebaseQueryInterface {
  collection: string
  doc: string
  storeAs: string
}

export interface FirebaseQueryCollectionInterface
  extends FirebaseQueryInterface {
  subcollections?: Omit<FirebaseQueryInterface, 'storeAs'>[]
}

const getSession = (firestore: FireStoreMap) => {
  const { status } = firestore
  const { devsuite } = firestore?.data ?? {}
  if (devsuite && status.requested.devsuite) {
    return devsuite
  }
  return undefined
}

export const getCurrentSessionKey = ({
  sessionId,
  projectName,
}: {
  sessionId: Array<SessionKey>
  projectName: string
}) =>
  sessionId.find((res: SessionKey) => res.project === projectName)?.key || ''

export const setFirebaseQueryParam = (projectIdentity: ProjectIdentity) => {
  const FirebaseQuery: FirebaseQueryCollectionInterface[] = []

  const { projectName, lightMap } = projectIdentity

  const sessionKey = getCurrentSessionKey(projectIdentity)
  if (projectName) {
    let showcaseQury = FirebaseQuery
    if (sessionKey) {
      showcaseQury = [
        ...showcaseQury,
        {
          collection: 'devsuite',
          doc: projectName,
          subcollections: [
            {
              collection: 'session',
              doc: sessionKey,
            },
          ],
          storeAs: 'devsuite',
        },
      ]
    }
    if (lightMap) {
      showcaseQury = [
        ...showcaseQury,
        {
          collection: 'devsuite',
          doc: projectName,
          subcollections: [{ collection: 'light-map', doc: lightMap }],
          storeAs: 'lightMap',
        },
      ]
    }
    return showcaseQury
  }
  return FirebaseQuery
}

export default getSession
