import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import {
  HouseAndLandActiveKeyType,
  MatrixDataCollectionInterface,
  PackageOptionsInterface,
  PrecinctListItemInterface,
} from '@api/types/house-and-land-type'

import isOffline from '@utilities/offline-mode-util'

export interface HouseAndLandInterface {
  activePrecinctId: string
  activeStageId: string
  activeLotId: string
  activeLotName: string
  activePackageId: string
  matrixData: MatrixDataCollectionInterface
  packages: Array<PackageOptionsInterface>
  precinctList: Array<PrecinctListItemInterface>
}

const LOCALSTORAGE_KEY = 'houseAndLandApiData'
const LOCALSTORAGE_KEY_ACTIVE_DATA = 'houseAndLandActiveData'

let activeData = {
  activePrecinctId: '',
  activeStageId: '',
  activeLotId: '',
  activeLotName: '',
  activePackageId: '',
}

let data = {
  matrixData: {},
  packages: [],
  precinctList: [],
}

const houseAndLandApiData = localStorage.getItem(LOCALSTORAGE_KEY)
const houseAndLandActiveData = localStorage.getItem(
  LOCALSTORAGE_KEY_ACTIVE_DATA
)

if (houseAndLandActiveData) {
  activeData = JSON.parse(houseAndLandActiveData)
}

if (isOffline()) {
  if (houseAndLandApiData) {
    data = JSON.parse(houseAndLandApiData)
  }
}

const storeDataInLocalstorage = (state: HouseAndLandInterface) => {
  const { matrixData, packages, precinctList } = state

  localStorage.setItem(
    LOCALSTORAGE_KEY,
    JSON.stringify({
      matrixData,
      packages,
      precinctList,
    })
  )
}

const initialState = {
  ...activeData,
  ...data,
} as HouseAndLandInterface

const houseAndLandSlice = createSlice({
  name: 'houseAndLand',
  initialState,
  reducers: {
    setMatrixData: (
      state: HouseAndLandInterface,
      action: PayloadAction<MatrixDataCollectionInterface>
    ) => {
      const newState = {
        ...state,
        matrixData: { ...state.matrixData, ...action.payload },
      }

      if (isOffline()) {
        storeDataInLocalstorage(newState)
      }
      return newState
    },

    setPackages: (
      state: HouseAndLandInterface,
      action: PayloadAction<Array<PackageOptionsInterface>>
    ) => {
      const newState = {
        ...state,
        packages: [...state.packages, ...action.payload],
      }

      if (isOffline()) {
        storeDataInLocalstorage(newState)
      }
      return newState
    },

    setPrecinctList: (
      state: HouseAndLandInterface,
      action: PayloadAction<Array<PrecinctListItemInterface>>
    ) => {
      const newState = {
        ...state,
        precinctList: action.payload,
      }

      if (isOffline()) {
        storeDataInLocalstorage(newState)
      }
      return newState
    },

    setByFlag: (
      state: HouseAndLandInterface,
      action: PayloadAction<{
        flag: HouseAndLandActiveKeyType
        value: string
      }>
    ) => {
      const newState = { ...state, [action.payload.flag]: action.payload.value }

      const { activePrecinctId, activeStageId, activeLotId, activePackageId } =
        newState

      localStorage.setItem(
        LOCALSTORAGE_KEY_ACTIVE_DATA,
        JSON.stringify({
          activePrecinctId,
          activeStageId,
          activeLotId,
          activePackageId,
        })
      )

      return newState
    },
    resetHouseAndLand: () => {
      localStorage.removeItem(LOCALSTORAGE_KEY)
      localStorage.removeItem(LOCALSTORAGE_KEY_ACTIVE_DATA)
      return initialState
    },
  },
})

export const {
  setMatrixData,
  setPackages,
  setByFlag,
  setPrecinctList,
  resetHouseAndLand,
} = houseAndLandSlice.actions

export const selectHouseAndLand = (state: RootStateTypeExtra) =>
  state.houseAndLand

export default houseAndLandSlice.reducer
