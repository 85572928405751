import React from 'react'
import { Switch, useHistory, useLocation } from 'react-router-dom'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import type { ProjectIdentity, SessionMap } from '@store/types'

import AbsoluteContainer from '@components/container/absolute-container'
import Navigation from '@components/navigation'
import { ProtectedRoutes, PublicRoutes } from '@components/route-guards'
import WithPageTransition from '@components/transition'

import PageNotFound from '@pages/404'
import Area from '@pages/area'
import AreaHouseAndLand from '@pages/area-house-and-land'
import Brochure from '@pages/brochure'
import Building from '@pages/building'
import Design from '@pages/design'
import EnvisionVR from '@pages/envision-vr'
import ExternalViews from '@pages/external-views'
import InteractiveMap from '@pages/interactive-map'
import LevelView from '@pages/level-view'
import Location from '@pages/location'
import Login from '@pages/login'
import PanoramicView from '@pages/panoramic-view'
import Precinct from '@pages/precinct'
import Settings from '@pages/settings'
import Shortlisted from '@pages/shortlist'
import SnaploaderView from '@pages/snaploader-view'
import Stage from '@pages/stage'
import Teams from '@pages/teams'
import ThreeDViewer from '@pages/three-d-viewer'
import VideoGallery from '@pages/video-gallery'
import Vision from '@pages/vision'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getCurrentSessionKey } from '@utilities/firebase-util'
import { hasToken } from '@utilities/helper'
import useCurrentPage from '@utilities/location-util'

export interface RoutesProps {
  session: SessionMap
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
}

const Routes = ({ session, projectIdentity, token }: RoutesProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })
  const history = useHistory()
  const location = useLocation()
  const [currentPage] = useCurrentPage()
  const [forceFullScreenMode, setForceFullScreenMode] =
    React.useState<boolean>()

  const disableNavigation = React.useMemo(
    () => !hasToken(token) || currentPage === '',
    [currentPage, token]
  )

  const createSessionFireStore = ({
    sessionId,
    projectName,
  }: ProjectIdentity) => {
    const currentSession = getCurrentSessionKey({
      ...projectIdentity,
      sessionId,
      projectName,
    })
    if (currentSession && projectName) {
      firebaseControlQuery.createSession({
        sessionId: currentSession,
        projectName,
      })
    }
  }

  React.useEffect(() => {
    if (session) {
      const { activeRoute, connected } = session
      if (connected && projectIdentity.projectName) {
        const paths = location.pathname.split('/')
        if (paths[2] !== activeRoute) {
          history.push({
            pathname: activeRoute,
            state: { from: location.pathname },
          })
        }
      }
    }
  }, [session, projectIdentity])

  React.useEffect(() => {
    if (!session) {
      const sessionKey = getCurrentSessionKey(projectIdentity)
      if (sessionKey) {
        createSessionFireStore(projectIdentity)
      }
    }
  }, [session])

  return (
    <>
      {disableNavigation ? null : (
        <Navigation forceFullScreenMode={forceFullScreenMode} />
      )}
      <WithPageTransition>
        <Switch location={location}>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/panoramic`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <PanoramicView />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/external-views`}
            authenticationPath="/"
          >
            <ExternalViews />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/area-view`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Area />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/area-view-house-and-land`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <AreaHouseAndLand />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/level-view`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <LevelView />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/precinct`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Precinct />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/building`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Building onFullScreenToggle={setForceFullScreenMode} />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/shortlist`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Shortlisted />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/gallery`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Design />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/areaview-3d`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <ThreeDViewer />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/location`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Location />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/vision`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Vision />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/teams`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Teams />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/brochure`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Brochure />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/video-gallery`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <VideoGallery />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/interactive-map`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <InteractiveMap />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/settings`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Settings />
            </AbsoluteContainer>
          </ProtectedRoutes>

          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/snaploader-view`}
            authenticationPath="/"
          >
            <SnaploaderView />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/3d-building`}
            authenticationPath="/"
          >
            <EnvisionVR />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/stages`}
            authenticationPath="/"
          >
            <Stage onFullScreenToggle={setForceFullScreenMode} />
          </ProtectedRoutes>

          <PublicRoutes
            strict
            exact
            path={`/${projectIdentity.projectName}/`}
            authenticatedPath={`/${projectIdentity.projectName}/vision`}
          >
            <Vision />
          </PublicRoutes>

          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/*`}
            authenticationPath="/"
          >
            <PageNotFound />
          </ProtectedRoutes>

          <PublicRoutes
            exact
            path="/"
            authenticatedPath={`/${projectIdentity.projectName}/vision`}
          >
            <Login />
          </PublicRoutes>

          <PublicRoutes
            exact
            path="/:projectid"
            authenticatedPath={`/${projectIdentity.projectName}/vision`}
          >
            <Login />
          </PublicRoutes>

          <PublicRoutes
            exact
            path="/:projectid/*"
            authenticatedPath={`/${projectIdentity.projectName}/vision`}
          >
            <Login />
          </PublicRoutes>
        </Switch>
      </WithPageTransition>
    </>
  )
}

export default Routes
