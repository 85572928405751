import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectHouseAndLand,
  setMatrixData,
  setPackages,
} from '@store/actionSlices/houseAndLand'
import { setShortlists } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  ShortlistItemInterface,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { Payload } from '@components/data-handler/data-handler'

import {
  Level,
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectMatrixDataFromResult,
  selectPackagesFromResult,
  useLazyGetMatrixDataByPrecinctQuery,
  useLazyGetPackagesByIdQuery,
} from '@api/houseAndLand'
import { MatrixDataCollectionInterface } from '@api/types/house-and-land-type'

interface UseGetShortlistDataArgs {
  propertyType: ShortlistPropertyType
  isConnected: boolean
  projectName: string
  shortlists: ShortlistItemInterface[]
  sessionShortlists?: ShortlistItemInterface[]
}

interface UseGetShortlistDataReturn {
  queryResult: Omit<Payload, 'data' | 'apiData'>
  queryResultData: Payload['apiData']
  filteredShortlists: ShortlistItemInterface[]
}

const AVAILABLE_STATUS = 'available'

const useGetShortlistData: (
  data: UseGetShortlistDataArgs
) => UseGetShortlistDataReturn = ({
  propertyType,
  isConnected,
  projectName,
  shortlists,
  sessionShortlists,
}: UseGetShortlistDataArgs) => {
  const { matrixData, packages } = useSelector(selectHouseAndLand)
  const dispatch = useDispatch()
  const [filteredShortlists, setFilteredShortlists] = React.useState<
    ShortlistItemInterface[]
  >([])

  React.useEffect(() => {
    setFilteredShortlists(shortlists)
  }, [shortlists])

  const buildingQueryResult = useGetBuildingQuery(
    { projectName },
    {
      selectFromResult: selectFromBuildingResult,
      skip: propertyType !== ShortlistPropertyType.Unit,
    }
  )

  const flattenLevels = React.useCallback(
    (data: Level[]): (Unit & { level: string })[] =>
      data.flatMap((item) =>
        item.data.map((unit) => ({ ...unit, level: item.level }))
      ),
    []
  )

  React.useEffect(() => {
    if (propertyType !== ShortlistPropertyType.Unit) {
      return
    }
    if (buildingQueryResult.status === 'fulfilled' && isConnected) {
      const flattenedLevels: (Unit & { level: string })[] = flattenLevels(
        buildingQueryResult.building
      )

      const properties: UnitShortlistInterface[] = flattenedLevels
        .filter((unit) =>
          sessionShortlists?.find((item) => item.propertyId === unit.id)
        )
        .map((unit) => ({
          propertyId: unit.id,
          propertyName: unit.name,
          references: {
            levelId: unit.metas.level,
            blockId: unit.blockId,
          },
          type: ShortlistPropertyType.Unit,
        }))
      setFilteredShortlists(properties)
      dispatch(setShortlists(properties))
    }
  }, [
    sessionShortlists,
    isConnected,
    buildingQueryResult.status,
    flattenLevels,
  ])

  React.useEffect(() => {
    if (propertyType !== ShortlistPropertyType.Unit) {
      return
    }
    if (buildingQueryResult.status === 'fulfilled' && !isConnected) {
      const flattenedLevels: (Unit & { level: string })[] = flattenLevels(
        buildingQueryResult.building
      )

      const availableProperties: UnitShortlistInterface[] = flattenedLevels
        .filter((unit) =>
          shortlists.some((item) => item.propertyId === unit.id)
        )
        .filter((item) => item.metas.status === AVAILABLE_STATUS)
        .map((unit) => ({
          propertyId: unit.id,
          propertyName: unit.name,
          references: {
            blockId: unit.blockId,
            levelId: unit.metas.level,
          },
          type: ShortlistPropertyType.Unit,
        }))

      setFilteredShortlists(availableProperties)
      dispatch(setShortlists(availableProperties))
    }
  }, [isConnected, buildingQueryResult.status, flattenLevels])

  const [
    batchMatrixDataByPrecinctQueryResponse,
    setBatchMatrixDataByPrecinctQueryResponse,
  ] = React.useState<
    Omit<
      ReturnType<typeof selectMatrixDataFromResult>,
      'matrixData' | 'errorStatus'
    > & {
      matrixData?: MatrixDataCollectionInterface
      errorStatus?: number
    }
  >({
    isError: false,
    isFetching: false,
    isLoaded: false,
    status: 'uninitialized',
    matrixData: {},
  })

  const [getMatrixDataByPrecinctQuery] = useLazyGetMatrixDataByPrecinctQuery({
    selectFromResult: selectMatrixDataFromResult,
  })
  const [getPackagesByIdQuery, packagesByIdQueryResponse] =
    useLazyGetPackagesByIdQuery({ selectFromResult: selectPackagesFromResult })

  const unavailablePrecinctIds = React.useMemo(() => {
    if (!(propertyType === ShortlistPropertyType.Lot)) {
      return undefined
    }
    const lotShortlists = (
      isConnected ? sessionShortlists || [] : shortlists
    ).filter(
      (item) =>
        item.type === ShortlistPropertyType.Lot &&
        !matrixData[item.references.precinctId]
    )
    return Array.from(
      new Set(
        lotShortlists.map(
          (item) => (item as LotShortlistInterface).references.precinctId
        )
      )
    )
  }, [matrixData, sessionShortlists, isConnected])

  const unavailablePackageIds = React.useMemo(() => {
    if (!(propertyType === ShortlistPropertyType.Lot)) {
      return undefined
    }
    const lotShortlists = (
      isConnected ? sessionShortlists || [] : shortlists
    ).filter(
      (item) =>
        item.type === ShortlistPropertyType.Lot &&
        item.configuration?.floorplan &&
        !packages.find((pkg) => pkg.id === item.configuration?.floorplan)
    ) as LotShortlistInterface[]
    return Array.from(
      new Set(lotShortlists.map((item) => item.configuration?.floorplan))
    )
  }, [packages, sessionShortlists, isConnected])

  const executeMatrixDataByPrecinctQuery = React.useCallback(async () => {
    if (
      !(
        propertyType === ShortlistPropertyType.Lot &&
        unavailablePrecinctIds?.length
      )
    ) {
      return
    }
    try {
      setBatchMatrixDataByPrecinctQueryResponse({
        isFetching: true,
        isLoaded: false,
        isError: false,
        status: 'pending',
      })
      const responses = await Promise.all(
        unavailablePrecinctIds.map((item) =>
          getMatrixDataByPrecinctQuery({
            projectName,
            precinctIdOrLabel: item,
            lots: true,
          })
        )
      )
      let newMatrixData: MatrixDataCollectionInterface = {}
      responses.forEach((response, index) => {
        const selectedResult = selectMatrixDataFromResult(response)
        newMatrixData = {
          ...newMatrixData,
          [unavailablePrecinctIds[index]]: selectedResult.matrixData,
        }
      })
      if (Object.keys(newMatrixData).length) {
        dispatch(setMatrixData(newMatrixData))
      }
      setBatchMatrixDataByPrecinctQueryResponse({
        isFetching: false,
        isLoaded: true,
        isError: false,
        status: 'fulfilled',
        matrixData: newMatrixData,
      })
    } catch (error) {
      setBatchMatrixDataByPrecinctQueryResponse({
        isFetching: false,
        isLoaded: true,
        isError: true,
        status: 'rejected',
        errorStatus: (error as { status: number })?.status,
      })
      console.log(error)
    }
  }, [unavailablePrecinctIds])

  const executePackagesByIdQuery = React.useCallback(async () => {
    if (
      !(
        propertyType === ShortlistPropertyType.Lot &&
        unavailablePackageIds?.length
      )
    ) {
      return
    }
    try {
      const response = await getPackagesByIdQuery({
        projectName,
        packageIds: unavailablePackageIds.join(','),
      })
      const selectedResult = selectPackagesFromResult(response)
      dispatch(setPackages(selectedResult.packages))
    } catch (error) {
      console.log(error)
    }
  }, [unavailablePackageIds])

  React.useEffect(() => {
    executeMatrixDataByPrecinctQuery()
    executePackagesByIdQuery()
  }, [executeMatrixDataByPrecinctQuery, executePackagesByIdQuery])

  React.useEffect(() => {
    if (propertyType !== ShortlistPropertyType.Lot) {
      return
    }
    if (
      isConnected &&
      (!unavailablePrecinctIds?.length ||
        batchMatrixDataByPrecinctQueryResponse?.status === 'fulfilled')
    ) {
      const properties =
        sessionShortlists?.filter((item) => {
          if (item.type !== ShortlistPropertyType.Lot) {
            return false
          }
          const matchedMatrixData = (
            unavailablePrecinctIds?.length
              ? batchMatrixDataByPrecinctQueryResponse?.matrixData
              : matrixData
          )?.[item.references?.precinctId]
          const matchedStage =
            matchedMatrixData?.stages?.[item.references.stageId]
          const matchedLot = matchedStage?.lots.find(
            (lot) => lot.id === item.propertyId
          )
          if (matchedLot) {
            return true
          }
          return false
        }) || []
      setFilteredShortlists(properties)
      dispatch(setShortlists(properties))
    }
  }, [
    sessionShortlists,
    isConnected,
    unavailablePrecinctIds,
    batchMatrixDataByPrecinctQueryResponse?.status,
  ])

  React.useEffect(() => {
    if (propertyType !== ShortlistPropertyType.Lot) {
      return
    }
    if (
      !isConnected &&
      (!unavailablePrecinctIds?.length ||
        batchMatrixDataByPrecinctQueryResponse?.status === 'fulfilled')
    ) {
      const availableProperties = shortlists.filter((item) => {
        const matchedMatrixData = (
          unavailablePrecinctIds?.length
            ? batchMatrixDataByPrecinctQueryResponse?.matrixData
            : matrixData
        )?.[(item as LotShortlistInterface).references?.precinctId]
        const matchedStage =
          matchedMatrixData?.stages?.[
            (item as LotShortlistInterface).references?.stageId
          ]
        const matchedLot = matchedStage?.lots?.find(
          (lot) => lot.id === item.propertyId
        )
        return matchedLot?.status === AVAILABLE_STATUS
      })

      setFilteredShortlists(availableProperties)
      dispatch(setShortlists(availableProperties))
    }
  }, [isConnected, batchMatrixDataByPrecinctQueryResponse?.status])

  const queryResult = React.useMemo<
    UseGetShortlistDataReturn['queryResult']
  >(() => {
    if (propertyType === ShortlistPropertyType.Unit) {
      return buildingQueryResult
    }
    if (batchMatrixDataByPrecinctQueryResponse?.status !== 'fulfilled') {
      return batchMatrixDataByPrecinctQueryResponse
    }
    if (packagesByIdQueryResponse.status !== 'fulfilled') {
      return packagesByIdQueryResponse
    }
    return batchMatrixDataByPrecinctQueryResponse
  }, [
    buildingQueryResult,
    batchMatrixDataByPrecinctQueryResponse,
    packagesByIdQueryResponse,
    filteredShortlists.length,
  ])

  const queryResultData = React.useMemo(() => {
    if (propertyType === ShortlistPropertyType.Unit) {
      return buildingQueryResult.building
    }
    if (batchMatrixDataByPrecinctQueryResponse?.status === 'fulfilled') {
      return batchMatrixDataByPrecinctQueryResponse?.matrixData
    }
    if (packagesByIdQueryResponse.status === 'fulfilled') {
      return packagesByIdQueryResponse.packages
    }
    return batchMatrixDataByPrecinctQueryResponse?.matrixData
  }, [
    buildingQueryResult,
    batchMatrixDataByPrecinctQueryResponse,
    packagesByIdQueryResponse,
  ])

  return {
    queryResult,
    queryResultData,
    filteredShortlists,
  }
}

export default useGetShortlistData
