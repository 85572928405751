import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { GalleryData } from '@api/design'

import isOffline from '@utilities/offline-mode-util'

export interface DesignInterface {
  items: Array<GalleryData>
  label: string
}

let initialState = [] as Array<DesignInterface>

if (isOffline()) {
  const designData = localStorage.getItem('designApiData')
  if (designData) {
    initialState = JSON.parse(designData)
  }
}

const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {
    setDesign: (
      state: Array<DesignInterface>,
      action: PayloadAction<Array<DesignInterface>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'designApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeDesign: () => {
      localStorage.removeItem('designApiData')
      return []
    },
  },
})

export const { setDesign, removeDesign } = designSlice.actions

export const selectDesign = (state: RootStateTypeExtra) => state.design

export default designSlice.reducer
