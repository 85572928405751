import React from 'react'

import { SvgProps } from './types'

const MinusSvg = ({
  width,
  height,
  className,
  styles,
  stroke,
  fill,
}: SvgProps) => (
  <svg
    style={{
      ...styles,
    }}
    width={width || '40'}
    height={height || '40'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={fill || 'none'}
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={stroke || 1.5}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
  </svg>
)

export default MinusSvg
