import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { resetBuilding } from '@store/actionSlices/building'
import { setLoadingIndicatorVisibility } from '@store/actionSlices/loadingIndicator'

import { buildingApi } from '@api/building'
import { useClearMatrixCacheMutation } from '@api/clear-cache'

import { notifyError, notifySuccess } from '@adUtilities/notifier'

interface ComponentProps {
  projectName: string
  email: string
}

const useMatrixCacheCleaner = ({ projectName, email }: ComponentProps): any => {
  const dispatch = useDispatch()

  const [matrixCacheCleanerTrigger, handleMatrixCacheCleanerTrigger] =
    useState(false)
  const [isMatrixCacheCleaned, setMatrixCacheCleanerState] = useState(false)

  const [clearMatrixCache, matrixCacheClearResponse] =
    useClearMatrixCacheMutation()

  const resetBuildingState = async () => {
    dispatch(buildingApi.util.resetApiState())
    dispatch(resetBuilding())
  }

  const handleMatrixCacheClearResponse = async () => {
    if (matrixCacheClearResponse.isLoading) {
      dispatch(setLoadingIndicatorVisibility(true))
      setMatrixCacheCleanerState(false)
      return
    }

    dispatch(setLoadingIndicatorVisibility(false))

    if (matrixCacheClearResponse.isSuccess) {
      await resetBuildingState()
      notifySuccess('The matrix cache has been successfully cleared.')
      setMatrixCacheCleanerState(true)
      return
    }

    if (matrixCacheClearResponse.isError) {
      notifyError('Something went wrong, please try again.')
      setMatrixCacheCleanerState(false)
    }
  }

  useEffect(() => {
    if (matrixCacheCleanerTrigger) {
      clearMatrixCache({ projectName, email }).finally(() => {
        handleMatrixCacheCleanerTrigger(false)
      })
    }
  }, [matrixCacheCleanerTrigger])

  useEffect(() => {
    if (!matrixCacheClearResponse.isUninitialized) {
      handleMatrixCacheClearResponse()
    }
  }, [matrixCacheClearResponse])

  return [isMatrixCacheCleaned, handleMatrixCacheCleanerTrigger]
}

export default useMatrixCacheCleaner
