import React from 'react'

import { SvgProps } from './types'

const FloorplanSvg = ({
  height,
  width,
  className,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    className={className}
    width={width || '48'}
    height={height || '48'}
    fill="none"
    viewBox="0 0 19 20"
    stroke={strokeColor || 'currentColor'}
    strokeWidth={stroke || 1}
  >
    <path
      d="M8.35829 13.9445V9.2223M8.35829 9.2223H0.802734M8.35829 9.2223V7.33341M17.8027 6.38897H12.1361V8.27786M12.1361 15.8334V18.6667M8.35829 16.7779V18.6667M8.35829 1.66675V4.50008M17.8027 13.0001H12.1361V11.1112"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8027 2.23341V18.1001C17.8027 18.2504 17.743 18.3945 17.6368 18.5008C17.5305 18.607 17.3864 18.6667 17.2361 18.6667H1.3694C1.21911 18.6667 1.07498 18.607 0.968707 18.5008C0.862437 18.3945 0.802734 18.2504 0.802734 18.1001V2.23341C0.802734 2.08313 0.862437 1.93899 0.968707 1.83272C1.07498 1.72645 1.21911 1.66675 1.3694 1.66675H17.2361C17.3864 1.66675 17.5305 1.72645 17.6368 1.83272C17.743 1.93899 17.8027 2.08313 17.8027 2.23341Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FloorplanSvg
