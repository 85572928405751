interface Geolocation {
  lat: number
  lng: number
}

type Device = 'Mobile' | 'Desktop'
type Browser =
  | 'Opera'
  | 'Edge'
  | 'Chrome'
  | 'Safari'
  | 'Firefox'
  | 'IE'
  | 'Edge'

interface Environment {
  host: string
  userAgent: string
  platform: string
  device: string
  browser: string
  screen: string
  viewport: string
  language: string
  cookie: string
  geoLocation: Geolocation | null
}

interface WindowExtended extends Window {
  opera?: string
  opr?: {
    addons?: unknown
  }
  chrome?: {
    webstore?: unknown
    runtime?: unknown
  }
  InstallTrigger?: unknown
  document: Document & {
    documentMode?: unknown
  }
  StyleMedia?: unknown
  safari?: {
    pushNotification?: {}
  }
}

const getDevice: () => Device = () => {
  const { userAgent } = navigator
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i
  return mobileRegex.test(userAgent) ? 'Mobile' : 'Desktop'
}

const getBrowser: () => Browser | 'Unknown Browser' = () => {
  const { userAgent } = navigator
  const windowExtended: WindowExtended = window

  if (
    (userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) > -1 ||
    !!windowExtended.opera ||
    (!!windowExtended.opr && !!windowExtended.opr.addons)
  ) {
    return 'Opera'
  }
  if (userAgent.indexOf('Edg') > -1) {
    return 'Edge'
  }
  if (
    userAgent.indexOf('Chrome') > -1 ||
    (!!windowExtended.chrome &&
      (!!windowExtended.chrome.webstore || !!windowExtended.chrome.runtime))
  ) {
    return 'Chrome'
  }
  if (
    userAgent.indexOf('Safari') > -1 ||
    /constructor/i.test(window.HTMLElement as unknown as string) ||
    ((p?: boolean | {}) =>
      p?.toString() === '[object SafariRemoteNotification]')(
      !windowExtended.safari ||
        (typeof windowExtended.safari !== 'undefined' &&
          windowExtended.safari.pushNotification)
    )
  ) {
    return 'Safari'
  }
  if (
    userAgent.indexOf('Firefox') > -1 ||
    typeof windowExtended.InstallTrigger !== 'undefined'
  ) {
    return 'Firefox'
  }
  if (
    userAgent.indexOf('MSIE') > -1 ||
    !!windowExtended.document.documentMode
  ) {
    return 'IE'
  }
  if (windowExtended.StyleMedia) {
    return 'Edge'
  }
  return 'Unknown Browser'
}

const getGeoLocation = async () =>
  new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  })

const getClientInformation: () => Promise<Environment> = async () => {
  const environment: Environment = {
    host: window.location.href,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    device: getDevice(),
    browser: getBrowser(),
    screen: `${window.screen.width}x${window.screen.height}`,
    viewport: `${window.innerWidth}x${window.innerHeight}`,
    language: navigator.language,
    cookie: document.cookie,
    geoLocation: null,
  }
  return environment
}

export { getBrowser, getDevice, getGeoLocation, getClientInformation }
