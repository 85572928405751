import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { BuildingInterface } from '@store/actionSlices/building'
import { setPanoramicData } from '@store/actionSlices/panoramic'
import { ConfigInterface } from '@store/actionSlices/projectConfig'
import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import PannellumView from '@components/showcase-pannellum'
import {
  PannellumDataInterface,
  PannellumViewRefInterface,
  PanoramaImageType,
} from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton'

import { selectFromResult, useGetPanoramicQuery } from '@api/panoramic'

import { ProjectType } from '@utilities/adgroup-utilities/types/common'
import getSession from '@utilities/firebase-util'
import {
  PANORAMIC_ACTIVE_BLOCK_KEY,
  PANORAMIC_ACTIVE_LEVEL_KEY,
  PANORAMIC_ACTIVE_UNIT_KEY,
  getItem,
  removeItem,
  setItem,
} from '@utilities/localstorage'

import { ArrowSvg } from '@svg/react'

interface ApartmentProps {
  projectName: string
  projectConfig: ConfigInterface
  panoramic: Array<PannellumDataInterface>
  session: SessionMap | undefined
  building: BuildingInterface
}

interface BuildingFlagInterface {
  set: boolean
  activeBlock: string
  activeLevel: string
  activeUnit: string
}

const defaultBuildingFlag = {
  set: false,
  activeBlock: '',
  activeLevel: '',
  activeUnit: '',
}

const Apartment = ({
  projectName,
  projectConfig,
  panoramic,
  session,
  building,
}: ApartmentProps) => {
  const dispatch = useDispatch()

  const pannellumViewRef = React.useRef<PannellumViewRefInterface>()

  const [buildingFlag, setBuildingFlag] =
    React.useState<BuildingFlagInterface>(defaultBuildingFlag)

  const [activeScene, setActiveScene] = React.useState('')
  const [activePanoramaType, setActivePanoramaType] =
    React.useState<PanoramaImageType>(PanoramaImageType.Sphere)
  const [isLoadingActiveScene, setActiveSceneLoadingState] =
    React.useState(true)

  const [isConnected, setIsConnected] = React.useState(false)
  const [scene, setScene] = React.useState('')
  const [zoom, setZoom] = React.useState<number | undefined>(undefined)
  const [pitch, setPitch] = React.useState<number | undefined>(undefined)
  const [yaw, setYaw] = React.useState<number | undefined>(undefined)
  const [rotationSpeed, setRotationSpeed] = React.useState<number | undefined>(
    undefined
  )

  const panoramicPayload = useGetPanoramicQuery(
    { projectName },
    { selectFromResult }
  )

  const findPanoramic = (
    type: 'unit' | 'level' | 'block',
    targetName: string
  ) => {
    const foundItems = panoramic.filter(
      (item: PannellumDataInterface) =>
        item.type === type && item.panoramaGroup?.toString() === targetName
    )
    const defaultItem = foundItems.find(
      (item: PannellumDataInterface) => item.default
    )
    return defaultItem || foundItems[0] || undefined
  }

  const getActivePanoramic = (buildingData: BuildingFlagInterface) => {
    const { activeBlock, activeLevel, activeUnit } = buildingData

    if (activeUnit !== '') {
      return findPanoramic('unit', activeUnit.toString())
    }

    if (activeLevel !== '') {
      return findPanoramic('level', activeLevel.toString())
    }

    if (activeBlock !== '') {
      return findPanoramic('block', activeBlock.toString())
    }

    return null
  }

  const storeBuildingDataToLocalstorage = (
    myBuilding: BuildingInterface
  ): void => {
    const { activeBlock, activeLevel, activeUnit } = myBuilding

    let localstorageBlock = getItem(PANORAMIC_ACTIVE_BLOCK_KEY, '')
    let localstorageLevel = getItem(PANORAMIC_ACTIVE_LEVEL_KEY, '')
    let localstorageUnit = getItem(PANORAMIC_ACTIVE_UNIT_KEY, '')

    if (activeBlock && activeBlock !== localstorageBlock)
      localstorageBlock = activeBlock

    if (activeLevel && activeLevel !== localstorageLevel)
      localstorageLevel = activeLevel

    if (activeUnit && activeUnit !== localstorageUnit)
      localstorageUnit = activeUnit

    setItem(PANORAMIC_ACTIVE_BLOCK_KEY, localstorageBlock)
    setItem(PANORAMIC_ACTIVE_LEVEL_KEY, localstorageLevel)
    setItem(PANORAMIC_ACTIVE_UNIT_KEY, localstorageUnit)

    setBuildingFlag({
      set: true,
      activeBlock: localstorageBlock,
      activeLevel: localstorageLevel,
      activeUnit: localstorageUnit,
    })
  }

  React.useEffect(() => {
    const { panoramic: panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      dispatch(setPanoramicData(panoramicData))
    }
  }, [panoramicPayload, panoramic])

  React.useEffect(() => {
    storeBuildingDataToLocalstorage(building)
  }, [building])

  React.useEffect(() => {
    if (buildingFlag.set && panoramic.length > 0) {
      const data = getActivePanoramic(buildingFlag)
      const active = data ? data.nameMap : panoramic[0]?.nameMap
      const panoramaType = data ? data.panoramaType : panoramic[0]?.panoramaType

      setActiveScene(active)
      setActivePanoramaType(panoramaType)
      setActiveSceneLoadingState(false)
    }
  }, [buildingFlag, panoramic])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        panoramic: {
          pitch: pitchFirebase,
          yaw: yawFirebase,
          zoom: zoomFirebase,
          scene: sceneFirebase,
          rotationSpeed: rotationSpeedFirebase,
        },
      } = session

      setIsConnected(connected)
      setRotationSpeed(rotationSpeedFirebase)

      if (panoramic.length > 0) {
        setScene(sceneFirebase)
      }

      setZoom(zoomFirebase)
      setPitch(pitchFirebase)
      setYaw(yawFirebase)
    }
  }, [session])

  React.useEffect(() => {
    if (zoom !== undefined) {
      pannellumViewRef?.current?.pannellum.setHfov(zoom)
    }
  }, [zoom])

  React.useEffect(() => {
    if (pitch !== undefined) {
      pannellumViewRef?.current?.pannellum.setPitch(pitch)
    }
  }, [pitch])

  React.useEffect(() => {
    if (yaw !== undefined) {
      pannellumViewRef?.current?.pannellum.setYaw(yaw)
    }
  }, [yaw])

  React.useEffect(() => {
    if (rotationSpeed === 0) {
      pannellumViewRef?.current?.pannellum.stopAutoRotate()
      pannellumViewRef?.current?.pannellum.setPitch(pitch || 0)
      pannellumViewRef?.current?.pannellum.setYaw(yaw || 0)
      return
    }
    pannellumViewRef?.current?.pannellum.startAutoRotate(rotationSpeed || 0)
  }, [rotationSpeed, yaw, pitch])

  React.useEffect(() => {
    if (scene !== '') {
      pannellumViewRef?.current?.pannellum.loadScene(scene)
    }
  }, [scene])

  React.useEffect(
    () => () => {
      setBuildingFlag(defaultBuildingFlag)
      removeItem(PANORAMIC_ACTIVE_BLOCK_KEY)
      removeItem(PANORAMIC_ACTIVE_LEVEL_KEY)
      removeItem(PANORAMIC_ACTIVE_UNIT_KEY)
    },
    []
  )

  return (
    <Container className="h-page-container w-screen">
      <DataHandler
        payload={{
          ...panoramicPayload,
          data: panoramic,
          apiData: panoramicPayload.panoramic,
        }}
        skeletonFrame={<Skeleton />}
      >
        {!isConnected && (
          <div className="absolute left-5 top-5 z-10">
            <Link
              to={
                projectConfig.type === ProjectType.Apartment
                  ? 'building'
                  : 'stages'
              }
              className="fixed rounded bg-white p-1 drop-shadow-40"
              title={
                projectConfig.type === ProjectType.Apartment
                  ? 'Back to building page'
                  : 'Back to stages page'
              }
            >
              <ArrowSvg className="h-8 w-8" strokeColor="#000" />
            </Link>
          </div>
        )}

        {isLoadingActiveScene ? (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
          </div>
        ) : (
          <PannellumView
            key="panoramic-view"
            ref={pannellumViewRef}
            payload={panoramic}
            activeScene={activeScene}
            panoramaType={activePanoramaType}
            className="h-full w-full"
            hideHotspots={isConnected}
          />
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    projectConfig,
    panoramic,
    firestore,
    building,
  }: RootStateFirebase) => ({
    projectName,
    projectConfig,
    panoramic,
    session: getSession(firestore),
    building,
  })
)(Apartment)
