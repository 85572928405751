import React from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import { useIdleTimeHandler } from '@components/idle-time-handler'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import getSession from '@utilities/firebase-util'

import {
  BufferSvg,
  PauseSvg,
  PlayCircleSvg,
  PlayerCloseCircleSvg,
} from '@svg/react'

import ScrubBar from './scrub-bar'
import VolumeBar from './volume-bar'

export interface ProgressProps {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export interface PlayerProps {
  galleryName: string
  videoUrl: string
  getPlayerState: (arg: React.SetStateAction<boolean>) => void
  playerControls?: PlayerControlInterface | undefined
  isActive?: boolean
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  disablePlayOption?: boolean
  onPlayerPauseOrStop?: () => void
  timeoutInSeconds?: number
}

const Player = ({
  galleryName,
  videoUrl,
  getPlayerState,
  playerControls,
  isActive,
  projectIdentity,
  session,
  disablePlayOption = false,
  onPlayerPauseOrStop,
  timeoutInSeconds = 0,
}: PlayerProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const playerWrapperRef = React.createRef<HTMLDivElement>()
  const playerRef = React.createRef<ReactPlayer>()
  const [isPlayerVisible, setPlayerVisibility] = React.useState(false)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [volume, setVolume] = React.useState(0.1)
  const [muteVideo, setMuteVideo] = React.useState(false)
  const [isControlsActive, setControlStatus] = React.useState(false)
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>()
  const [videoLength, setVideoLength] = React.useState(0)
  const [isBuffing, setBuffing] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const progressInitial: ProgressProps = {
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  }
  const [progress, setProgress] = React.useState<ProgressProps>(progressInitial)
  const [scrubPositionInSeconds, setScrubPositionInSeconds] = React.useState(-1)

  const isIdle = useIdleTimeHandler({ timeoutInSeconds })

  const handleControlStatus = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (!isControlsActive) {
      setControlStatus(true)
    }
    const timeout = setTimeout(() => {
      setControlStatus(false)
    }, 3000)
    setTimeoutId(timeout)
  }

  const updateCurrentVideoPositionInSecondsInFirebase = async (
    value: number
  ) => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.currentVideoPositionInSeconds`]: value,
    })
  }

  React.useEffect(() => {
    if (isActive && playerControls && isConnected) {
      setIsPlaying(playerControls.playerState === 'play')
      getPlayerState(playerControls.playerState === 'play')
      setPlayerVisibility(playerControls.isPlayerVisible)
      setVolume(playerControls.volume)
      setMuteVideo(playerControls.isMuted)
      setScrubPositionInSeconds(playerControls.scrubPositionInSeconds)
    }
  }, [playerControls])

  React.useEffect(() => {
    if (isConnected && scrubPositionInSeconds >= 0) {
      playerRef.current?.seekTo(scrubPositionInSeconds)
    }
  }, [scrubPositionInSeconds])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      setIsConnected(connected)

      if (!connected) {
        return
      }

      const { videoGallery } = session

      if (!videoGallery) {
        return
      }

      const {
        playerControl: { videoSynchronizeTriggerKey },
      } = videoGallery

      if (videoSynchronizeTriggerKey) {
        const currentVideoPositionInSeconds = parseFloat(
          progress.playedSeconds.toFixed(2)
        )

        updateCurrentVideoPositionInSecondsInFirebase(
          currentVideoPositionInSeconds
        ).catch((error) => console.error(`Something went wrong: ${error}`))
      }
    }
  }, [session])

  React.useEffect(() => {
    const updatePlayerState = async () => {
      if (!playerControls) {
        return
      }

      const { playerState, videoSynchronizeTriggerKey } = playerControls
      if (playerState === 'play') {
        setIsPlaying(true)
        setPlayerVisibility(true)

        if (!videoSynchronizeTriggerKey) {
          await firebaseControlQuery.update({
            [`${galleryName}.playerControl.videoSynchronizeTriggerKey`]: true,
          })
        }
      }
    }

    updatePlayerState().catch((error) => console.error(error))

    return () => {
      setTimeoutId(undefined)
    }
  }, [])

  const handleBuffer = (videoState: boolean) => {
    setBuffing(videoState)
    if (isConnected) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.isBuffering`]: videoState,
      })
    }
  }

  const handleDuration = (length: number) => {
    setVideoLength(length)
    if (isConnected) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.videoLengthInSeconds`]: length,
      })
    }
  }

  const toTimeString = (milliseconds: number): string =>
    new Date(milliseconds).toISOString().slice(14, 19)

  const showPlayerControls = isControlsActive || !isIdle

  return (
    <>
      {!isPlayerVisible && (
        <div className="flex h-full items-center justify-center">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation()
              setPlayerVisibility(true)
              setIsPlaying(true)
            }}
            className="drop-shadow-70"
            data-testid="play-button"
            disabled={disablePlayOption}
          >
            <PlayCircleSvg size="s" className="h-36 w-36 text-tertiaryColour" />
          </button>
        </div>
      )}

      {isPlayerVisible && (
        <div
          data-testid="react-player-container"
          className="relative z-10 w-full"
          ref={playerWrapperRef}
        >
          <ReactPlayer
            ref={playerRef}
            onStart={() => {
              getPlayerState(true)
            }}
            url={videoUrl}
            playing={isPlaying}
            width="w-full"
            height="100vh"
            style={{ background: '#000000' }}
            volume={volume}
            muted={muteVideo}
            onProgress={(p: ProgressProps) => setProgress(p)}
            onDuration={(length) => handleDuration(length)}
            onBuffer={() => handleBuffer(true)}
            onBufferEnd={() => handleBuffer(false)}
            loop
          />
          {isBuffing && (
            <div className="absolute bottom-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <BufferSvg className="h-20 w-20 text-white drop-shadow-40" />
            </div>
          )}
          {!isConnected && (
            <div
              className={`absolute top-32 z-50 flex w-full justify-center transition-all ease-in-out ${
                showPlayerControls
                  ? 'opacity-100'
                  : 'pointer-events-none opacity-0'
              }`}
            >
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  getPlayerState(false)
                  setIsPlaying(false)
                  setPlayerVisibility(false)
                  setVolume(0.1)
                  setProgress(progressInitial)
                  onPlayerPauseOrStop?.()
                }}
              >
                <PlayerCloseCircleSvg
                  className="cursor-pointer text-gray-500"
                  size="m"
                />
              </button>
            </div>
          )}
          {!isConnected && videoLength > 0 && (
            <div
              className={`absolute bottom-0 z-50 h-36 w-full bg-gradient-to-t from-gray-800 text-white transition-all ease-in-out ${
                showPlayerControls ? 'opacity-100' : 'opacity-0'
              } ${isPlaying ? 'pt-16' : 'pt-8'}`}
              onClick={(e) => {
                e.stopPropagation()
                handleControlStatus()
              }}
              role="none"
            >
              <ScrubBar
                playerReference={playerRef}
                videoLengthInMilliseconds={videoLength * 1000}
                isPlaying={isPlaying}
                isBuffing={isBuffing}
              />
              <div className="flex w-full justify-between">
                <div className="flex gap-10 pl-4">
                  <button
                    type="button"
                    className="flex cursor-pointer justify-center"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsPlaying(!isPlaying)
                      getPlayerState(!isPlaying)
                      if (isPlaying) {
                        onPlayerPauseOrStop?.()
                      }
                    }}
                  >
                    {isPlaying ? (
                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                        <PauseSvg size="m" className="text-gray-300" />
                      </div>
                    ) : (
                      <PlayCircleSvg size="m" className="text-gray-500" />
                    )}
                  </button>

                  <div className="flex w-28 items-center">
                    <VolumeBar volume={volume} setVolume={setVolume} />
                  </div>
                </div>

                <div className="flex items-center pr-10">
                  <p className="text-sm text-white">
                    {toTimeString(progress.playedSeconds * 1000)} /{' '}
                    {toTimeString(videoLength * 1000)}
                  </p>
                </div>
              </div>
            </div>
          )}

          {!showPlayerControls && (
            <button
              type="button"
              className="absolute left-0 top-0 z-50 h-screen w-screen cursor-default"
              onClick={(e) => {
                e.stopPropagation()
                handleControlStatus()
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default connect(({ firestore, projectIdentity }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Player)
