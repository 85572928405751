import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
} from 'pure-react-carousel'
import React from 'react'

import type { GalleryControlInterface } from '@store/types'

import FadeContainer from '@components/fade-container'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'

import handleMouseWheel from '@utilities/gallery-image-control-util'

import { generateId } from '@adUtilities/generate-id-util'

import GalleryProvider from './gallery-provider'
import GallerySlide from './gallery-slide'
import GallerySlider from './gallery-slider'
import { setScrollPosition } from './helper'
import SlideTrigger from './slide-trigger'

export interface GalleryHandlerInterface {
  galleryName: string
  galleryControl?: GalleryControlInterface
  slides: Array<{ id: string; src: string }>
  labels?: Array<{
    label: string
    sublabel: string
    src?: string
    type?: string
  }>
  gradiant?: number
  legacyAssets?: boolean
  isConnected?: boolean
  dotType?: 'dot' | 'text' | 'thumbnail' | 'label'
  labelPosition?: 'left' | 'right'
  toggleControls?: boolean
  showShadows?: boolean
  toggleBlurredBackground?: boolean
  onClick?: () => void
  gallerySlideClassNames?: string
  slideTriggerContainerClassNames?: string
}

const GalleryHandler = ({
  galleryName,
  galleryControl,
  slides,
  labels,
  gradiant,
  legacyAssets = true,
  isConnected = false,
  dotType = 'dot',
  labelPosition = 'left',
  toggleControls = true,
  showShadows = false,
  onClick,
  gallerySlideClassNames = '',
  slideTriggerContainerClassNames = 'pb-14',
  toggleBlurredBackground,
}: GalleryHandlerInterface) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const innernextButton = generateId(10)
  const innerprevButton = generateId(10)
  const innerfirstButton = generateId(10)
  const innerlastButton = generateId(10)

  const handleGallerySliderClick = () => {
    onClick?.()
  }

  return (
    <GalleryProvider
      galleryName={galleryName}
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      dragEnabled={false}
      disableKeyboard
      className="h-full ipad-mini:w-screen"
      originalSlides={slides}
      galleryControl={galleryControl}
    >
      {(slideContent, activeIndex, setActiveIndex) => (
        <div
          onWheel={(e) => {
            handleMouseWheel(
              e,
              innernextButton,
              innerprevButton,
              activeIndex,
              slides.length
            )
            setScrollPosition(dotContainerRef)
          }}
          className="h-full"
        >
          <ButtonNext id={innernextButton} className="hidden">
            Next button
          </ButtonNext>
          <ButtonBack id={innerprevButton} className="hidden">
            Prev button
          </ButtonBack>
          <ButtonFirst id={innerfirstButton} className="hidden">
            First button
          </ButtonFirst>
          <ButtonLast id={innerlastButton} className="hidden">
            Last button
          </ButtonLast>
          {!isConnected && slides.length > 1 && (
            <IdleTimeHandler className="absolute left-0 top-0 z-4">
              <FadeContainer className="relative z-2" show={toggleControls}>
                {showShadows && (
                  <div className="pointer-events-none fixed bottom-0 h-1/4 w-screen bg-shadow-horizontal" />
                )}
                <div
                  className={`absolute flex h-screen cursor-default flex-col justify-end ${
                    labelPosition === 'left' ? 'left-6' : 'right-6'
                  } ${slideTriggerContainerClassNames}`}
                  onClick={handleGallerySliderClick}
                  role="none"
                >
                  <div role="none" onClick={(e) => e.stopPropagation()}>
                    <SlideTrigger
                      type={dotType}
                      items={
                        labels
                          ? labels.map((label) => ({
                              text: label.label,
                              subText: label.sublabel,
                              ...(label.src && { src: label.src }),
                              ...(label.type && { type: label.type }),
                            }))
                          : []
                      }
                      setCurrentSlide={setActiveIndex}
                      currentSlide={activeIndex}
                      nextButtonId={innernextButton}
                      previousButtonId={innerprevButton}
                      dotContainerRef={dotContainerRef}
                      toggleControls={toggleControls}
                    />
                  </div>
                </div>
              </FadeContainer>
            </IdleTimeHandler>
          )}
          <GallerySlider onClick={handleGallerySliderClick} className="h-full">
            {slideContent.map((slideItem, index) => (
              <GallerySlide
                index={index}
                key={`${slideItem.id} - ${index as number}`}
                className={toggleBlurredBackground ? 'overflow-hidden' : ''}
              >
                <div className="relative">
                  {toggleBlurredBackground && (
                    <ImageHandler
                      url={slideItem?.src}
                      type={legacyAssets ? 'legacy' : 'new'}
                      className="background-cover image-blur absolute inset-0 z-2"
                    />
                  )}
                  <ImageHandler
                    url={slideItem?.src}
                    type={legacyAssets ? 'legacy' : 'new'}
                    bgProps={{
                      gradiant: gradiant || 0,
                    }}
                    className={`${
                      toggleBlurredBackground
                        ? 'background-contain'
                        : 'background-cover'
                    } absolute inset-0 z-3 ${gallerySlideClassNames}`}
                  />
                </div>
              </GallerySlide>
            ))}
          </GallerySlider>
        </div>
      )}
    </GalleryProvider>
  )
}

export default GalleryHandler
