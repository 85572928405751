import React from 'react'

import { SvgProps } from './types'

const ChevronSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
  stroke,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    top: '0deg',
    down: '180deg',
    right: '90deg',
    left: '270deg',
  }

  return (
    <svg
      className={`${className}`}
      style={{
        ...styles,
        ...{
          height: 'auto',
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '24'}
      height={height || '24'}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={stroke || '1.5'}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 15.75l7.5-7.5 7.5 7.5"
      />
    </svg>
  )
}

export default ChevronSvg
