import React from 'react'

const TeamsSkeleton = () => (
  <div className="flex h-full w-screen">
    <div className="h-full w-2/5 bg-tertiaryColour">
      <div className="relative h-4/5 p-20">
        <div className="h-24 w-40 rounded-lg bg-slate-400/20">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="mt-12 h-8 w-full rounded-full bg-slate-400/20">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="mt-4 h-8 w-full rounded-full bg-slate-400/20">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="mt-4 h-8 w-3/5 rounded-full bg-slate-400/20">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="mt-24 h-16 w-40 rounded-lg bg-slate-400/20">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="absolute bottom-0 h-10 w-3/5 rounded-full bg-slate-400/20">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>
    </div>
    <div className="h-full w-3/5 bg-slate-400/50">
      <div className="shimmer-container relative h-full opacity-50"></div>
    </div>
  </div>
)

export default TeamsSkeleton
