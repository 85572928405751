import React from 'react'

const HeadSkeleton = () => (
  <div className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#EEEEEE] py-4">
    <div className="h-3 w-20 rounded bg-zinc-400/20"></div>
    <div className="h-3 w-6 rounded bg-zinc-400/20"></div>
  </div>
)

const CardSkeleton = () => (
  <div className="shimmer-container relative flex flex-col space-y-3 rounded-none p-0 shadow-none">
    <div className="overflow-hidden rounded-md bg-white">
      <div className="flex flex-col space-y-4 px-6 py-4">
        <div className="flex justify-between">
          <div className="h-3 w-24 rounded bg-zinc-400/20"></div>
          <div className="h-3 w-6 rounded bg-zinc-400/20"></div>
        </div>
        <div className="flex justify-between">
          <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
          <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
          <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
          <div className="h-5 w-6 rounded bg-zinc-400/20"></div>
        </div>
      </div>
    </div>
  </div>
)

const SidePanelSkeleton = () => (
  <div className="h-unit-sidepanel-container overflow-y-scroll px-4 pb-0.5">
    <div className="flex flex-col space-y-2">
      <div>
        <HeadSkeleton />
        <div className="flex flex-col space-y-4">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      </div>
      <div>
        <HeadSkeleton />
        <div className="flex flex-col space-y-4">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      </div>
      <div>
        <HeadSkeleton />
        <div className="flex flex-col space-y-4">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      </div>
    </div>
  </div>
)

export default SidePanelSkeleton
