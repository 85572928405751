import React from 'react'

import { SvgProps } from './types'

const CheckSvg = ({
  width,
  height,
  className,
  styles,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      ...styles,
    }}
    width={width || '48'}
    height={height || '48'}
    fill="none"
    stroke={strokeColor || 'currentColor'}
    viewBox="0 0 15 11"
    strokeWidth={stroke || 1.5}
  >
    <path
      d="M1.66602 6.33337L4.99935 9.66671L13.3327 1.33337"
      stroke={strokeColor}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckSvg
