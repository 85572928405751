import { FloorTabInterface } from '@store/types'

const TAB_ITEMS: Array<FloorTabInterface> = [
  { key: 'floor', label: 'Floor' },
  { key: 'gallery', label: 'Gallery' },
]

const floorTabItems = (): Array<FloorTabInterface> => TAB_ITEMS

const getTabIndexByKey = (key: string): number =>
  TAB_ITEMS.findIndex((tab: FloorTabInterface) => tab.key === key)

const getTabKeyByIndexBy = (index: number): string => TAB_ITEMS[index].key

export { floorTabItems, getTabIndexByKey, getTabKeyByIndexBy }
