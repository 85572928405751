import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface VideoGalleryData {
  id: string
  title: string
  fileName: string
  src: string
  previewImageSrc: string
}

type VideoGalleryType = {
  projectName: string
}

export const videoGalleryApi = createApi({
  reducerPath: 'videoGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getVideoGallery: builder.query<
      ApiResponse<Array<VideoGalleryData>>,
      VideoGalleryType
    >({
      query: (params: VideoGalleryType) => ({
        url: `/v1/${params.projectName}/videos`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  videoGalleryData: VideoGalleryData[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  videoGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetVideoGalleryQuery } = videoGalleryApi
