import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ProjectIdentity, RootStateTypeExtra, SessionKey } from '@store/types'

const projectIdentity = localStorage.getItem('projectIdentity')

export interface ProjectIdentityOptional {
  projectId?: string
  projectName?: string
  projectLabel?: string
  lightMap?: string
  sessionId?: Array<SessionKey>
  masterKey?: string
}

let initialState = {
  projectName: '',
  projectId: '',
  projectLabel: '',
  sessionId: [],
  lightMap: '',
  masterKey: '',
} as ProjectIdentity

if (projectIdentity) {
  initialState = JSON.parse(projectIdentity)
}

const projectIdentitySlice = createSlice({
  name: 'projectIdentity',
  initialState,
  reducers: {
    setProjectIdentity: (
      state: ProjectIdentity,
      action: PayloadAction<ProjectIdentityOptional>
    ) => {
      localStorage.setItem(
        'projectIdentity',
        JSON.stringify({
          ...state,
          ...action.payload,
        })
      )
      return {
        ...state,
        ...action.payload,
      }
    },
    removeProjectIdentity: (state: ProjectIdentity) => {
      localStorage.removeItem('projectIdentity')
      return {
        ...state,
        ...{
          sessionId: [],
          masterKey: '',
        },
      }
    },
  },
})

export const { setProjectIdentity, removeProjectIdentity } =
  projectIdentitySlice.actions

export const selectProject = (state: RootStateTypeExtra) =>
  state.projectIdentity

export default projectIdentitySlice.reducer
