export const MarkerType = {
  arrow: 'arrow',
  badgeLabel: 'badgelabel',
  arrowSmall: 'arrowsmall',
  badge: 'badge',
}

export const CustomObjectTypes = {
  polyGroup: 'PolyGroup',
  markerGroup: 'MarkerGroup',
  outerCircle: 'OuterCircle',
  innerCircle: 'InnerCircle',
  labelGroup: 'LabelGroup',
  oblongGroup: 'OblongGroup',
  oblongLabel: 'OblongLabel',
  oblongSubLabel: 'OblongSubLabel',
}

export const TransparentFill = 'rgba(255, 255, 255, 0.1)'
export const TransparentFullFill = 'rgba(255, 255, 255, 0)'

export const SVGArrowURL =
  'https://d1bw6x5263wwbc.cloudfront.net/images/arrow.svg'
export const SVGChevronURL =
  'https://d1bw6x5263wwbc.cloudfront.net/images/chevron.svg'
