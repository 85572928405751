const handleMouseWheel = (
  event: React.WheelEvent,
  nextButtonId: string,
  prevButtonId: string,
  currentSlide: number,
  slideLength: number
) =>
  event.deltaY < 0
    ? currentSlide !== 0 && document.getElementById(prevButtonId)?.click()
    : currentSlide !== slideLength - 1 &&
      document.getElementById(nextButtonId)?.click()

export default handleMouseWheel
