import React from 'react'

import { ProjectIdentity } from '@store/types'

import { GenericModalCard as ModalCard } from '@components/modals'

import { getCurrentSessionKey } from '@utilities/firebase-util'

import { PlusSvg, TrashSvg } from '@svg/react'

interface SessionHandlerProps {
  removeSession: () => void
  createSession: () => void
  connected: boolean | undefined
  projectIdentity: ProjectIdentity
}

const SessionHandler = ({
  connected,
  removeSession,
  createSession,
  projectIdentity,
}: SessionHandlerProps) => {
  const [toggleSessionModal, setToggleSessionModal] = React.useState(false)
  const [toggleClearSessionModal, setToggleClearSessionModal] =
    React.useState(false)
  const [sessionDeleting, setSessionDelete] = React.useState(false)

  const handleSessionRemoval = () => {
    setSessionDelete(true)
    removeSession()
  }

  React.useEffect(() => {
    if (sessionDeleting) {
      setSessionDelete(false)
    }
  }, [projectIdentity.sessionId])

  return (
    <>
      <ModalCard
        headerLabel="Manage Remote Session"
        cancelLabel="Close"
        toggle={(status: boolean) => setToggleClearSessionModal(status)}
        isVisible={toggleClearSessionModal}
        okLabel="Yes"
        okAction={() => handleSessionRemoval()}
      >
        <div className="px-4 text-left">
          <p>Do you want to clear this session?</p>
        </div>
      </ModalCard>
      <ModalCard
        headerLabel="Connect a remote"
        cancelLabel="Close"
        toggle={(status: boolean) => setToggleSessionModal(status)}
        isVisible={toggleSessionModal}
      >
        <span>Enter the bellow Session ID in your Showcase Remote App:</span>
        <div className="flex items-center justify-center p-4 text-2xl">
          <b className="mr-1">{getCurrentSessionKey(projectIdentity)}</b>
          <span className="relative ml-2 flex h-3 w-3">
            <span
              className={`absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75 ${
                connected ? 'animate-ping bg-lime-400' : 'bg-lime-400'
              }`}
            ></span>
            <span
              className={`relative inline-flex h-3 w-3 rounded-full ${
                connected ? 'bg-lime-500' : 'bg-red-600'
              }`}
            ></span>
          </span>
        </div>
      </ModalCard>
      <button
        disabled={sessionDeleting || projectIdentity.masterKey !== ''}
        onClick={() => {
          if (getCurrentSessionKey(projectIdentity)) {
            setToggleClearSessionModal(true)
            return
          }
          createSession()
          setToggleSessionModal(true)
        }}
        type="button"
        className="pointer flex w-full items-center rounded border border-2 border-gray-300 p-3 px-5 text-left text-left"
      >
        {projectIdentity.sessionId.length > 0 && (
          <b className="mr-4">Session</b>
        )}
        <span>
          {getCurrentSessionKey(projectIdentity)
            ? `${getCurrentSessionKey(projectIdentity)}`
            : 'Connect a Remote'}{' '}
        </span>
        {getCurrentSessionKey(projectIdentity) && (
          <span
            className={`${
              connected ? 'bg-green-300' : 'bg-red-300'
            } ml-4 rounded px-2`}
          >
            {connected ? 'Connected' : 'No Connection'}
          </span>
        )}
        {getCurrentSessionKey(projectIdentity) ? (
          <TrashSvg
            className={`ml-auto ${
              sessionDeleting || projectIdentity.masterKey !== ''
                ? 'text-red-200'
                : 'text-red-700'
            }`}
            width="35"
            height="35"
          />
        ) : (
          <PlusSvg className="ml-auto" width="35" height="35" />
        )}
      </button>
    </>
  )
}

export default SessionHandler
