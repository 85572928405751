import React from 'react'

import { TokenPayloadInterface } from '@store/actionSlices/token'

import { hasToken } from '@utilities/helper'

import {
  notifySuccess,
  notifyWarning,
  removeNotification,
} from '@adUtilities/notifier'

interface ComponentProps {
  token: TokenPayloadInterface
  isOnline: boolean
}

const useConnectionIndicator = ({
  token,
  isOnline,
}: ComponentProps): string => {
  const [notificationId, setNotificationId] = React.useState<string>('')

  React.useEffect(() => {
    if (hasToken(token)) {
      if (!isOnline) {
        setNotificationId(
          notifyWarning(
            'Showcase is currently offline. No internet connection.',
            true,
            0
          )
        )
      }

      if (isOnline && notificationId) {
        removeNotification(notificationId)
        notifySuccess('Showcase is back online.', false, 3000)
        setNotificationId('')
      }
    }
  }, [isOnline])

  return notificationId
}

export default useConnectionIndicator
