import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

export const connectionIndicatorSlice = createSlice({
  name: 'connectionIndicator',
  initialState: {
    isOnline: true,
  },
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      ...{ isOnline: action.payload },
    }),
  },
})

export const { setConnectionStatus } = connectionIndicatorSlice.actions

export const selectConnectionIndicator = (state: RootStateTypeExtra) =>
  state.connectionIndicator

export default connectionIndicatorSlice.reducer
