import React from 'react'

import { DropDownOption } from '@store/types'

import { ChevronSvg } from '@svg/react'

export interface DropDownProps {
  items: Array<DropDownOption>
  value: string
  onSelect: (arg: string) => void
  disabledOption?: (item: string) => boolean
  label?: string
  className?: string
  optionClassName?: string
  disabled?: boolean
  placeholder?: string
  buttonRadius?: string
  buttonRadiusClasses?: string
  optionRadiusClasses?: string
  useValueAsKey?: boolean
}
const DropDown = ({
  items,
  value,
  onSelect,
  label,
  disabled,
  disabledOption,
  className = '',
  optionClassName,
  placeholder,
  buttonRadius = 'rounded',
  buttonRadiusClasses = 'rounded-tl rounded-tr',
  optionRadiusClasses = 'rounded-bl rounded-br',
  useValueAsKey,
}: DropDownProps) => {
  const [isOpen, toggleOpen] = React.useState(false)
  const dropDownRef = React.useRef<HTMLDivElement>(null)
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node) &&
      isOpen
    ) {
      toggleOpen(false)
    }
  }
  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  const displayedValue = React.useMemo(() => {
    const selectedValue = items.find((item) => item.value === value)
    return selectedValue?.label || value
  }, [items, value])

  return (
    <div className="relative w-full text-sm" ref={dropDownRef}>
      <button
        type="button"
        className={`w-full transition duration-200 ease-in-out ${
          isOpen
            ? `${buttonRadiusClasses} border-l border-r border-t border-mainColour`
            : `${buttonRadius} border border-[#CCC]`
        } ${disabled ? 'pointer-events-none' : ''} px-2 py-1 ${className}`}
        onClick={() => toggleOpen(!isOpen)}
      >
        {label && (
          <span className="flex text-xs font-normal text-[#888888]">
            {label}
          </span>
        )}
        <div className="flex items-center justify-between">
          <span
            className={`text-base font-medium ${disabled ? 'text-[#CCC]' : ''}`}
          >
            {displayedValue || placeholder}
          </span>
          <ChevronSvg
            className={`${disabled ? 'text-[#CCC]' : ''} h-6 w-6`}
            stroke={1}
            rotate={isOpen ? 'top' : 'down'}
          />
        </div>
      </button>
      <div
        className={`no-scrollbar absolute z-20 flex w-full flex-col gap-3 overflow-y-auto bg-white px-2 py-3 transition duration-200 ease-in-out ${
          isOpen
            ? `visible max-h-56 border border-mainColour ${optionRadiusClasses}`
            : 'invisible max-h-0'
        } ${optionClassName}`}
      >
        {items.map((item: DropDownOption) => (
          <div
            key={
              useValueAsKey || typeof item.label !== 'string'
                ? item.value
                : item.label
            }
            className={`cursor-pointer ${
              disabledOption &&
              disabledOption(item.value) &&
              'pointer-events-none text-gray-300'
            }`}
          >
            <button
              type="button"
              onClick={() => {
                if (item.value !== value) {
                  toggleOpen(false)
                  onSelect(item.value)
                }
              }}
              className={`flex w-full items-center justify-between text-left text-base font-medium hover:text-mainColour ${
                value === item.value ? 'text-mainColour' : ''
              }`}
            >
              {item.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
export default DropDown
