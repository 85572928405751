import React from 'react'
import { useDispatch } from 'react-redux'

import {
  setMatrixData,
  setPrecinctList,
} from '@store/actionSlices/houseAndLand'

import {
  selectMatrixDataFromResult,
  selectPrecinctListFromResult,
  useGetMatrixDataByPrecinctQuery,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'

interface ComponentProps {
  projectName: string
  precinctId: string
}

const useGetHouseAndLandFilterData = ({
  projectName,
  precinctId,
}: ComponentProps) => {
  const dispatch = useDispatch()

  const precinctsPayload = useGetPrecinctListQuery(
    { projectName },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )
  const { matrixData, isFetching } = useGetMatrixDataByPrecinctQuery(
    {
      projectName,
      precinctIdOrLabel: precinctId,
    },
    // TODO: this login needs to be improved, precinct will never be null or empty in H&L project
    { selectFromResult: selectMatrixDataFromResult, skip: precinctId === '' }
  )

  React.useEffect(() => {
    // TODO: We should only store data into the matrixData store if no data exists for the active precinct in the matrixData store
    if (Object.keys(matrixData).length > 0) {
      dispatch(
        setMatrixData({
          [precinctId]: matrixData,
        })
      )
    }
  }, [matrixData])

  React.useEffect(() => {
    const { precinctList } = precinctsPayload
    // TODO: I think we should check check to the store as well
    if (precinctList?.length > 0) {
      dispatch(setPrecinctList(precinctList))
    }
  }, [precinctsPayload])

  return { isFetching }
}

export default useGetHouseAndLandFilterData
