import { fabric } from 'fabric'

import { Point, Size } from '@api/interactive-plan'

import { getRelativePostition, getScaledAttribute } from './utils/utils'

export interface RectParams {
  size: Size
  bgSize: Size
  coordinates: Point
  rectSize?: Size
  rectAngle?: number
  rectCoordinates?: Point
  color?: string
  markerColor?: string
}

const Rect = (): {
  createRect: (arg: RectParams) => fabric.Rect
} => {
  const RectBase = {
    fill: '#cccccc',
    width: 200,
    height: 5,
    objectCaching: false,
    stroke: 'none',
    strokeWidth: 0,
  }

  const createRect = ({
    size,
    bgSize,
    rectSize,
    rectAngle,
    coordinates,
    rectCoordinates,
    markerColor,
  }: RectParams) => {
    const rect = new fabric.Rect({
      ...RectBase,
      fill: markerColor || '#ffffff',
      left: rectCoordinates
        ? getRelativePostition(
            coordinates?.x || 0,
            rectCoordinates?.x || 0,
            size.width || 0,
            bgSize.width || 0
          )
        : 0,
      top: rectCoordinates
        ? getRelativePostition(
            coordinates?.y || 0,
            rectCoordinates?.y || 0,
            size.height || 0,
            bgSize.height || 0
          )
        : 0,
      width: getScaledAttribute(rectSize?.width || 0, bgSize.width),
      height: getScaledAttribute(rectSize?.height || 0, bgSize.height),
      angle: rectAngle,
    })
    return rect
  }

  return {
    createRect,
  }
}

export default Rect
