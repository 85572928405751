import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { TeamsInfo } from '@api/teams'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<TeamsInfo>

if (isOffline()) {
  const teamsData = localStorage.getItem('teamsApiData')
  if (teamsData) {
    initialState = JSON.parse(teamsData)
  }
}

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (
      state: Array<TeamsInfo>,
      action: PayloadAction<Array<TeamsInfo>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'teamsApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeTeams: () => {
      localStorage.removeItem('teamsApiData')
      return []
    },
  },
})

export const { setTeams, removeTeams } = teamsSlice.actions

export const selectTeams = (state: RootStateTypeExtra) => state.teams

export default teamsSlice.reducer
