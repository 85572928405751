import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { VideoGalleryData } from '@api/video-gallery'

import isOffline from '@utilities/offline-mode-util'

let initialState = [] as Array<VideoGalleryData>

if (isOffline()) {
  const videoGalleryData = localStorage.getItem('videoGalleryApiData')
  if (videoGalleryData) {
    initialState = JSON.parse(videoGalleryData)
  }
}

const videoGallerySlice = createSlice({
  name: 'videoGallery',
  initialState,
  reducers: {
    setVideoGallery: (
      state: Array<VideoGalleryData>,
      action: PayloadAction<Array<VideoGalleryData>>
    ) => {
      if (isOffline()) {
        localStorage.setItem(
          'videoGalleryApiData',
          JSON.stringify([...action.payload])
        )
      }
      return [...action.payload]
    },
    removeVideoGallery: () => {
      localStorage.removeItem('videoGalleryApiData')
      return []
    },
  },
})

export const { setVideoGallery, removeVideoGallery } = videoGallerySlice.actions

export const selectVideoGallery = (state: RootStateTypeExtra) =>
  state.videoGallery

export default videoGallerySlice.reducer
