import React from 'react'
import { useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'

import { GenericModalCard as ModalCard } from '@components/modals'

import InputHandler from '@utilities/form-util'

import { ChevronSvg } from '@svg/react'

interface LightModelHandlerProps {
  lightMap: string
}

const LightModelHandler = ({ lightMap }: LightModelHandlerProps) => {
  const dispatch = useDispatch()

  const [toggled, setToggleModal] = React.useState(false)

  const [inputKeyValue, setInputKeyValue] = React.useState({
    value: lightMap,
    isValid: true,
    message: '',
  })

  const connectLight = () => {
    dispatch(setProjectIdentity({ lightMap: inputKeyValue.value }))
  }

  React.useEffect(() => {
    setInputKeyValue({
      ...inputKeyValue,
      value: lightMap,
    })
  }, [toggled])

  const disconnectLight = () => {
    setInputKeyValue({
      ...inputKeyValue,
      value: '',
    })
    dispatch(setProjectIdentity({ lightMap: '' }))
  }

  return (
    <>
      <ModalCard
        headerLabel="Light Model"
        toggle={(status: boolean) => setToggleModal(status)}
        cancelAction={() => console.log('cancel')}
        okAction={() => connectLight()}
        isVisible={toggled}
        disableOk={inputKeyValue.value.length === 0 || !inputKeyValue.isValid}
        hideOk={lightMap.length > 0}
      >
        <div className="px-4 text-left">
          <p>Enter a valid light code to connect showcase &#38; your model</p>
        </div>
        <div
          className={`mx-4 my-3 flex items-center justify-center rounded border text-lg
          ${
            !inputKeyValue.isValid && inputKeyValue.value
              ? 'border-red-700'
              : 'border-gray-300'
          } 
        `}
        >
          <input
            onChange={(event) => InputHandler(event, setInputKeyValue)}
            className="w-full border-none focus:ring-0"
            placeholder="Enter a valid light map key"
            type="text"
            value={inputKeyValue.value}
          />
          {lightMap && (
            <button
              onClick={() => disconnectLight()}
              type="button"
              className="ml-auto p-2 text-red-700"
            >
              Remove
            </button>
          )}
        </div>
      </ModalCard>
      <button
        onClick={() => setToggleModal(!toggled)}
        type="button"
        className="flex w-full items-center rounded border border-2 border-gray-300 p-3 px-5 text-left"
      >
        <span className="text-sm">Light Model</span>
        <ChevronSvg
          className="ml-auto h-5 w-5 py-1"
          stroke={4}
          rotate="right"
        />
      </button>
    </>
  )
}

export default LightModelHandler
