import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface GalleryData {
  id: string
  imageSource: string
  imageName: string
  title: string
}

export interface ProcessedGalleryData {
  items: Array<GalleryData>
  label: string
}

export type GalleryCollection = Record<string, Array<GalleryData>>

type DesignType = {
  projectName: string
}

export const designApi = createApi({
  reducerPath: 'designApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getDesign: builder.query<ApiResponse<GalleryCollection>, DesignType>({
      query: (params: DesignType) => ({
        url: `/v1/${params.projectName}/design`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

const processGalleryData = (
  data: GalleryCollection
): Array<ProcessedGalleryData> => {
  const gallery: Array<ProcessedGalleryData> = []

  Object.keys(data).forEach((key: string) => {
    gallery.push({
      items: data[key] ?? [],
      label: key.charAt(0).toUpperCase() + key.slice(1),
    })
  })

  return gallery
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  gallery: ReturnType<typeof processGalleryData>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  gallery: processGalleryData(data?.data || []).filter(
    (galleries) => galleries.items.length > 0
  ),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetDesignQuery } = designApi
