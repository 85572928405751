import { ChangeEvent, Dispatch, SetStateAction } from 'react'

export interface InputInterface {
  value: string
  isValid: boolean
  message: string
}

const InputHandler = (
  e: ChangeEvent<HTMLInputElement>,
  setState: Dispatch<SetStateAction<InputInterface>>,
  customMessage?: string,
  customValidation?: (arg: any) => boolean
) => {
  setState({
    value: e.target.value,
    isValid: customValidation
      ? customValidation(e.target.value)
      : e.target.validity.valid,
    message: customMessage || e.target.validationMessage,
  })
}

export default InputHandler
