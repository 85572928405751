import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { UnitGallery } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

type UnitGalleryType = {
  projectName: string
  unitId: string
}

export const unitGalleryApi = createApi({
  reducerPath: 'unitGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getUnitGallery: builder.query<
      ApiResponse<Array<UnitGallery>>,
      UnitGalleryType
    >({
      query: (params: UnitGalleryType) => ({
        url: `/v1/${params.projectName}/unit-galleries/${params.unitId}`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})
export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  unitGalleryData: Array<UnitGallery>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  unitGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})
export const { useGetUnitGalleryQuery } = unitGalleryApi
