import React from 'react'
import { CSSTransition } from 'react-transition-group'

import { useIdleTimeHandler } from './utils'

export interface IdleTimeHandlerProps {
  children: React.ReactNode
  disable?: boolean
  className?: string
}

const IdleTimeHandler = ({
  children,
  disable = false,
  className,
}: IdleTimeHandlerProps) => {
  if (disable) return <>{children}</>

  const isAppIdle = useIdleTimeHandler()

  return (
    <CSSTransition
      in={!isAppIdle}
      classNames="transition-fade-out"
      timeout={300}
    >
      <div className={className}>{children}</div>
    </CSSTransition>
  )
}

export default IdleTimeHandler
