import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

type ParamType = {
  projectName: string
  email: string
}

export const clearCacheApi = createApi({
  reducerPath: 'clearCacheApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    clearMatrixCache: builder.mutation<ApiResponse<string>, ParamType>({
      query: (params: ParamType) => ({
        url: `/v1/${params.projectName}/clear-cache-matrix`,
        method: 'PATCH',
        body: {
          ...params,
        },
      }),
    }),
  }),
})

export const { useClearMatrixCacheMutation } = clearCacheApi
