import { useFirestore } from 'react-redux-firebase'

import { SessionMap } from '@store/types'
import type { Channel, ProjectIdentity, SessionKey } from '@store/types'

import { getCurrentSessionKey } from './firebase-util'

interface WildCardObjectInterface {
  [key: string]: any
}

interface FirebaseControlQueryProps {
  projectIdentity: ProjectIdentity
}

interface CreateSessionInterface {
  projectName: string
  sessionId: string
}

interface FirebaseControlQueryReturn {
  updateConnection: (
    connected: boolean,
    sessionId?: Array<SessionKey>
  ) => Promise<any>
  isProjectEnabled: (arg: string) => Promise<boolean>
  doesProjectExist: (arg: string) => Promise<boolean>
  createSession: (arg: CreateSessionInterface) => Promise<any>
  updateLightMap: (arg: Array<Channel>) => Promise<any>
  update: (arg: WildCardObjectInterface) => Promise<any>
  updateLightChannel: (arg: WildCardObjectInterface) => Promise<any>
  getMap: () => Promise<SessionMap | undefined>
  refreshSession: () => Promise<any>
  deleteSession: () => Promise<any>
  updateShortlist: (arg: {
    key: string
    payload: Record<string, any>
  }) => Promise<any>
}

const FirebaseControlQuery = ({
  projectIdentity,
}: FirebaseControlQueryProps): FirebaseControlQueryReturn => {
  const firestore = useFirestore()

  const isProjectEnabled = async (projectName: string) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectName)
      .get()
    const { exists } = response

    if (!exists) {
      return false
    }

    const data = response.data()

    if (!data) {
      return true
    }

    if (!('enable' in data)) {
      return true
    }

    return data?.enable ?? false
  }

  const doesProjectExist = async (projectName: string) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectName)
      .get()
    return exists
  }

  const getMap = async () => {
    const data = await firestore.collection('session-base').doc('map').get()
    if (!data.exists) {
      return undefined
    }
    return data.data() as SessionMap
  }

  const doesSessionExist = async ({
    projectName,
    sessionId,
  }: {
    projectName: string
    sessionId: string
  }) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectName)
      .collection('session')
      .doc(sessionId)
      .get()
    return exists
  }

  const createSession = async ({
    projectName,
    sessionId,
  }: CreateSessionInterface) => {
    const exist = await doesSessionExist({ projectName, sessionId })
    const map = await getMap()
    if (!exist && map) {
      await firestore
        .collection('devsuite')
        .doc(projectName)
        .collection('session')
        .doc(sessionId)
        .set(map || {})
      return true
    }
    return false
  }

  const updateConnection = async (
    connected: boolean,
    sessionId?: Array<SessionKey>
  ) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(
        (
          getCurrentSessionKey(projectIdentity) ||
          getCurrentSessionKey({
            ...projectIdentity,
            sessionId: sessionId || [],
          })
        )
          .toUpperCase()
          .replace(/\s/g, '')
      )
      .update({
        connected,
      })
    return response
  }

  const refreshSession = async () => {
    const map = await getMap()
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(getCurrentSessionKey(projectIdentity))
      .update({ ...(map || {}), connected: true })
    return response
  }

  const deleteSession = async () => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(getCurrentSessionKey(projectIdentity))
      .delete()
    return response
  }

  const update = async (value: WildCardObjectInterface) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('session')
      .doc(getCurrentSessionKey(projectIdentity))
      .update(value)
    return response
  }

  const updateLightChannel = async (value: WildCardObjectInterface) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('light-map')
      .doc(projectIdentity.lightMap)
      .update(value)
    return response
  }

  const updateLightMap = async (channels: Array<Channel>) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectName)
      .collection('light-map')
      .doc(projectIdentity.lightMap)
      .update({ channels })
    return response
  }

  const updateShortlist = async (value: {
    key: string
    payload: Record<string, any>
  }) => {
    const { exists } = await firestore
      .collection('shortlisted-property')
      .doc(value.key)
      .get()

    if (!exists) {
      await firestore
        .collection('shortlisted-property')
        .doc(value.key)
        .set({ payload: JSON.stringify(value.payload), createdAt: Date.now() })
    } else {
      await firestore
        .collection('shortlisted-property')
        .doc(value.key)
        .update({
          payload: JSON.stringify(value.payload),
          createdAt: Date.now(),
        })
    }
  }

  return {
    getMap,
    updateConnection,
    isProjectEnabled,
    doesProjectExist,
    refreshSession,
    deleteSession,
    createSession,
    updateLightMap,
    update,
    updateLightChannel,
    updateShortlist,
  }
}

export default FirebaseControlQuery
