const validateEmailFormat = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

const getQueryStringParams = (query: string) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}

const formatAreaNumber = (num: string | number) => {
  const convertedNum = Number(num)
  return Number.isNaN(convertedNum) ? num : Math.round(convertedNum * 100) / 100
}

export { validateEmailFormat, getQueryStringParams, formatAreaNumber }
