import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'

import appConfig from '@store/actionSlices/appConfig'
import bedConfig from '@store/actionSlices/bedConfig'
import brochure from '@store/actionSlices/brochure'
import building from '@store/actionSlices/building'
import buildingPageConfig from '@store/actionSlices/buildingPageConfig'
import connectionIndicator from '@store/actionSlices/connectionIndicator'
import design from '@store/actionSlices/design'
import externalViews from '@store/actionSlices/externalViews'
import floorPlateGallery from '@store/actionSlices/floorPlateGallery'
import houseAndLand from '@store/actionSlices/houseAndLand'
import interactiveMap from '@store/actionSlices/interactiveMap'
import interactivePlan from '@store/actionSlices/interactivePlan'
import loadingIndicator from '@store/actionSlices/loadingIndicator'
import location from '@store/actionSlices/location'
import lotFilter from '@store/actionSlices/lotFilter'
import panoramic from '@store/actionSlices/panoramic'
import projectConfig from '@store/actionSlices/projectConfig'
import projectIdentity from '@store/actionSlices/projectIdentity'
import shortlist from '@store/actionSlices/shortlist'
import teams from '@store/actionSlices/teams'
import token from '@store/actionSlices/token'
import unitFilter from '@store/actionSlices/unitFilter'
import unitGallery from '@store/actionSlices/unitGallery'
import videoGallery from '@store/actionSlices/videoGallery'
import vision from '@store/actionSlices/vision'

import { agentApi } from '@api/agent'
import { userApi } from '@api/authentication'
import { bedConfigApi } from '@api/bed-config'
import { brochureApi } from '@api/brochure'
import { buildingApi } from '@api/building'
import { clearCacheApi } from '@api/clear-cache'
import { configApi } from '@api/config'
import { designApi } from '@api/design'
import { externalLinksApi } from '@api/external-links'
import { externalViewsApi } from '@api/external-views'
import { floorPlateGalleryApi } from '@api/floor-plate-gallery'
import { houseAndLandApi } from '@api/houseAndLand'
import { interactiveMapApi } from '@api/interactive-map'
import { interactivePlanApi } from '@api/interactive-plan'
import { locationApi } from '@api/location'
import { masterKeyApi } from '@api/master-key'
import { panoramicApi } from '@api/panoramic'
import { projectListApi } from '@api/project-list'
import { shortlistApi } from '@api/shortlist'
import { teamsApi } from '@api/teams'
import { unitGalleryApi } from '@api/unit-gallery'
import { videoGalleryApi } from '@api/video-gallery'
import { visionApi } from '@api/vision'

import { getFirebaseLocalHost } from '@utilities/helper'

import firebaseConfig from '../firebaseConfig'

firebase.initializeApp(firebaseConfig)

firebase.firestore()

const { firebasePort, firebaseIp } = getFirebaseLocalHost()
if (firebasePort && firebaseIp) {
  firebase
    .firestore()
    .useEmulator(firebaseIp || 'localhost', Number(firebasePort))
}

const initialState = {}

const rootReducer = combineReducers({
  token,
  teams,
  design,
  brochure,
  vision,
  building,
  location,
  shortlist,
  bedConfig,
  interactivePlan,
  interactiveMap,
  projectIdentity,
  unitFilter,
  lotFilter,
  projectConfig,
  loadingIndicator,
  panoramic,
  videoGallery,
  externalViews,
  floorPlateGallery,
  buildingPageConfig,
  connectionIndicator,
  appConfig,
  unitGallery,
  houseAndLand,
  [userApi.reducerPath]: userApi.reducer,
  [teamsApi.reducerPath]: teamsApi.reducer,
  [designApi.reducerPath]: designApi.reducer,
  [brochureApi.reducerPath]: brochureApi.reducer,
  [visionApi.reducerPath]: visionApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [buildingApi.reducerPath]: buildingApi.reducer,
  [interactivePlanApi.reducerPath]: interactivePlanApi.reducer,
  [interactiveMapApi.reducerPath]: interactiveMapApi.reducer,
  [shortlistApi.reducerPath]: shortlistApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [bedConfigApi.reducerPath]: bedConfigApi.reducer,
  [panoramicApi.reducerPath]: panoramicApi.reducer,
  [videoGalleryApi.reducerPath]: videoGalleryApi.reducer,
  [externalViewsApi.reducerPath]: externalViewsApi.reducer,
  [clearCacheApi.reducerPath]: clearCacheApi.reducer,
  [floorPlateGalleryApi.reducerPath]: floorPlateGalleryApi.reducer,
  [unitGalleryApi.reducerPath]: unitGalleryApi.reducer,
  [projectListApi.reducerPath]: projectListApi.reducer,
  [masterKeyApi.reducerPath]: masterKeyApi.reducer,
  [houseAndLandApi.reducerPath]: houseAndLandApi.reducer,
  [agentApi.reducerPath]: agentApi.reducer,
  [externalLinksApi.reducerPath]: externalLinksApi.reducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  ...initialState,
})

export type RootStateType = ReturnType<typeof rootReducer>

export function setupStore(
  preloadedState?: PreloadedState<ReturnType<typeof rootReducer>>
) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        visionApi.middleware,
        designApi.middleware,
        brochureApi.middleware,
        locationApi.middleware,
        interactivePlanApi.middleware,
        interactiveMapApi.middleware,
        buildingApi.middleware,
        teamsApi.middleware,
        shortlistApi.middleware,
        configApi.middleware,
        bedConfigApi.middleware,
        panoramicApi.middleware,
        videoGalleryApi.middleware,
        externalViewsApi.middleware,
        clearCacheApi.middleware,
        floorPlateGalleryApi.middleware,
        unitGalleryApi.middleware,
        projectListApi.middleware,
        masterKeyApi.middleware,
        houseAndLandApi.middleware,
        agentApi.middleware,
        externalLinksApi.middleware
      ),
    preloadedState,
  })
}

const store = setupStore()

const FirebaseProps = {
  firebase,
  dispatch: store.dispatch,
  config: { useFirestoreForProfile: false },
  createFirestoreInstance,
}

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatchType = typeof store.dispatch

export type AppStore = ReturnType<typeof setupStore>

export default { FirebaseProps, store }
