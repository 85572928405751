import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export type AgentType = 'agent' | 'partner-agent'

export interface Agent {
  id: string
  firstName: string
  lastName: string
  email: string
  type: AgentType
  showcaseEnabled: boolean
}

export interface NewAgentPayload {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export const agentApi = createApi({
  reducerPath: 'agentApi',
  baseQuery: baseQueryInterceptor,
  tagTypes: ['AgentsList'],
  endpoints: (builder) => ({
    getAllAgents: builder.query<
      ApiResponse<Array<Agent>>,
      {
        projectName: string
        type?: Agent['type']
        showcaseEnabled?: Agent['showcaseEnabled']
      }
    >({
      query: (params: {
        projectName: string
        type?: Agent['type']
        showcaseEnabled?: Agent['showcaseEnabled']
      }) => ({
        url: `/v2/${params.projectName}/agents/list`,
        method: 'get',
        params: {
          type: params.type,
          showcaseEnabled: params.showcaseEnabled,
        },
      }),
      providesTags: ['AgentsList'],
    }),
    getAgentByRef: builder.query<
      ApiResponse<Agent>,
      { projectId: string; ref: string }
    >({
      query: (params: { projectId: string; ref: string }) => ({
        url: `/v2/${params.projectId}/agents/${params.ref}`,
        method: 'get',
      }),
    }),
    createPartnerAgent: builder.mutation<
      ApiResponse<Agent>,
      NewAgentPayload & { projectName: string }
    >({
      query: (params: NewAgentPayload & { projectName: string }) => ({
        url: `/v2/${params.projectName}/agents`,
        method: 'post',
        body: {
          ...params,
          type: 'partner-agent',
        },
      }),
      invalidatesTags: ['AgentsList'],
    }),
  }),
})

export const selectFromAgentsResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  data: Array<Agent>
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  data: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const {
  useGetAllAgentsQuery,
  useLazyGetAgentByRefQuery,
  useCreatePartnerAgentMutation,
} = agentApi
