import * as Sentry from '@sentry/react'

import sentryConfig from '@src/sentryConfig'

import { TokenPayloadInterface } from '@store/actionSlices/token'

import {
  EnvisionVRConfigurationInterface,
  SnaploaderConfigurationInterface,
} from '@api/config'

import { HouseAndLandPackageOptionType } from './adgroup-utilities/types/houseAndLand'

function truncateTextWithEllipsis(
  text: string,
  length: number,
  end: number
): string {
  return text.length > length ? `${text.substring(0, end)}...` : text
}

function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}
}

const hasToken = (token: TokenPayloadInterface): boolean => {
  const {
    access_token: { token: accessToken },
    refresh_token: { token: refreshToken },
  } = token

  return accessToken !== '' && refreshToken !== ''
}

const hasSnaploader = (
  snapConfig: SnaploaderConfigurationInterface
): boolean => {
  const { active, sceneSnapId, sceneModelId } = snapConfig
  return active && sceneSnapId !== '' && sceneModelId !== ''
}

const hasEnvisionVR = (config: EnvisionVRConfigurationInterface): boolean => {
  const { active, url } = config
  return active && url !== ''
}

const getFirebaseLocalHost = () => {
  const firebasePort = localStorage.getItem('firebasePort')
  const firebaseIp = localStorage.getItem('firebaseIp')
  return {
    firebaseIp,
    firebasePort,
  }
}

const getAssetPort = (): string => {
  const assetPort = localStorage.getItem('assetPort')
  return assetPort === null ? '' : assetPort
}

const getActiveProject = (): string => {
  const projectIdentity = localStorage.getItem('projectIdentity')
  if (!projectIdentity) {
    return ''
  }
  const projectIdentityObj = JSON.parse(projectIdentity)
  return projectIdentityObj.projectName || ''
}

const roomQueryParams = () => {
  const room = localStorage.getItem('room')
  return room === null ? {} : { showcaseRoom: room }
}

const validateIp = (ip: string) => {
  const ipv4 =
    /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/
  const ipv6 = /((([0-9a-fA-F]){1,4}):){7}([0-9a-fA-F]){1,4}/

  if (ipv4.test(ip) || ipv6.test(ip) || ip === 'localhost') {
    return true
  }
  return false
}

const validatePort = (port: string) => {
  const portRegex =
    /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
  return portRegex.test(port)
}

const formatPrice = (value: string | number) =>
  !Number.isNaN(Number(value))
    ? `$${Number(value).toLocaleString('en-US')}`
    : ''

const initializeSentry = () => {
  const {
    dsnKey,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    enableSentry,
  } = sentryConfig

  Sentry.init({
    environment: process.env.REACT_APP_NODE_ENV || 'production',
    enabled: enableSentry,
    dsn: dsnKey,
    integrations: [
      Sentry.browserTracingIntegration(), // For Performance Monitoring
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
      }), // For Session Replays
    ],
    // Performance Monitoring
    tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  })
}

const mappedPackageOptionType = (type: string) => {
  const types: { [type: string]: HouseAndLandPackageOptionType } = {
    facade: HouseAndLandPackageOptionType.Facade,
    facadeColor: HouseAndLandPackageOptionType.FacadeColor,
    floorplanOption: HouseAndLandPackageOptionType.FloorplanOption,
    internalColorScheme: HouseAndLandPackageOptionType.InternalColorScheme,
    internalTheme: HouseAndLandPackageOptionType.InternalTheme,
  }

  return types[type] || type
}

export {
  getQueryStringParams,
  truncateTextWithEllipsis,
  hasToken,
  hasSnaploader,
  hasEnvisionVR,
  getFirebaseLocalHost,
  getAssetPort,
  roomQueryParams,
  validateIp,
  validatePort,
  formatPrice,
  getActiveProject,
  initializeSentry,
  mappedPackageOptionType,
}
