import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface GalleryData {
  id: string
  imageSource: string
  imageName: string
}

export interface BrochureData {
  label: string
  Images: Array<GalleryData>
}

type BrochureType = {
  projectName: string
}

export const brochureApi = createApi({
  reducerPath: 'brochureApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBrochure: builder.query<ApiResponse<Array<BrochureData>>, BrochureType>({
      query: (params: BrochureType) => ({
        url: `/v1/${params.projectName}/brochure`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  brochureData: BrochureData[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  brochureData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetBrochureQuery } = brochureApi
