const getScaledAttribute = (attrib: number, scaledSize: number) =>
  scaledSize * (attrib / 100)

const getRelativePostition = (
  mainCoordinate: number,
  relativeCoordinate: number,
  size: number,
  scaledSize: number
) =>
  getScaledAttribute(mainCoordinate, scaledSize) +
  (getScaledAttribute(relativeCoordinate, scaledSize) +
    getScaledAttribute(size, scaledSize) / 2)

const getPercentageValue = (item: number, percentage: number) =>
  (item * percentage) / 100

export { getScaledAttribute, getRelativePostition, getPercentageValue }
