import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

export interface GalleryData {
  id: string
  label: string
  imageSource: string
  imageName: string
  videoSource: string
  videoFileName: string
  videoTitle: string
  config: {
    isTitleCard: boolean
  }
}

export interface VisionData {
  gallery: Array<GalleryData>
  title: string
}

type VisionType = {
  projectName: string
}

export const visionApi = createApi({
  reducerPath: 'visionApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getVision: builder.query<ApiResponse<VisionData>, VisionType>({
      query: (params) => ({
        url: `/v1/${params.projectName}/vision`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
  }),
})

const setTitleCard = ({ gallery }: VisionData) => {
  let keyCard = 0
  if (gallery.length > 0) {
    const newGalleryRef = gallery.map((gal, key) => {
      if (gal.config.isTitleCard) {
        keyCard = key
        return { ...gal }
      }
      return gal
    })
    if (keyCard !== 0) {
      newGalleryRef.splice(0, 0, newGalleryRef.splice(keyCard, 1)[0])
    }
    return newGalleryRef
  }
  return []
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  gallery: GalleryData[]
  title: string
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  gallery: data?.data ? setTitleCard(data.data) : [],
  title: data?.data.title || '',
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetVisionQuery } = visionApi
