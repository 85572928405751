import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '../types'

export const loadingIndicatorSlice = createSlice({
  name: 'loadingIndicator',
  initialState: {
    isVisible: false,
  },
  reducers: {
    setLoadingIndicatorVisibility: (state, action: PayloadAction<boolean>) => ({
      ...state,
      ...{ isVisible: action.payload },
    }),
  },
})

export const { setLoadingIndicatorVisibility } = loadingIndicatorSlice.actions

export const selectLoadingIndicator = (state: RootStateTypeExtra) =>
  state.loadingIndicator

export default loadingIndicatorSlice.reducer
