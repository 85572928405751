import React from 'react'

export enum Path {
  AreaView = 'area-view-house-and-land',
  Precinct = 'precinct',
  Stage = 'stages',
}

type Params = {
  precinct?: string
  stage?: string
  lot?: string
}

const useParseMapActionRoute = () => {
  const checkValidity = React.useCallback(
    (path: Path | undefined, params: Params) => {
      const isValidPath = path ? Object.values(Path).includes(path) : false
      if (!isValidPath) {
        return false
      }
      if (path === Path.Precinct) {
        if (!params.precinct) {
          return false
        }
      }
      if (path === Path.Stage) {
        if (!(params.precinct && params.stage)) {
          return false
        }
      }
      return true
    },
    []
  )

  const parseActionRoute = React.useCallback((actionRoute?: string) => {
    let path: Path | undefined
    const params: Params = {}
    if (path !== Path.AreaView) {
      actionRoute?.split('&').forEach((i) => {
        const [index, value] = i.split('=')
        if (index === 'page') {
          path = value as Path
        } else {
          params[index as keyof typeof params] = value
        }
      })
    }
    const urlParams = new URLSearchParams(params).toString()

    return {
      path,
      pathWithParams: urlParams ? `${path}?${urlParams}` : path,
      params,
      isValid: checkValidity(path, params),
    }
  }, [])

  return [parseActionRoute]
}

export default useParseMapActionRoute
