import React from 'react'

import { SvgProps } from './types'

const FileSvg = ({ width, height, className, size, styles }: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="currentColor"
      viewBox="0 0 11 15"
      stroke="none"
    >
      <path
        d="M6.875 0.5H1.375C0.61875 0.5 0.00687516 1.13 0.00687516 1.9L0 13.1C0 13.87 0.611875 14.5 1.36812 14.5H9.625C10.3813 14.5 11 13.87 11 13.1V4.7L6.875 0.5ZM8.25 11.7H2.75V10.3H8.25V11.7ZM8.25 8.9H2.75V7.5H8.25V8.9ZM6.1875 5.4V1.55L9.96875 5.4H6.1875Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FileSvg
