const sentryConfig = {
  dsnKey: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  replaysSessionSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
  ),
  replaysOnErrorSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
  ),
  enableSentry: process.env.REACT_APP_NODE_ENV !== 'local',
}

export default sentryConfig
